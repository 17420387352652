import React from 'react';
import { Row, Col } from 'react-bootstrap';
import _ from 'lodash';

import Util from '../../../helper/Util';

import MainBet from './MainBet';
import Moment from 'react-moment';

class MainMarket extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            star: false,
        }
    }

    setStarActive = () => {
        this.setState({
            star: !this.state.star
        })
    }
    setActiveMainBtn = (value) => {
        var element = document.getElementById('live-panel-' + value);
        if (element.classList.contains('active')) {
            element.classList.remove("active");
        }
        else {
            element.classList.add("active");
        }
    }

    render() {


        let { star, isLive, market, fixture } = this.props;

        let selectedProvider = market.Providers[0];
        let bets = selectedProvider.Bets;

        bets = Util.sortBet(bets, market.Id);

        let time = (fixture.Livescore && fixture.Livescore.Scoreboard && fixture.Livescore.Scoreboard.Time) ? fixture.Livescore.Scoreboard.Time : 0;

        time = parseInt(time * 0.0166666667);

        if (fixture.Livescore.Scoreboard.CurrentPeriod === 80) time = "HT" //Break Time

        //console.log(fixture.Livescore.Scoreboard.CurrentPeriod)

        let drawBets = _.map(_.take(bets, 3), bet => {

            return <MainBet fixture={fixture} market={market} provider={selectedProvider} bet={bet} key={bet.Id} />;
        })



        return (
            <Row className="mx-auto match-inner-panel-bg main-market-odds">
                <div className="width-95 mx-auto pb-1">
                    <Col xs="1" lg="1" md="auto" className="display-flex text-center p-2">
                        <i className={`fs-24 material-icons ${star ? 'activeStart' : ''}`} onClick={this.setStarActive}>star_border</i>
                    </Col>
                    <Col xs="1" lg="21" md="auto" className="display-flex text-center p-2 text-danger">
                        {time}"
                    </Col>
                    {drawBets}
                </div>
            </Row>
        )
    }
}
export default MainMarket;