import axios from "axios";
import jwtDecode from "jwt-decode";
import { apiConfig } from "../../config/index";
import EventEmitter from "../../helper/EventEmitter";
import _ from 'lodash';

class jwtService extends EventEmitter { //
  init() {
    this.setInterceptors();
    this.handleAuthentication();
  }

  setInterceptors = () => {
    axios.interceptors.response.use(
      response => {
        return response;
      },
      err => {
        return new Promise((resolve, reject) => {
          if (err.response &&
            err.response.status === 401 &&
            err.config &&
            !err.config.__isRetryRequest
          ) {
            // if you ever get an unauthorized response, logout the user
            this.emit("onAutoLogout", "Invalid access_token");
            this.setSession(null);
          }
          throw err;
        });
      }
    );
  };

  handleAuthentication = () => {
    let access_token = this.getAccessToken();

    if (!access_token) {
      this.emit("onNoAccessToken");

      return;
    }

    if (this.isAuthTokenValid(access_token)) {
      this.setSession(access_token);
      this.emit("onAutoLogin", true);
    } else {
      this.setSession(null);
      this.emit("onAutoLogout", "access_token expired");
    }
  };

  signInWithUsernameAndPassword = (username, password) => {
    return new Promise((resolve, reject) => {
      axios
        .post(apiConfig.routes.login, {
          username,
          password
        })
        .then(response => {
          let user = response.data;
          if (user) {
            this.setSession(user.access_token);
            resolve(user);
          } else {
            reject(response.data.error);
          }
        }).catch(error => {
          reject(error);
        });
    });
  };

  getUser = () => {
    return new Promise((resolve, reject) => {
      axios
        .get(apiConfig.routes.user)
        .then(response => {
          let user = response.data;

          if (user) {
            resolve(user);
          } else {
            reject(response.data.error);
          }
        });
    });
  };

  setSession = access_token => {
    if (access_token) {
      localStorage.setItem("jwt_access_token", access_token);
      axios.defaults.headers.common["Authorization"] = "Bearer " + access_token;
    } else {
      localStorage.removeItem("jwt_access_token");
      delete axios.defaults.headers.common["Authorization"];
    }
  };

  logout = () => {
    axios.get(apiConfig.routes.logout);
    this.setSession(null);
  };

  isAuthTokenValid = access_token => {
    if (!access_token) {
      return false;
    }
    const decoded = jwtDecode(access_token);
    const currentTime = Date.now() / 1000;
    if (decoded.exp < currentTime) {
      console.warn("access token expired");
      return false;
    } else {
      return true;
    }
  };

  getAccessToken = () => {
    return window.localStorage.getItem("jwt_access_token");
  };

  placeBet = (data) => {
    return new Promise((resolve, reject) => {
      axios
        .post(apiConfig.routes.placeBet, data)
        .then(response => {

          if (response.data.statusCode == 200) {
            resolve(response.data);
          } else {

            if (response.data.message === Object(response.data.message)) {
              let firstMessage = response.data.message[_(Object.keys(response.data.message)).first()][0];
              reject(firstMessage);
            } else {
              reject(response.data.message);
            }
          }


        }).catch(error => {
          reject(error);
        });
    });
  };

  getLimits = () => {
    return new Promise((resolve, reject) => {
      axios
        .get(apiConfig.routes.getLimits)
        .then(response => {
          resolve(response.data);
        }).catch(error => {
          reject(error);
        });
    });
  };

  getBetslips = () => {
    return new Promise((resolve, reject) => {
      axios
        .post(apiConfig.routes.getBetslips)
        .then(response => {
          resolve(response.data);
        }).catch(error => {
          reject(error);
        });
    });
  };
}


const instance = new jwtService();

export default instance;
