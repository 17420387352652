import _ from 'lodash';

import { lSportsConfig } from '../config/lsports.config'

class Util {
    static assignBetSort = function (betObject, marketId) {

        let market = _.find(lSportsConfig.sort.bet, i => {

            return i.marketId === marketId
        })

        let bet = null;

        if (market) {
            bet = _.find(market.bets, i => {
                return i.Name === betObject.Name
            })
        }

        return bet ? _.assign(betObject, bet) : _.assign(betObject, { "Name": betObject.Name, "Sort": betObject.BaseLine ? betObject.BaseLine : betObject.Id });
    }

    static sortBet = function (bets, marketId) {
        _.forEach(bets, (b, i) => {
            bets[i] = this.assignBetSort(b, marketId);
        })
        return _.orderBy(bets, ['Sort', 'Name'], 'dec');
    }

    static assignMarketSort = function (marketObject, sportId) {

        let sport = _.find(lSportsConfig.sort.market, i => {

            return i.sportId === sportId
        })

        let market = null;

        if (sport) {
            market = _.find(sport.markets, i => {
                return i.Id === marketObject.Id
            })
        }

        return market ? _.assign(marketObject, market) : _.assign(marketObject, { "Id": marketObject.Id, "Sort": marketObject.Id });
    }

    static sortMarket = function (markets, sportId) {
        _.forEach(markets, (b, i) => {
            markets[i] = this.assignMarketSort(b, sportId);
        })
        return _.orderBy(markets, ['Sort'], 'dec');
    }

    static toFixedFloat = function (value, dec) {
        return parseFloat(parseFloat(value).toFixed(dec))
    }

    static prepareForRemoval = function (fixtures) {
        let fixturesClone = _.cloneDeep(fixtures);
        _.forEach(fixturesClone, e => {
            _.forEach(e.markets, market => {

                _.forEach(market.bets, (bet, key) => {

                    if (bet.Stake > 0.00 && bet.Status === 1) {
                        market.bets[key] = { ...bet, ...{ isPlaced: true } }
                    }
                });
            })
        })

        return fixturesClone;
    }

    static prepareAllForRemoval = function (fixtures) {
        let fixturesClone = _.cloneDeep(fixtures);
        _.forEach(fixturesClone, e => {
            _.forEach(e.markets, market => {

                _.forEach(market.bets, (bet, key) => {
                    market.bets[key] = { ...bet, ...{ isPlaced: true } }
                });
            })
        })

        return fixturesClone;
    }

    static filterBetStakeZeroOut = function (fixtures) {
        let fixturesClone = _.cloneDeep(fixtures);

        _.forEach(fixturesClone, e => {
            _.forEach(e.markets, market => {

                market.bets = _.filter(market.bets, bet => {
                    return bet.Stake > 0.00 && bet.Status === 1;
                });

                e.markets = _.filter(e.markets, m => {
                    return m.bets.length !== 0;
                });
            })

            fixturesClone = _.filter(fixturesClone, g => {
                return g.markets.length !== 0;
            });
        })

        return fixturesClone;
    }

    static filterBetStakeZeroIn = function (fixtures) {
        let fixturesClone = _.cloneDeep(fixtures);

        _.forEach(fixturesClone, e => {
            _.forEach(e.markets, market => {

                market.bets = _.filter(market.bets, bet => {
                    return bet.Stake <= 0.00 || bet.Status !== 1;
                });

                e.markets = _.filter(e.markets, m => {
                    return m.bets.length !== 0;
                });
            })

            fixturesClone = _.filter(fixturesClone, g => {
                return g.markets.length !== 0;
            });
        })

        return fixturesClone;
    }

    static convertToPlaceBetData = function (fixtures, type, multiStake = 0) {

        let data = _.map(fixtures, fixture => {
            return _.map(fixture.markets, market => {
                return _.map(market.bets, bet => {

                    return {
                        bet_id: bet.Id,
                        fixture_id: fixture.fixtureId,
                        market_id: market.Id,
                        provider_id: bet.provider,
                        champ: fixture.fixture.Fixture.Sport.Name + "," + fixture.fixture.Fixture.Location.Name + "," + fixture.fixture.Fixture.League.Name,
                        match: fixture.fixture.Fixture.Participants[0].Name + " - " + fixture.fixture.Fixture.Participants[1].Name,
                        market: market.market.Name,
                        name: bet.Name,
                        status: bet.Status,
                        price: bet.Price,
                        start_date: fixture.fixture.Fixture.StartDate,
                        stake: type === 1 ? bet.Stake : 0.00,
                        settlement: bet.Settlement,
                    }
                })
            })
        })

        return {
            bettype_id: type,
            multi_stake: multiStake,
            Bets: _.flattenDepth(data, 2),
        };

    }

    static placeBetValidation = function (fixtures, type, multiStake = 0) {

        let message = null;

        if (type === 2) {
            if (multiStake <= 0.00) {
                message = 'The multi stake must be greater than 0.';
                return message;
            }

            _.forEach(fixtures, f => {
                let canNotBeCombined = (_.size(f.markets) > 1 || _.size(f.markets[0].bets) > 1);
                if (canNotBeCombined) {
                    message = 'Can not be combined.';
                    return message;
                }

                _.forEach(f.markets, m => {
                    _.forEach(m.bets, b => {
                        if (b.Status !== 1) {
                            message = b.Status === 2 ? ('The bet is suspended (bets cannot be placed)') : ("The bet is settled(bets cannot be placed)")
                            return message;
                        }
                    })
                })
            })
        } else if (type === 1) {
            let count = 0;
            _.forEach(fixtures, f => {
                _.forEach(f.markets, m => {
                    _.forEach(m.bets, b => {

                        count++;

                        if (b.Status !== 1) {
                            message = b.Status === 2 ? ('The bet is suspended (bets cannot be placed)') : ("The bet is settled(bets cannot be placed)")
                            return message;
                        } if (b.Stake <= 0.00) {

                            message = 'The stake must be greater than 0.';
                            return message;
                        }
                    })
                })
            })
        }

        return message;

    }


    static placeBetValidationLimitsSingleBet = function (fixtures, betslip) {

        let message = null;

        let limits = betslip.limits;

        let maxStake = _.find(limits, { id: 1 });
        let minStake = _.find(limits, { id: 2 });
        let maxWin = _.find(limits, { id: 3 });
        let minBetAmount = _.find(limits, { id: 6 });

        let count = 0;
        _.forEach(fixtures, f => {
            _.forEach(f.markets, m => {
                _.forEach(m.bets, b => {

                    count++;

                    if (b.Stake > maxStake.default_limit) {
                        message = 'The stake must be less than ' + maxStake.default_limit + ".";
                        return message;
                    }

                    if (b.Stake < minStake.default_limit) {
                        message = 'The stake must be greater than ' + minStake.default_limit + ".";
                        return message;
                    }
                })
            })
        })

        let totalFixtures = betslip.fixtures;
        let totalWin = 0.00;
        _.forEach(totalFixtures, fixture => {
            _.forEach(fixture.markets, market => {
                _.forEach(market.bets, bet => {
                    totalWin += (parseFloat(bet.Stake) * parseFloat(bet.Price));
                })
            })
        })

        if (totalWin > maxWin.default_limit) {
            message = 'Max win amount is ' + maxWin.default_limit + ".";
            return message;
        }

        return message;

    }

    static placeBetValidationLimitsMultiBet = function (fixtures, betslip) {

        let message = null;

        let limits = betslip.limits;
        let totalMultiOdds = betslip.totalMultiOdds;
        let multiStake = betslip.multiStake;
        let totalWin = (parseFloat(multiStake) * parseFloat(totalMultiOdds)).toFixed(2)

        let maxOdd = _.find(limits, { id: 4 });
        let maxWin = _.find(limits, { id: 3 });
        let minStake = _.find(limits, { id: 9 });


        if (totalMultiOdds > maxOdd.default_limit) {
            message = 'Max odd is ' + maxOdd.default_limit + ".";
            return message;
        }

        if (totalWin > maxWin.default_limit) {
            message = 'Max win amount is ' + maxWin.default_limit + ".";
            return message;
        }

        if (multiStake < minStake.default_limit) {
            message = 'The stake must be greater than ' + minStake.default_limit + ".";
            return message;
        }

        return message;

    }


}

export default Util

