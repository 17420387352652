
export const API_URL = "http://cp.melkonian.nl/api/auth/";
export const apiConfig = {
    routes: {
        login: API_URL + "login",
        logout: API_URL + "logout",
        user: API_URL + "user",
        placeBet: API_URL + "betslips/place",
        getLimits: API_URL + "bettinglimit/get_limits",
        getBetslips: API_URL + "betslips",
    }
}