
export const homeConfig = {
  gridItems: [
    { id: 0, name: 'Sports', icon: 'icon-soccer', iconColor: 'icon-color', path: '/prematch' },
    { id: 1, name: '', icon: 'icon-live', iconColor: 'bg-live', path: '/inplay' },
    { id: 2, name: 'Today', icon: 'icon-today', iconColor: 'icon-color', path: '' },
    { id: 3, name: 'Highlights', icon: 'icon-highlights-1', iconColor: 'icon-color', path: '' },
    { id: 4, name: 'Results', icon: 'icon-score', iconColor: 'icon-color', path: '' },
    { id: 5, name: 'Last Minute', icon: 'icon-last-minute', iconColor: 'icon-color', path: '' },
    { id: 6, name: 'Live Casino', icon: 'icon-live-casino', iconColor: 'icon-color', path: '/live-casino' },
    { id: 7, name: 'Casino', icon: 'icon-casino-1', iconColor: 'icon-color', path: '/casino' },
    { id: 8, name: 'My Bets', icon: 'icon-my-bets', iconColor: 'icon-color', path: '' },
  ]
}

