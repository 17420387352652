export const LSPORTS_URL = "http://prematch.lsports.eu/OddService/";
export const lSportsConfig = {
    routes: {
        GetSports: LSPORTS_URL + "GetSports",
        GetLocations: LSPORTS_URL + "GetLocations",
        GetEvents: LSPORTS_URL + "GetEvents",
        EnablePackage: LSPORTS_URL + "EnablePackage",
    },
    account: {
        prematch: {
            username: "effegamings@gmail.com",
            password: "fe7n47n5ud3",
            guid: "5f2194c7-387e-4fcc-a402-6db2b1d684b1",
            packageID: "2379",

        },
        inplay: {
            username: "effegamings@gmail.com",
            password: "fe7n47n5ud3",
            packageID: "2380",

        }

    },
    filter: {
        events: {
            sports: '54094',
            //sports: '6046,48242,35232,54094,154830,154919',
            locations: '248,103,243,161,215,142,147,122,76,176,218,131,137,172,148,60,241,242,207,80,155,61,50,71,17,236,4,48,79,171,22,154,179,223,123,83,202,135,86,187,216',
            markets: '1,4,59,2,7,13,52,6,11,17,16,12,63,5,62,25,55,56,41,42,19,23,22,34,35,79,80,81,84,82,92,95,96,97,104,105,106,107,108,109,110,111,113,123,124,128,134,137,138,139,140,143,144,145,146,147,148,149,150,151,161,168,169,188,189,190,192,194,195,196,197,198,199,202,203,207,208,209,210,211,212,213,215,216,218,219,238,245,181,182,183,184,185,186,187,21,30,31,50,51,57,61,70,71,72,73,77,85,91,98,99,100,163,170,171,214,217,242,243,260,261,282,284,285,289,292,300,390,391,392,393,406,409,410,411,412,419,420,421,433,456,457,461,579,600,762,941,1030,43,44,101,102,220,221,386,387,388,602,45,224,9,90,159,164,165,166,175,176,177,178,204,205,206,274,286,299,343,344,345,346,347,754,819,882,897,898,908,1000,74,75,76,337,338,440,834,909,910,29,88,662'
        }

    },
    inplay: {

        mainMarket: [
            {
                Id: 6046, Name: "Football", selectedMarket: 1,
                Markets: [
                    { Id: 1, Name: "Match Result" },
                    { Id: 238, Name: " Remaining match" },
                    { Id: 59, Name: "Next Goal" },
                    { Id: 7, Name: "Double Chance" },
                    { Id: 52, Name: "Draw no bet" },
                    { Id: 420, Name: "Remaining match Overtime" },

                ],
            },
            {
                Id: 48242, Name: "Basketball", selectedMarket: 1,
                Markets: [
                    { Id: 1, Name: "Match Result" },
                    { Id: 238, Name: " Remaining match" },
                    { Id: 5, Name: "Odd/Even", },
                    { Id: 7, Name: "Double Chance" },
                    { Id: 52, Name: "Draw no bet" },
                ],
            },
            {
                Id: 35232, Name: "Ice Hockey", selectedMarket: 1,
                Markets: [
                    { Id: 1, Name: "Match Result" },
                    { Id: 238, Name: " Remaining match" },
                    { Id: 59, Name: "Next Goal" },
                    { Id: 5, Name: "Odd/Even", },
                    { Id: 7, Name: "Double Chance" },
                    { Id: 52, Name: "Draw no bet" },

                ],
            },
            {
                Id: 54094, Name: "Tennis", selectedMarket: 52,
                Markets: [
                    { Id: 52, Name: "Draw no bet" },
                    { Id: 238, Name: " Remaining match" },
                    { Id: 5, Name: "Odd/Even", },
                    { Id: 41, Name: "1st Period Winner" },
                    { Id: 42, Name: "2nd Period Winner" },
                    { Id: 43, Name: "3rd Period Winner" },
                    { Id: 44, Name: "4th Period Winner" },
                ],
            },
            {
                Id: 154830, Name: "Volleyball", selectedMarket: 52,
                Markets: [
                    { Id: 52, Name: "Draw no bet" },
                    { Id: 238, Name: " Remaining match" },
                    { Id: 17, Name: "Both Teams To Score" },
                    { Id: 41, Name: "1st Period Winner" },
                    { Id: 42, Name: "2nd Period Winner" },
                    { Id: 43, Name: "3rd Period Winner" },
                    { Id: 44, Name: "4th Period Winner" },

                ],
            },
            {
                "Id": 154919, "Name": "Boxing", selectedMarket: 52,
                Markets: [
                    { Id: 1, Name: "Match Result" },
                    { Id: 52, Name: "Draw no bet" },
                    { Id: 7, Name: "Double Chance" },
                    { Id: 88, Name: "To Qualify" },
                    { Id: 662, Name: "Odd/Even Rounds" },
                ],
            },
        ],
        selectedSport: 6046,
    },
    prematch: {

        mainMarket: [
            {
                Id: 6046, Name: "Football", selectedMarket: 1,
                Markets: [
                    { Id: 1, Name: "Match Result" },

                    { Id: 7, Name: "Double Chance" },
                    { Id: 52, Name: "2 Way" },

                ],
            },
            {
                Id: 48242, Name: "Basketball", selectedMarket: 1,
                Markets: [
                    { Id: 1, Name: "Match Result" },

                    { Id: 5, Name: "Odd/Even", },
                    { Id: 7, Name: "Double Chance" },
                    { Id: 52, Name: "Draw no bet" },
                ],
            },
            {
                Id: 35232, Name: "Ice Hockey", selectedMarket: 1,
                Markets: [
                    { Id: 1, Name: "Match Result" },


                    { Id: 5, Name: "Odd/Even", },
                    { Id: 7, Name: "Double Chance" },
                    { Id: 52, Name: "Draw no bet" },

                ],
            },
            {
                Id: 54094, Name: "Tennis", selectedMarket: 52,
                Markets: [
                    { Id: 52, Name: "Draw no bet" },

                    { Id: 5, Name: "Odd/Even", },
                    { Id: 41, Name: "1st Period Winner" },
                    { Id: 42, Name: "2nd Period Winner" },
                    { Id: 43, Name: "3rd Period Winner" },
                    { Id: 44, Name: "4th Period Winner" },
                ],
            },
            {
                Id: 154830, Name: "Volleyball", selectedMarket: 52,
                Markets: [
                    { Id: 52, Name: "Draw no bet" },
                    { Id: 17, Name: "Both Teams To Score" },
                    { Id: 41, Name: "1st Period Winner" },
                    { Id: 42, Name: "2nd Period Winner" },
                    { Id: 43, Name: "3rd Period Winner" },
                    { Id: 44, Name: "4th Period Winner" },

                ],
            },
            {
                "Id": 154919, "Name": "Boxing", selectedMarket: 52,
                Markets: [
                    { Id: 1, Name: "Match Result" },
                    { Id: 52, Name: "Draw no bet" },
                    { Id: 7, Name: "Double Chance" },
                    { Id: 88, Name: "To Qualify" },
                    { Id: 662, Name: "Odd/Even Rounds" },
                ],
            },
        ],
        selectedSport: 6046,
    },
    sort: {
        sport: [
            { Id: 6046, Description: "Football", Sort: 1, Icon: "icon-soccer" },
            { Id: 48242, Description: "Basketball", Sort: 2, Icon: "icon-basketball" },
            { Id: 35232, Description: "Ice Hockey", Sort: 3, Icon: "icon-hockey" },
            { Id: 54094, Description: "Tennis", Sort: 4, Icon: "icon-tennis" },
            { Id: 154830, Description: "Volleyball", Sort: 5, Icon: "icon-volleyball" },
            { "Id": 154919, "Description": "Boxing", Sort: 8, Icon: "icon-boxing" },
        ],

        location: [
            {
                SportId: 6046,
                Locations: [
                    { Id: 248, Description: "International", "Sort": 1 },
                    { Id: 103, Description: "Turkey", "Sort": 2 },
                    { Id: -1, Description: "Turkey Amateur", "Sort": 3 },
                    { Id: 243, Description: "England", "Sort": 4 },
                    { Id: -1, Description: "England Amateur", "Sort": 5 },
                    { Id: 161, Description: "Germany", "Sort": 6 },
                    { Id: 215, Description: "Italy", "Sort": 7 },
                    { Id: 142, Description: "Spain", "Sort": 8 },
                    { Id: 147, Description: "France", "Sort": 9 },
                    { Id: 122, Description: "Netherlands", "Sort": 10 },
                    { Id: 76, Description: "Portugal", "Sort": 11 },
                    { Id: 176, Description: "Belgium", "Sort": 12 },
                    { Id: 218, Description: "Mexico", "Sort": 13 },
                    { Id: 131, Description: "Morocco", "Sort": 14 },
                    { Id: 137, Description: "Algeria", "Sort": 15 },
                    { Id: 172, Description: "Australia", "Sort": 16 },
                    { Id: 148, Description: "Japan", "Sort": 17 },
                    { Id: 60, Description: "Israel", "Sort": 18 },
                    { Id: 241, Description: "Northern Ireland", "Sort": 19 },
                    { Id: 242, Description: "Wales", "Sort": 20 },
                    { Id: -1, Description: "United Arab Emirates", "Sort": 21 },
                    { Id: 207, Description: "Egypt", "Sort": 22 },
                    { Id: 80, Description: "Saudi Arabia", "Sort": 23 },
                    { Id: 155, Description: "Kuwait", "Sort": 24 },
                    { Id: 61, Description: "India", "Sort": 25 },
                    { Id: 50, Description: "Guatemala", "Sort": 26 },
                    { Id: 71, Description: "Bolivia", "Sort": 27 },
                    { Id: 17, Description: "Oman", "Sort": 28 },
                    { Id: 236, Description: "Tanzania", "Sort": 29 },
                ]
            },
            {
                SportId: 48242,
                Locations: [
                    { Id: 4, Description: "United States", "Sort": 1 },
                    { Id: 248, Description: "International", "Sort": 2 },
                    { Id: 142, Description: "Spain", "Sort": 3 },
                    { Id: 215, Description: "Italy", "Sort": 4 },
                    { Id: 161, Description: "Germany", "Sort": 5 },
                    { Id: 48, Description: "Greece", "Sort": 6 },
                    { Id: 172, Description: "Australia", "Sort": 7 },
                    { Id: 103, Description: "Turkey", "Sort": 8 },
                    { Id: 79, Description: "Russia", "Sort": 9 },
                    { Id: 171, Description: "Austria", "Sort": 10 },
                    { Id: 60, Description: "Israel", "Sort": 11 },
                    { Id: 22, Description: "Poland", "Sort": 12 },
                    { Id: 154, Description: "South Korea", "Sort": 13 },
                    { Id: 148, Description: "Japan", "Sort": 14 },
                    { Id: 179, Description: "Bahrain", "Sort": 15 },
                    { Id: 223, Description: "Qatar", "Sort": 16 },
                ]
            },
            {
                SportId: 35232,
                Locations: [
                    { Id: 4, Description: "United States", "Sort": 1 },
                    { Id: 79, Description: "Russia", "Sort": 2 },
                    { Id: 248, Description: "International", "Sort": 3 },
                    { Id: 161, Description: "Germany", "Sort": 4 },
                    { Id: 147, Description: "France", "Sort": 5 },
                    { Id: 123, Description: "Norway", "Sort": 6 },
                    { Id: 83, Description: "Sweden", "Sort": 7 },
                    { Id: 202, Description: "Czech Republic", "Sort": 8 },
                    { Id: 135, Description: "Denmark", "Sort": 9 },
                    { Id: 171, Description: "Austria", "Sort": 10 },
                    { Id: 86, Description: "Slovakia", "Sort": 11 },
                    { Id: 187, Description: "Belarus", "Sort": 12 },
                    { Id: 216, Description: "Kazakhstan", "Sort": 13 },
                ]
            },
        ],
        bet: [
            {
                marketId: 1,
                bets: [
                    { Name: "1", Sort: 0 },
                    { Name: "X", Sort: 1 },
                    { Name: "2", Sort: 2 },
                ]
            }

        ],
        market: [
            {
                sportId: 6046,
                markets: [
                    { Id: 1, Sort: 0 },
                    { Id: 52, Sort: 1 },
                    { Id: 59, Sort: 2 },
                    { Id: 2, Sort: 3 },
                    { Id: 7, Sort: 4 },
                    { Id: 13, Sort: 5 },
                    { Id: 4, Sort: 6 },
                    { Id: 6, Sort: 7 },
                    { Id: 11, Sort: 8 },
                    { Id: 17, Sort: 9 },
                    { Id: 16, Sort: 10 },
                    { Id: 12, Sort: 11 },
                    { Id: 63, Sort: 12 },
                    { Id: 5, Sort: 13 },
                    { Id: 62, Sort: 14 },
                    { Id: 25, Sort: 15 },
                    { Id: 55, Sort: 16 },
                    { Id: 56, Sort: 17 },
                    { Id: 41, Sort: 18 },
                    { Id: 42, Sort: 19 },
                    { Id: 19, Sort: 20 },
                    { Id: 12, Sort: 21 },
                    { Id: 23, Sort: 21 },
                    { Id: 22, Sort: 23 },
                    { Id: 34, Sort: 24 },
                    { Id: 35, Sort: 25 },
                    { Id: 79, Sort: 26 },
                    { Id: 80, Sort: 27 },
                    { Id: 81, Sort: 28 },
                    { Id: 84, Sort: 29 },
                    { Id: 82, Sort: 30 },
                    { Id: 92, Sort: 31 },
                    { Id: 95, Sort: 32 },
                    { Id: 96, Sort: 33 },
                    { Id: 97, Sort: 34 },
                    { Id: 104, Sort: 35 },
                    { Id: 105, Sort: 36 },
                    { Id: 106, Sort: 37 },
                    { Id: 107, Sort: 38 },
                    { Id: 108, Sort: 39 },
                    { Id: 109, Sort: 40 },
                    { Id: 110, Sort: 41 },
                    { Id: 111, Sort: 42 },
                    { Id: 113, Sort: 43 },
                    { Id: 123, Sort: 44 },
                    { Id: 124, Sort: 45 },
                    { Id: 128, Sort: 46 },
                    { Id: 134, Sort: 47 },
                    { Id: 137, Sort: 48 },
                    { Id: 138, Sort: 49 },
                    { Id: 139, Sort: 50 },
                    { Id: 140, Sort: 51 },
                    { Id: 143, Sort: 52 },
                    { Id: 144, Sort: 53 },
                    { Id: 145, Sort: 54 },
                    { Id: 146, Sort: 55 },
                    { Id: 147, Sort: 56 },
                    { Id: 148, Sort: 57 },
                    { Id: 149, Sort: 58 },
                    { Id: 150, Sort: 59 },
                    { Id: 151, Sort: 60 },
                    { Id: 161, Sort: 61 },
                    { Id: 168, Sort: 62 },
                    { Id: 169, Sort: 63 },
                    { Id: 188, Sort: 64 },
                    { Id: 189, Sort: 65 },
                    { Id: 190, Sort: 66 },
                    { Id: 192, Sort: 67 },
                    { Id: 194, Sort: 68 },
                    { Id: 195, Sort: 69 },
                    { Id: 196, Sort: 70 },
                    { Id: 197, Sort: 71 },
                    { Id: 198, Sort: 72 },
                    { Id: 199, Sort: 73 },
                    { Id: 202, Sort: 74 },
                    { Id: 203, Sort: 75 },
                    { Id: 207, Sort: 76 },
                    { Id: 208, Sort: 77 },
                    { Id: 209, Sort: 78 },
                    { Id: 210, Sort: 79 },
                    { Id: 211, Sort: 70 },
                    { Id: 212, Sort: 71 },
                    { Id: 213, Sort: 72 },
                    { Id: 215, Sort: 73 },
                    { Id: 216, Sort: 74 },
                    { Id: 218, Sort: 75 },
                    { Id: 219, Sort: 76 },
                    { Id: 238, Sort: 77 },
                    { Id: 245, Sort: 78 },
                    { Id: 181, Sort: 79 },
                    { Id: 182, Sort: 80 },
                    { Id: 183, Sort: 81 },
                    { Id: 184, Sort: 82 },
                    { Id: 185, Sort: 83 },
                    { Id: 186, Sort: 84 },
                    { Id: 187, Sort: 85 },
                    { Id: 21, Sort: 86 },
                    { Id: 25, Sort: 87 },
                    { Id: 30, Sort: 88 },
                    { Id: 31, Sort: 89 },
                    { Id: 50, Sort: 90 },
                    { Id: 51, Sort: 91 },
                    { Id: 57, Sort: 92 },
                    { Id: 61, Sort: 93 },
                    { Id: 70, Sort: 94 },
                    { Id: 71, Sort: 95 },
                    { Id: 72, Sort: 96 },
                    { Id: 73, Sort: 97 },
                    { Id: 77, Sort: 98 },
                    { Id: 85, Sort: 99 },
                    { Id: 91, Sort: 100 },
                    { Id: 498, Sort: 101 },
                    { Id: 99, Sort: 102 },
                    { Id: 100, Sort: 103 },
                    { Id: 163, Sort: 104 },
                    { Id: 170, Sort: 105 },
                    { Id: 171, Sort: 106 },
                    { Id: 214, Sort: 107 },
                    { Id: 4217, Sort: 108 },
                    { Id: 242, Sort: 109 },
                    { Id: 4243, Sort: 110 },
                    { Id: 260, Sort: 111 },
                    { Id: 261, Sort: 112 },
                    { Id: 282, Sort: 113 },
                    { Id: 284, Sort: 114 },
                    { Id: 285, Sort: 115 },
                    { Id: 289, Sort: 116 },
                    { Id: 292, Sort: 117 },
                    { Id: 300, Sort: 118 },
                    { Id: 390, Sort: 119 },
                    { Id: 391, Sort: 120 },
                    { Id: 392, Sort: 121 },
                    { Id: 393, Sort: 122 },
                    { Id: 406, Sort: 123 },
                    { Id: 409, Sort: 124 },
                    { Id: 410, Sort: 125 },
                    { Id: 411, Sort: 126 },
                    { Id: 412, Sort: 127 },
                    { Id: 419, Sort: 128 },
                    { Id: 421, Sort: 129 },
                    { Id: 433, Sort: 130 },
                    { Id: 456, Sort: 131 },
                    { Id: 457, Sort: 132 },
                    { Id: 461, Sort: 133 },
                    { Id: 579, Sort: 134 },
                    { Id: 600, Sort: 135 },
                    { Id: 762, Sort: 136 },
                    { Id: 941, Sort: 137 },
                    { Id: 1030, Sort: 138 },

                ]
            },
            {
                sportId: 48242,
                markets: [
                    { Id: 1, Sort: 0 },
                    { Id: 52, Sort: 1 },
                    { Id: 4, Sort: 2 },
                    { Id: 2, Sort: 2 },
                    { Id: 5, Sort: 3 },
                    { Id: 6, Sort: 4 },
                    { Id: 7, Sort: 5 },
                    { Id: 13, Sort: 6 },
                    { Id: 16, Sort: 7 },
                    { Id: 17, Sort: 8 },
                    { Id: 21, Sort: 9 },
                    { Id: 41, Sort: 10 },
                    { Id: 42, Sort: 11 },
                    { Id: 43, Sort: 12 },
                    { Id: 44, Sort: 13 },
                    { Id: 51, Sort: 14 },
                    { Id: 56, Sort: 15 },
                    { Id: 63, Sort: 16 },
                    { Id: 70, Sort: 17 },
                    { Id: 101, Sort: 18 },
                    { Id: 102, Sort: 19 },
                    { Id: 198, Sort: 20 },
                    { Id: 199, Sort: 21 },
                    { Id: 220, Sort: 21 },
                    { Id: 221, Sort: 23 },
                    { Id: 386, Sort: 24 },
                    { Id: 387, Sort: 25 },
                    { Id: 388, Sort: 26 },
                    { Id: 390, Sort: 27 },
                    { Id: 602, Sort: 28 },
                ]
            },
            {
                sportId: 35232,
                markets: [
                    { Id: 1, Sort: 0 },
                    { Id: 52, Sort: 1 },
                    { Id: 2, Sort: 2 },
                    { Id: 59, Sort: 3 },
                    { Id: 6, Sort: 4 },
                    { Id: 7, Sort: 5 },
                    { Id: 4, Sort: 6 },
                    { Id: 16, Sort: 7 },
                    { Id: 17, Sort: 8 },
                    { Id: 22, Sort: 9 },
                    { Id: 23, Sort: 10 },
                    { Id: 25, Sort: 11 },
                    { Id: 34, Sort: 12 },
                    { Id: 35, Sort: 13 },
                    { Id: 41, Sort: 14 },
                    { Id: 42, Sort: 15 },
                    { Id: 45, Sort: 16 },
                    { Id: 13, Sort: 17 },
                    { Id: 5, Sort: 18 },
                    { Id: 61, Sort: 19 },
                    { Id: 96, Sort: 20 },
                    { Id: 97, Sort: 21 },
                    { Id: 113, Sort: 21 },
                    { Id: 151, Sort: 23 },
                    { Id: 192, Sort: 24 },
                    { Id: 211, Sort: 25 },
                    { Id: 212, Sort: 26 },
                    { Id: 215, Sort: 27 },
                    { Id: 216, Sort: 28 },
                    { Id: 218, Sort: 29 },
                    { Id: 219, Sort: 30 },
                    { Id: 224, Sort: 31 },

                ]
            },
            {
                sportId: 54094,
                markets: [
                    { Id: 52, Sort: 0 },
                    { Id: 2, Sort: 1 },
                    { Id: 4, Sort: 2 },
                    { Id: 5, Sort: 3 },
                    { Id: 6, Sort: 4 },
                    { Id: 9, Sort: 5 },
                    { Id: 13, Sort: 6 },
                    { Id: 21, Sort: 7 },
                    { Id: 41, Sort: 8 },
                    { Id: 42, Sort: 9 },
                    { Id: 43, Sort: 10 },
                    { Id: 44, Sort: 11 },
                    { Id: 61, Sort: 12 },
                    { Id: 62, Sort: 13 },
                    { Id: 85, Sort: 14 },
                    { Id: 90, Sort: 15 },
                    { Id: 100, Sort: 16 },
                    { Id: 106, Sort: 17 },
                    { Id: 107, Sort: 18 },
                    { Id: 149, Sort: 19 },
                    { Id: 159, Sort: 20 },
                    { Id: 164, Sort: 21 },
                    { Id: 165, Sort: 21 },
                    { Id: 166, Sort: 23 },
                    { Id: 168, Sort: 24 },
                    { Id: 175, Sort: 25 },
                    { Id: 176, Sort: 26 },
                    { Id: 177, Sort: 27 },
                    { Id: 178, Sort: 28 },
                    { Id: 198, Sort: 29 },
                    { Id: 199, Sort: 30 },
                    { Id: 202, Sort: 31 },
                    { Id: 203, Sort: 32 },
                    { Id: 204, Sort: 33 },
                    { Id: 205, Sort: 34 },
                    { Id: 206, Sort: 35 },
                    { Id: 274, Sort: 36 },
                    { Id: 285, Sort: 37 },
                    { Id: 286, Sort: 38 },
                    { Id: 299, Sort: 39 },
                    { Id: 300, Sort: 40 },
                    { Id: 343, Sort: 41 },
                    { Id: 344, Sort: 42 },
                    { Id: 345, Sort: 43 },
                    { Id: 346, Sort: 44 },
                    { Id: 347, Sort: 45 },
                    { Id: 456, Sort: 46 },
                    { Id: 457, Sort: 47 },
                    { Id: 754, Sort: 48 },
                    { Id: 819, Sort: 49 },
                    { Id: 882, Sort: 50 },
                    { Id: 897, Sort: 51 },
                    { Id: 898, Sort: 52 },
                    { Id: 908, Sort: 53 },
                    { Id: 1000, Sort: 54 },
                ]
            },
            {
                sportId: 154830,
                markets: [
                    { Id: 52, Sort: 0 },
                    { Id: 4, Sort: 1 },
                    { Id: 2, Sort: 2 },
                    { Id: 6, Sort: 3 },
                    { Id: 5, Sort: 4 },
                    { Id: 13, Sort: 5 },
                    { Id: 16, Sort: 6 },
                    { Id: 17, Sort: 7 },
                    { Id: 21, Sort: 8 },
                    { Id: 41, Sort: 9 },
                    { Id: 42, Sort: 10 },
                    { Id: 43, Sort: 11 },
                    { Id: 44, Sort: 12 },
                    { Id: 62, Sort: 13 },
                    { Id: 63, Sort: 14 },
                    { Id: 70, Sort: 15 },
                    { Id: 72, Sort: 16 },
                    { Id: 73, Sort: 17 },
                    { Id: 74, Sort: 18 },
                    { Id: 75, Sort: 19 },
                    { Id: 76, Sort: 20 },
                    { Id: 90, Sort: 21 },
                    { Id: 113, Sort: 21 },
                    { Id: 164, Sort: 23 },
                    { Id: 175, Sort: 24 },
                    { Id: 202, Sort: 25 },
                    { Id: 203, Sort: 26 },
                    { Id: 204, Sort: 27 },
                    { Id: 205, Sort: 28 },
                    { Id: 206, Sort: 29 },
                    { Id: 286, Sort: 30 },
                    { Id: 299, Sort: 31 },
                    { Id: 337, Sort: 32 },
                    { Id: 338, Sort: 33 },
                    { Id: 440, Sort: 34 },
                    { Id: 834, Sort: 35 },
                    { Id: 909, Sort: 36 },
                    { Id: 910, Sort: 37 },
                    { Id: 285, Sort: 38 },
                ]
            },
            {
                sportId: 154919,
                markets: [
                    { Id: 1, Sort: 0 },
                    { Id: 52, Sort: 1 },
                    { Id: 2, Sort: 2 },
                    { Id: 7, Sort: 3 },
                    { Id: 29, Sort: 4 },
                    { Id: 88, Sort: 5 },
                    { Id: 662, Sort: 6 },
                ]
            },

        ]

    }
}