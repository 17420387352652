import axios from "axios";
import { lSportsConfig } from "../../config"
import EventEmitter from "../../helper/EventEmitter";



class lSportsService extends EventEmitter { //

  enablePackage = () => {
    return new Promise((resolve, reject) => {
      axios
        .get(lSportsConfig.routes.EnablePackage, {
          params: {
            username: lSportsConfig.account.prematch.username,
            password: lSportsConfig.account.prematch.password,
            guid: lSportsConfig.account.prematch.guid,
            packageid: lSportsConfig.account.prematch.packageID,
          }
        })
        .then(response => {
          resolve(response);
        }).catch(error => {
          reject(error);
        });
    });
  };

  getSports = (lang) => {
    return new Promise((resolve, reject) => {
      axios
        .get(lSportsConfig.routes.GetSports, {
          params: {
            username: lSportsConfig.account.prematch.username,
            password: lSportsConfig.account.prematch.password,
            guid: lSportsConfig.account.prematch.guid,
            lang: lang
          }
        })
        .then(response => {
          resolve(response);
        }).catch(error => {
          reject(error);
        });
    });
  };

  getLocations = (lang) => {
    return new Promise((resolve, reject) => {
      axios
        .get(lSportsConfig.routes.GetLocations, {
          params: {
            username: lSportsConfig.account.prematch.username,
            password: lSportsConfig.account.prematch.password,
            guid: lSportsConfig.account.prematch.guid,
            lang: lang
          }
        })
        .then(response => {
          resolve(response);
        }).catch(error => {
          reject(error);
        });
    });
  };

  getEvents = (lang) => {
    return new Promise((resolve, reject) => {
      axios
        .get(lSportsConfig.routes.GetEvents, {
          params: {
            username: lSportsConfig.account.prematch.username,
            password: lSportsConfig.account.prematch.password,
            guid: lSportsConfig.account.prematch.guid,
            lang: lang,
            sports: lSportsConfig.filter.events.sports,
            locations: lSportsConfig.filter.events.locations,
            markets: lSportsConfig.filter.events.markets,
          }
        })
        .then(response => {
          resolve(response);
        }).catch(error => {
          reject(error);
        });
    });
  };

  fetchEvents = (lang) => {
    return new Promise((resolve, reject) => {
      fetch('http://lsports.melkonian.nl/data/prematch/getevents.json')
        .then(response => response.json())
        .then(data => resolve(data));
    });
  };


  getEventsBySport = (lang, sportId) => {
    return new Promise((resolve, reject) => {
      axios
        .get(lSportsConfig.routes.GetEvents, {
          params: {
            username: lSportsConfig.account.prematch.username,
            password: lSportsConfig.account.prematch.password,
            guid: lSportsConfig.account.prematch.guid,
            lang: lang,
            sports: sportId,
            locations: lSportsConfig.filter.events.locations,
            markets: lSportsConfig.filter.events.markets,
          }
        })
        .then(response => {
          resolve(response);
        }).catch(error => {
          reject(error);
        });
    });
  };

  getEventsBySportAndLocation = (lang, sportId, locationId) => {
    return new Promise((resolve, reject) => {
      axios
        .get(lSportsConfig.routes.GetEvents, {
          params: {
            username: lSportsConfig.account.prematch.username,
            password: lSportsConfig.account.prematch.password,
            guid: lSportsConfig.account.prematch.guid,
            lang: lang,
            sports: sportId,
            locations: locationId,
            markets: lSportsConfig.filter.events.markets,
          }
        })
        .then(response => {
          resolve(response);
        }).catch(error => {
          reject(error);
        });
    });
  };


  //InPlay
  inPlayGetEvents = (lang) => {
    return new Promise((resolve, reject) => {
      fetch('http://lsports.melkonian.nl/data/inplay/getsnapshot.json')
        .then(response => response.json())
        .then(data => resolve(data));
    });
  };
}


const instance = new lSportsService();

export default instance;
