import React from 'react';
import { Row, Col } from 'react-bootstrap';
import _ from 'lodash';

import Util from '../../../helper/Util';

import MainBet from './MainBet';

class MainMarket extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            star: false,
        }
    }

    setStarActive = () => {
        this.setState({
            star: !this.state.star
        })
    }
    setActiveMainBtn = (value) => {
        var element = document.getElementById('live-panel-' + value);
        if (element.classList.contains('active')) {
            element.classList.remove("active");
        }
        else {
            element.classList.add("active");
        }
    }

    render() {


        let { star, isLive, market, fixture } = this.props;

        let selectedProvider = market.Providers[0];

        let bets = selectedProvider.Bets;

        bets = Util.sortBet(bets, market.Id);


        let drawBets = _.map(_.take(bets, 3), bet => {

            return <MainBet fixture={fixture} market={market} bet={bet} provider={selectedProvider} key={bet.Id} />;
        })

        return (
            <Row className="mx-auto match-inner-panel-bg main-market-odds">
                <div className="width-95 mx-auto pb-1">
                    <Col xs="2" lg="2" md="auto" className="display-flex text-center pl-3 p-2">
                        <i className={`fs-24 material-icons ${star ? 'activeStart' : ''}`} onClick={this.setStarActive}>star_border</i>
                    </Col>
                    {drawBets}
                </div>
            </Row>
        )
    }
}
export default MainMarket;