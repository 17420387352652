import React from 'react';
import starBurst from '../../images/starburst.jpg';
import bookOfDeadSlot from '../../images/bookofdeadslot.jpg';
import hotSpin from '../../images/hotspin.jpg';
import mysticMirror from '../../images/mysticmirror.jpg';
import bookOfGold from '../../images/bookofgold.jpg';
import wildape from '../../images/wildape.jpg';
import piratesPlenty from '../../images/piratesplenty.jpg';
import deadAlive from '../../images/deadalive.jpg';
import mysteryReels from '../../images/mysteryreels.jpg';
import fireJoker from '../../images/firejoker.png';
import narcos from '../../images/narcos.jpg';
import secretOfTheStones from '../../images/secretofthestone.jpg';
import netent from '../../images/netent.jpg';
import jumanji from '../../images/jumanji.jpg';
import megaPyramid from '../../images/megapyramid.jpg';
import {Fade} from 'react-reveal';
class Casino extends React.Component{
    constructor(props){
        super(props)

    }
    
    render(){
        return(
            <div className="stuff w-100">
                <div className="square ripple">
                    <div className="content-casino ">
                        {/* <div className="casino-img" /> */}
                        <img alt="Start Burst" className="casino-img" src={starBurst} />
                    </div>
                </div>
                <div className="square ripple">
                    <div className="content-casino">
                    <img alt="Book Of Dead Slot" className="casino-img"src={bookOfDeadSlot} />
                    </div>
                </div>
                <div className="square ripple">
                    <div className="content-casino">
                    <img alt="Hot Spin" className="casino-img"src={hotSpin} />
                    </div>
                </div>
                <div className="square ripple">
                    <div className="content-casino ">
                        {/* <div className="casino-img" /> */}
                        <img alt="Mystic Mirror" className="casino-img"src={mysticMirror} />
                    </div>
                </div>
                <div className="square ripple">
                    <div className="content-casino">
                    <img alt="Book Of Gold" className="casino-img"src={bookOfGold} />
                    </div>
                </div>
                <div className="square ripple">
                    <div className="content-casino">
                    <img alt="Wild APE" className="casino-img"src={wildape} />
                    </div>
                </div>
                <div className="square ripple">
                    <div className="content-casino ">
                        {/* <div className="casino-img" /> */}
                        <img alt="Pirates Plenty" className="casino-img"src={piratesPlenty} />
                    </div>
                </div>
                <div className="square ripple">
                    <div className="content-casino">
                    <img alt="Dead Alive" className="casino-img"src={deadAlive} />
                    </div>
                </div>
                <div className="square ripple">
                    <div className="content-casino">
                    <img alt="Mystery Reels" className="casino-img"src={mysteryReels} />
                    </div>
                </div>
                <div className="square ripple">
                    <div className="content-casino ">
                        {/* <div className="casino-img" /> */}
                        <img alt="Fire Joker" className="casino-img"src={fireJoker} />
                    </div>
                </div>
                <div className="square ripple">
                    <div className="content-casino">
                    <img alt="Narcos" className="casino-img"src={narcos} />
                    </div>
                </div>
                <div className="square ripple">
                    <div className="content-casino">
                    <img alt="Netent" className="casino-img"src={netent} />
                    </div>
                </div>
                <div className="square ripple">
                    <div className="content-casino ">
                        {/* <div className="casino-img" /> */}
                        <img alt="Secret Of The Stones" className="casino-img"src={secretOfTheStones} />
                    </div>
                </div>
                <div className="square ripple">
                    <div className="content-casino">
                    <img alt="Jumanji" className="casino-img"src={jumanji} />
                    </div>
                </div>
                <div className="square ripple">
                    <div className="content-casino">
                    <img alt="Mega Pyramid" className="casino-img"src={megaPyramid} />
                    </div>
                </div>
            </div>
        )
    }
}
export default Casino;