import React, { Component } from "react";
import * as Maps from "../store/map";
import { connect } from 'react-redux';

import { lSportsConfig } from '../config/lsports.config'
import _ from 'lodash';

class InPlayAMQ extends Component {


  componentDidMount() {
    let { isLoadingStarted } = this.props.lSports.inplay;

    if (isLoadingStarted !== true) {

      this.props.inplayStartLoading(1);
      this.props.inplayGetEvents("en");
    }

  }

  render() {
    return <React.Fragment children={this.props.children} />;
  }
}


export default connect(Maps.mapLSportsStateToProps, Maps.mapLSportsDispatchToProps)(InPlayAMQ);
