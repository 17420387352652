import React from 'react'
import Carousel from 'react-bootstrap/Carousel';
// import banner from '../../images/home-banner.jpg'
// import banner2 from '../../images/home-banner2.jpeg'

import banner from '../../images/home-banner-1.jpg'
import banner2 from '../../images/home-banner-2.jpg'
class HomeCarousel extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
        }
    }
    changeLanguage = (e) => {
        debugger
    }
    render() {

        return (
            <div>
                <Carousel className="mb-2">
                    <Carousel.Item>
                        <img
                            className="d-block w-100"
                            src={banner}
                            alt="First slide"
                        />
                        {/* <Carousel.Caption>
          <h3>First slide label</h3>
          <p>Nulla vitae elit libero, a pharetra augue mollis interdum.</p>
        </Carousel.Caption> */}
                    </Carousel.Item>
                    <Carousel.Item>
                        <img
                            className="d-block w-100"
                            src={banner2}
                            alt="Second slide"
                        />

                        {/* <Carousel.Caption>
          <h3>Second slide label</h3>
          <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
        </Carousel.Caption> */}
                    </Carousel.Item>
                    <Carousel.Item>
                        <img
                            className="d-block w-100"
                            src={banner}
                            alt="Third slide"
                        />

                        {/* <Carousel.Caption>
          <h3>Third slide label</h3>
          <p>
            Praesent commodo cursus magna, vel scelerisque nisl consectetur.
          </p>
        </Carousel.Caption> */}
                    </Carousel.Item>
                </Carousel>
            </div>

        )
    }
}
export default HomeCarousel;