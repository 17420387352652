import React from 'react';
import Carousel from './Carousel';
import Grid from './Grid';
import * as Maps from "../../store/map";
import { connect } from 'react-redux';



class Home extends React.Component {

    render() {

        return (
            <div className="main-container bg-f7">
                <Carousel {...this.props} />
                <Grid {...this.props} />
            </div>
        )
    }
}

export default connect(Maps.mapUserStateToProps, Maps.mapUserDispatchToProps)(Home);




