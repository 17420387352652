import React from "react";

import { homeConfig } from "../../config";

class Grid extends React.Component {

  render() {
    const items = homeConfig.gridItems;

    const squareList = (
      items.map(item => {
        return (
          <div className="square ripple" onClick={e => this.props.history.push(item.path)} key={item.id}>
            <div className="content ">
              <div className="center">
                <i className={"demo-icon " + item.iconColor + " " + item.icon + " lh-0"}></i>
                {item.name !== "" ? <div className="menu">{item.name}</div> : null}

              </div>
            </div>
          </div>
        )
      })
    )

    return (
      <div className="stuff pl-40 pr-40">
        {squareList}
      </div>
    );
  }

}

export default Grid;
