import React from 'react';
import { Container, Row, Col, Button } from 'react-bootstrap';

import Match from './Matche';
import _ from 'lodash';
import MainMarketSelector from './MainMarketSelector';


class Matches extends React.Component {
    constructor(props) {
        super(props)
    }

    removeLeague = (sportId, locationId, leagueId) => {
        this.props.inplayRemoveLeague(sportId, locationId, leagueId);
    }

    handleChange = (e) => {

    }

    render() {

        let { events, isLive } = this.props;

        var eventLength = events ? events.length : 0;

        let drawMatches = events ? (_.map(events, (e, i) => {
            let divider = eventLength - 1 > i ? (< div className="result-divider"> </div>) : (null);
            return (
                <div className="match-panel" key={"panel_" + e.FixtureId}>
                    <Match isLive={isLive} event={e} key={"event_" + e.FixtureId} />
                    {divider}
                </div>
            )
        })) : (null);


        let groupInfo = events ? (
            <div className="country">
                <Row className="bg-dark-gray mx-auto text-white pt-2 pb-2" >
                    <Col xs="10" lg="10" className="pl-2 text-overflow" >

                        {/* <img className="live-flag-25" src={turkeyFlag}></img> */}
                        <i className={"demo-icon " + events[0].Fixture.Sport.Icon + " lh-1p pr-1"}></i>
                        <div country={"flag_" + events[0].Fixture.Location.Id} className="flag live-flag-25"></div>
                        <span className="pl-1">{events[0].Fixture.Location.Name}</span>
                        <span className="pl-2 pr-1">|</span>
                        <span className="pl-1 text-overflow">{events[0].Fixture.League.Name}</span>

                    </Col>
                    <Col xs="2" lg="2" className="d-flex justify-content-end pr-2">
                        <i className="header-close-btn material-icons" onClick={(e) => { this.removeLeague(events[0].Fixture.Sport.Id, events[0].Fixture.Location.Id, events[0].Fixture.League.Id) }}> close </i>
                    </Col>
                </Row>
            </div>
        ) : (null);

        return (
            <div>
                <MainMarketSelector events={events} />



                {groupInfo}

                {drawMatches}

            </div>
        )
    }
}
export default Matches;