

import * as Actions from "../map/actions";

const initialState = {
  loading: false,
};

const generalReducer = function (state = initialState, action) {

  switch (action.type) {

    case Actions.SET_LOADING: {

      return {
        ...state,
        loading: action.value,
      };
    }

    default:
      return state;
  }
};


export default generalReducer;


