import { combineReducers } from "redux";
import user from "./user.reducer";
import lSports from "./lsports.reducer";

import betslip from "./betslip.reducer";
import general from "./general.reducer";

const reducer = combineReducers({
  general,
  user,
  lSports,
  betslip
});

export default reducer;
