
export const ADD_BET = "[APP] ADD BET";
export const REMOVE_BET = "[APP] REMOVE BET";
export const CLEAR_BETS = "[APP] CLEAR BETS";
export const SET_BETS = "[APP] SET BETS";
export const SET_MULTI_STAKE = "[APP] SET MULTI STAKE";
export const SET_BET_STAKE = "[APP] SET BET STAKE";
export const SET_LIMITS = "[APP] SET LIMITS";


export const addBet = (fixture, market, bet, provider) => {
  return (dispatch, getState) => {
    dispatch({ type: ADD_BET, fixture: fixture, market: market, bet: bet, provider: provider });
  }
}

export const removeBet = (fixture, market, bet, provider) => {
  return (dispatch, getState) => {
    dispatch({ type: REMOVE_BET, fixture: fixture, market: market, bet: bet, provider: provider });
  }
}

export const clearBets = () => {
  return (dispatch, getState) => {
    dispatch({ type: CLEAR_BETS });
  }
}

export const setBets = (fixtures) => {
  return (dispatch, getState) => {
    dispatch({ type: SET_BETS, fixtures: fixtures });
  }
}

export const setMultiStake = (value) => {
  return (dispatch, getState) => {
    dispatch({ type: SET_MULTI_STAKE, value: value });
  }
}

export const setBetStake = (fixture, market, bet, provider, value) => {
  return (dispatch, getState) => {
    dispatch({ type: SET_BET_STAKE, fixture: fixture, market: market, bet: bet, provider: provider, value: value });
  }
}

export const setLimits = (limits) => {
  return (dispatch, getState) => {
    dispatch({ type: SET_LIMITS, limits: limits });
  }
}


export function mapBetslipDispatchToProps(dispatch) {
  return {
    addBet: (fixture, market, bet, provider) => { dispatch(addBet(fixture, market, bet, provider)) },
    removeBet: (fixture, market, bet, provider) => { dispatch(removeBet(fixture, market, bet, provider)) },
    clearBets: () => { dispatch(clearBets()) },
    setBets: (fixtures) => { dispatch(setBets(fixtures)) },
    setMultiStake: (value) => { dispatch(setMultiStake(value)) },
    setBetStake: (fixture, market, bet, provider, value) => { dispatch(setBetStake(fixture, market, bet, provider, value)) },
    setLimits: (limits) => { dispatch(setLimits(limits)) },

  }
}

