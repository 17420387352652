import React from 'react'
class CasinoHeader extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            isSearchOpen: false,
            isActive: 'All'
        }
    }
    showSearchBar = (e) => {
        this.setState({ isSearchOpen: true })
    }
    hideSearchBar = (e) => {
        this.setState({ isSearchOpen: false })
    }
    activeTab = (tab) =>{
        this.setState({
            isActive: tab
        })
    }
    render() {
        let { isSearchOpen, isActive } = this.state;
        return (
            <div>
                {
                    isSearchOpen ?
                        <div className="casino-header disable-select mb-2">
                            <div className="input-wrapper">
                            <input id="stuff" autoFocus />
                            <label ForHtml="stuff" className="demo-icon icon-search input-icon"></label>
                            </div>
                            <div className="ml-2 pt-2" onClick={this.hideSearchBar}>Cancel</div>
                        </div>
                        :
                        <div className="casino-header disable-select mb-2" style={{ overflowX: "auto" }}>
                            
                            <div className='divbox mr-0' onClick={this.showSearchBar}>
                                <i className="demo-icon icon-search"></i>
                            </div>
                            <div className={`divbox box-min-width ${isActive==="All"?'active':''}`} onClick={(e => this.activeTab('All'))}>
                                <span>All</span>
                            </div>
                            <div className={`divbox ${isActive==="Netent"?'active':''}`} onClick={(e => this.activeTab('Netent'))}>
                                <span className="live-head-btn">Live Roulette</span>
                            </div>
                            <div className={`divbox ${isActive==="Novoline"?'active':''}`} onClick={(e => this.activeTab('Novoline'))}>
                                <span className="live-head-btn">Live Blackjack</span>
                            </div>
                            <div className={`divbox ${isActive==="EGT"?'active':''}`} onClick={(e => this.activeTab('EGT'))}>
                                <span className="live-head-btn">Live Poker</span>
                            </div>
                            <div className={`divbox ${isActive==="BTF"?'active':''}`} onClick={(e => this.activeTab('BTF'))}>
                                <span className="live-head-btn">Live Baccarat</span>
                            </div>
                        </div>
                }
            </div>
        )
    }
}
export default CasinoHeader;