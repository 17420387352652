import React, { Component } from 'react';
import { Row, Col } from 'react-bootstrap';
import Calulator from './Calculator'

import BetslipMultiBet from './BetslipMultiBet';
import MultiMiniCalculator from './MultiMiniCalculator';
import _ from 'lodash';

import * as Maps from "../../store/map";
import { connect } from 'react-redux';
import { compose } from 'redux'
import Util from "../../helper/Util";

class BetslipList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isShow: false
        }
    }

    componentDidMount() {
        this.props.setPlaceBetError(null);
    }

    componentWillReceiveProps(nextProps) {
        let { fixtures } = nextProps.betslip;
        let { placeBetError } = nextProps.user;

        if (this.props.general.loading === true && nextProps.general.loading === false && placeBetError === null) {

            let prepareForRemoval = Util.prepareAllForRemoval(fixtures);

            this.props.setBets(prepareForRemoval);

            setTimeout(this.props.setBets, 1000, []);
        }
    }


    openCalculator = (show) => {

        this.setState({ isShow: show });
    }

    closeModal = () => {
        this.setState({ isShow: false });
    }

    setMultiStake = (value) => {
        this.props.setMultiStake(value);
    }

    placeBet = () => {
        let { fixtures, multiStake } = this.props.betslip;
        let validation = Util.placeBetValidation(fixtures, 2, multiStake);

        if (validation !== null) {
            this.props.setPlaceBetError(validation);
            return;
        }

        validation = Util.placeBetValidationLimitsMultiBet(fixtures, this.props.betslip);

        if (validation !== null) {
            this.props.setPlaceBetError(validation);
            return;
        }


        let data = Util.convertToPlaceBetData(fixtures, 2, multiStake);
        this.props.placeBet(data);

    }

    render() {

        let { isShow, } = this.state;
        let { fixtures, totalMultiOdds, multiStake } = this.props.betslip;
        let { placeBetError } = this.props.user;
        let { switchBetslipType } = this.props;
        let index = 0;
        let drawBets = _.map(fixtures, fixture => {
            return _.map(fixture.markets, market => {
                return _.map(market.bets, bet => {
                    index++;
                    return <BetslipMultiBet switchBetslipType={switchBetslipType} fixture={fixture.fixture} market={market.market} index={index} bet={bet} key={bet.Id} />
                })
            })
        })

        let drawCalculator = isShow ? (<Calulator startValue={multiStake} possibleWin={(parseFloat(multiStake) * parseFloat(totalMultiOdds)).toFixed(2)} onSetValue={this.setMultiStake} onCloseModal={this.closeModal}></Calulator>) : (null);

        let error = placeBetError ? (
            <Row className="mx-auto fs-15">

                <Col xs="12" className="bg-dark p-1 text-center" >

                    <span className="material-icons icon mr-2" style={{
                        verticalAlign: 'middle',
                        color: 'red',
                    }}>error</span>
                    <span>{placeBetError}</span>

                </Col>
            </Row>
        ) : (null);

        let bootomContainer = _.size(fixtures) > 0 ? (
            <div className="bootom-container">
                {error}
                <MultiMiniCalculator openCalculator={this.openCalculator} />
                <Row className="mx-auto pt-1 pb-1 betslip-panel">
                    <Col xs="6">
                        Total odds
                        </Col>
                    <Col xs="6" className="text-right">
                        {parseFloat(totalMultiOdds).toFixed(2)}
                    </Col>
                </Row>
                {/* <div className="justify-content-md-center mx-auto rowSection">
                        <Row className="mx-auto yellow">
                            <Col xs="6" className="pl-1">
                                +10% Bonus
                            </Col>
                            <Col xs="6" className="text-right pr-1">
                                54.50
                            </Col>
                        </Row>
                    </div> */}
                <Row className="mx-auto pt-1 pb-1 betslip-panel">
                    <Col xs="6">
                        Total possible win
                        </Col>
                    <Col xs="6" className="text-right">
                        {(parseFloat(multiStake) * parseFloat(totalMultiOdds)).toFixed(2)}
                    </Col>
                </Row>
                <Row className="justify-content-md-center mx-auto">
                    <Col xs="12" className="bg-green" onClick={this.placeBet}>
                        <div className="fs-24 text-white tablinks place-btn">Place bet</div>
                    </Col>
                </Row>
            </div>
        ) : (null);

        return (
            <div>
                <div className="betslip-section mb-200">

                    {drawBets}
                </div>
                {bootomContainer}
                {drawCalculator}
            </div>

        )
    }
}
export default compose(
    connect(Maps.mapBetslipStateToProps, Maps.mapBetslipDispatchToProps),
    connect(Maps.mapUserStateToProps, Maps.mapUserDispatchToProps)
)(BetslipList);