import React from 'react';
import { Modal } from 'react-bootstrap'
import * as Maps from "../../store/map";
import { connect } from 'react-redux';

class LoadingSpinner extends React.Component {
    onHide = (e) => {
        e.preventDefault();
    }
    render() {
        let { loading } = this.props.general;
        return (
            <Modal
                size="sm"
                show={loading}
                onHide={this.onHide}
                aria-labelledby="example-modal-sizes-title-sm"
                centered
                className="loading-modal"
            >
                <Modal.Body>
                    <div className="d-flex justify-content-center">

                        <div className="spinner-grow text-light" role="status">
                            <span className="sr-only">Loading...</span>
                        </div>
                        <div className="spinner-grow text-light" role="status">
                            <span className="sr-only">Loading...</span>
                        </div>
                        <div className="spinner-grow text-light" role="status">
                            <span className="sr-only">Loading...</span>
                        </div>
                        <div className="spinner-grow text-light" role="status">
                            <span className="sr-only">Loading...</span>
                        </div>

                    </div>
                </Modal.Body>
            </Modal>
        )
    }
}
export default connect(Maps.mapGeneralStateToProps, Maps.mapGeneralDispatchToProps)(LoadingSpinner);