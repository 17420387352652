import React, { Component } from "react";
import * as Maps from "../store/map";
import { connect } from 'react-redux';

import { lSportsConfig } from '../config/lsports.config'
import _ from 'lodash';

class PrematchAMQ extends Component {


  componentDidMount() {
    let { isLoadingStarted } = this.props.lSports.prematch;


    // if (isLoadingStarted !== true) {
    //   var sports = lSportsConfig.filter.events.sports.split(',');
    //   var locations = lSportsConfig.filter.events.locations.split(',');
    //   this.props.startLoading(sports.length * locations.length);

    //   _.forEach(locations, l => {
    //     _.forEach(sports, s => {

    //       this.props.getEventsBySportAndLocation("en", s, l);
    //     })

    //   })
    // }

    if (isLoadingStarted !== true) {

      this.props.startLoading(1);
      this.props.fetchEvents("en");
    }

    //this.startInplayMQ(this.props);

  }

  startInplayMQ = (scope) => {
    var count = 0;
    var last_index = 0;
    var xhr = new XMLHttpRequest();
    xhr.open("GET", "http://localhost/CashPro/Web/EffebetMobile/public/3dparty/lsportMQ.php", true);
    xhr.onprogress = function (e) {
      count++;

      var curr_index = xhr.responseText.length;
      if (last_index == curr_index) return;
      var s = xhr.responseText.substring(last_index, curr_index);
      last_index = curr_index;
      var sanitized = '[' + s.replace(/}{/g, '},{') + ']';
      //console.log(sanitized);
      var res = JSON.parse(sanitized);
      _.forEach(res, h => {

        if (h.body) {
          var jsonBody = JSON.parse(h.body)
          if (jsonBody.Body) {
            scope.updateEvents(jsonBody.Body.Events);
            //console.log(jsonBody.Body.Events)
          }
          else {
            //console.error(h)
          }

        }
      })

    }
    xhr.onreadystatechange = function () {
      if (xhr.readyState == 4) {
        console.log("Complete = " + xhr.responseText);
      }
    }
    xhr.onerror = function () {
      console.log("** An error occurred during the transaction");
    };
    xhr.send();
  }

  render() {
    return <React.Fragment children={this.props.children} />;
  }
}


export default connect(Maps.mapLSportsStateToProps, Maps.mapLSportsDispatchToProps)(PrematchAMQ);
