import React from 'react';
import { Row, Col } from 'react-bootstrap';
import BetslipSingleBet from './BetslipSingleBet';
import _ from 'lodash';

import * as Maps from "../../store/map";
import { connect } from 'react-redux';
import Calulator from './Calculator'
import { compose } from 'redux'
import Util from "../../helper/Util";

class BetslipSingle extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            isShow: false,
            selectedBet: null,
            selectedFixture: null,
            selectedMarket: null,
        }
    }

    componentDidMount() {
        this.props.setPlaceBetError(null);
    }

    openCalculator = (show, fixture, market, bet) => {

        this.setState({
            isShow: show,
            selectedBet: bet,
            selectedFixture: fixture,
            selectedMarket: market,
        });
    }

    closeModal = (show) => {
        this.setState({ isShow: show });
    }

    setSelectedBetStake = (value) => {
        let { selectedFixture, selectedMarket, selectedBet } = this.state;

        if (selectedBet) {
            this.props.setBetStake(selectedFixture, selectedMarket, selectedBet, selectedBet.provider, value);
        }
    }

    placeBet = () => {
        let { fixtures, multiStake } = this.props.betslip;

        let filteredOutFixtures = Util.filterBetStakeZeroOut(fixtures);


        if (_.size(filteredOutFixtures) === 0) {
            console.log(filteredOutFixtures)
            filteredOutFixtures = fixtures;
        }

        let validation = Util.placeBetValidation(filteredOutFixtures, 1, multiStake);

        if (validation !== null) {
            this.props.setPlaceBetError(validation);
            return;
        }

        console.log(this.props)

        validation = Util.placeBetValidationLimitsSingleBet(filteredOutFixtures, this.props.betslip);

        if (validation !== null) {
            this.props.setPlaceBetError(validation);
            return;
        }



        let data = Util.convertToPlaceBetData(filteredOutFixtures, 1, multiStake);
        this.props.placeBet(data);







    }

    componentWillReceiveProps(nextProps) {
        let { fixtures } = nextProps.betslip;
        let { placeBetError } = nextProps.user;

        if (this.props.general.loading === true && nextProps.general.loading === false && placeBetError === null) {

            let prepareForRemoval = Util.prepareForRemoval(fixtures);

            this.props.setBets(prepareForRemoval);

            setTimeout(this.props.setBets, 1000, Util.filterBetStakeZeroIn(fixtures));
        }
    }

    render() {

        let { isShow, selectedBet } = this.state;
        let { fixtures, totalOdds } = this.props.betslip;
        let { placeBetError } = this.props.user;

        let index = 0;
        let totalWin = 0.00;
        let drawBets = _.map(fixtures, fixture => {
            return _.map(fixture.markets, market => {
                return _.map(market.bets, bet => {
                    index++;
                    totalWin += (parseFloat(bet.Stake) * parseFloat(bet.Price));
                    return <BetslipSingleBet fixture={fixture.fixture} market={market.market} index={index} bet={bet} openCalculator={this.openCalculator} key={bet.Id} />
                })
            })
        })

        let startValue = selectedBet ? selectedBet.Stake : "";

        let possibleWin = selectedBet ? ((parseFloat(selectedBet.Stake) * parseFloat(selectedBet.Price)).toFixed(2)) : 0.00;

        let drawCalculator = isShow ? (<Calulator startValue={startValue} possibleWin={possibleWin} onSetValue={this.setSelectedBetStake} onCloseModal={this.closeModal}></Calulator>) : (null);

        let error = placeBetError ? (
            <Row className="mx-auto fs-15">

                <Col xs="12" className="bg-dark p-1 text-center" >

                    <span className="material-icons icon mr-2" style={{
                        verticalAlign: 'middle',
                        color: 'red',
                    }}>error</span>
                    <span>{placeBetError}</span>

                </Col>
            </Row>
        ) : (null);

        let bootomContainer = _.size(fixtures) > 0 ? (
            <div className="bootom-container">
                {error}
                <Row className="mx-auto betslip-panel pt-1">
                    <Col xs="6">
                        Total odds
                        </Col>
                    <Col xs="6" className="text-right">
                        {parseFloat(totalOdds).toFixed(2)}
                    </Col>
                </Row>
                <Row className="mx-auto pb-1 betslip-panel">
                    <Col xs="6">
                        Total possible win
                        </Col>
                    <Col xs="6" className="text-right">
                        {parseFloat(totalWin).toFixed(2)}
                    </Col>
                </Row>
                <Row className="mx-auto">
                    <Col xs="12" className="bg-green" onClick={this.placeBet}>
                        <div className="fs-24 text-white tablinks place-btn">Place bet</div>
                    </Col>
                </Row>
            </div>
        ) : (null);


        return (
            <div>
                <div className="betslip-section mb-120">
                    {drawBets}
                </div>

                {bootomContainer}
                {drawCalculator}
            </div>

        )
    }
}
export default compose(
    connect(Maps.mapBetslipStateToProps, Maps.mapBetslipDispatchToProps),
    connect(Maps.mapUserStateToProps, Maps.mapUserDispatchToProps)
)(BetslipSingle);