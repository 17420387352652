import React from 'react';
import { Row, Col } from 'react-bootstrap';
import MainMarket from './Markets/MainMarket';
import Markets from './Markets/Markets';
import _ from 'lodash';

import * as Maps from "../../store/map";
import { connect } from 'react-redux';
import Moment from 'react-moment';
import 'moment-timezone';

import Util from '../../helper/Util';



class Matche extends React.Component {
    constructor(props) {
        super(props)
    }

    openResult = (value) => {
        let { activeMatchId } = this.props.lSports.prematch;
        let activeId = value === activeMatchId ? '' : value
        this.props.setActiveMatchId(activeId);
    }

    render() {


        let { event, isLive } = this.props;
        let { activeMatchId, mainMarket } = this.props.lSports.prematch;

        let currentMainMarket = _.find(mainMarket, m => m.Id === event.Fixture.Sport.Id);




        let score = isLive ? (
            <Col xs="2" lg="2" className="p-1 pt-2 mt-2 text-center active fs-15" onClick={(e) => this.openResult(event.FixtureId)}>
                0 : 0
            </Col>
        ) : (<Col className="p-2  text-center active ">
            <Moment format="DD/MM/YY">
                {event.Fixture.StartDate}
            </Moment>
            <br />
            <Moment format="HH:mm">
                {event.Fixture.StartDate}
            </Moment>

        </Col>);

        let statistics = (
            <Col xs="1" lg="1" className="p-1 pt-2 mt-2 text-center" onClick={this.ShowStats}>
                <i className="demo-icon icon-stats"></i>
            </Col>
        )

        let livestream = isLive ? (
            <Col xs="1" lg="1" className="p-1 pt-0 mt-2 text-center">
                <i className="demo-icon fs-18 icon-livestream" style={{ color: "red" }}></i>
            </Col>
        ) : (null);

        let participants = (
            <Col xs="6" lg="6" md="auto" className="pl-3 p-2 text-overflow" onClick={(e) => this.openResult(event.FixtureId)}>
                {event.Fixture.Participants[0].Name} <br /> {event.Fixture.Participants[1].Name}
            </Col>
        );

        //console.log(event.Markets);
        let sortedMarkets = Util.sortMarket(event.Markets, event.Fixture.Sport.Id);

        let mainMarketEvent = _.find(sortedMarkets, m => m.Id === currentMainMarket.selectedMarket);

        if (!mainMarketEvent) {
            mainMarketEvent = _.head(sortedMarkets);
        }

        sortedMarkets = _.filter(sortedMarkets, m => m.Id !== mainMarketEvent.Id);


        let drawMarkets = activeMatchId === event.FixtureId ? (
            <Markets markets={sortedMarkets} fixture={event} />
        ) : (null);

        let colLength = isLive ? 2 : 3;
        let matchesInfo = (
            <Col xs={colLength} lg={colLength} className=" p-1 pt-2 text-right mt-2 fs-15" onClick={(e) => this.openResult(event.FixtureId)}>
                <span className=" pr-4"  >+{_.size(sortedMarkets)}</span>
                <i className={`live-dropdown-arror fs-20 pr-2 fa ${activeMatchId === event.FixtureId ? 'fa-angle-down' : 'fa-angle-right'}`}></i>
            </Col>
        );

        if (mainMarketEvent === undefined) return null;


        return (

            <div>

                <div className="match-panel">
                    <div>
                        <Row className="mx-auto match-inner-panel">
                            <div className="width-95 mx-auto">
                                {participants}
                                {score}
                                {statistics}
                                {livestream}
                                {matchesInfo}
                            </div>
                        </Row>
                        <MainMarket market={mainMarketEvent} fixture={event} isLive={isLive} />
                    </div>
                    <div className={`result-open-panel ${activeMatchId === event.FixtureId ? 'open-result' : ''}`}>
                        {drawMarkets}
                    </div>

                </div>
            </div>
        )
    }
}
export default connect(Maps.mapLSportsStateToProps, Maps.mapLSportsDispatchToProps)(Matche);