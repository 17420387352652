

import * as Actions from "../map/actions";
import _ from 'lodash';
import { lSportsConfig } from '../../config/lsports.config'


const initialState = {
  prematch: {
    isLoaded: false,
    originEvents: [],
    filteredEvents: [],
    eventSportGroup: {},
    events: null,
    sports: null,
    locations: null,
    search: '',
    mainMarket: lSportsConfig.prematch.mainMarket,
    activeMatchId: -1,
    selectedSport: null,
    selectedLocation: null,
    selectedLeague: null,
    isLocationsActive: false,
    isPrematchActive: true,
    isLoadingStarted: false,
    desiredLoadCount: 0,
    loadedCount: 0,
    removeLeagueFromSportsCount: 0,
    isSportSearchActive: false,
  },
  inplay: {
    originEvents: [],
    filteredEvents: [],
    eventSportGroup: {},
    locations: null,
    selectedSport: null,
    search: '',
    eventGroup: [],
    selectedLocations: [],
    isLoadingStarted: false,
    desiredLoadCount: 0,
    loadedCount: 0,
    activeMatchId: -1,
    mainMarket: lSportsConfig.inplay.mainMarket,
  }



};

const filterEventsByConditions = function (events) {

  return _.filter(events, function (item) {
    return item.Fixture.Status === 1;// || item.Fixture.Status === 9 || item.Fixture.Status === 2;
  });

}

const filterInPlayEvents = function (events) {


  let newEvents = _.filter(events, function (item) {

    return (item.Fixture.Status === 9 || item.Fixture.Status === 2);
  });

  //temp limit sport count
  let count = 0;
  let fakeSportId = 1000000;

  newEvents = _.filter(newEvents, function (item) {

    if (count < 10) {
      count++;

    } else {
      fakeSportId = 6046;
    }
    return item.Fixture.Sport.Id !== fakeSportId;
  });

  return newEvents;
}

const assignLocationSort = function (locationObject, sportId) {

  let sport = _.find(lSportsConfig.sort.location, i => {

    return i.SportId === sportId
  })

  let location = null;

  if (sport) {
    location = _.find(sport.Locations, i => {
      return i.Id === locationObject.Id
    })
  }

  return location ? _.assign(locationObject, location) : _.assign(locationObject, { "Id": locationObject.Id, "Sort": locationObject.Id });
}

const assignSportSort = function (sportObject) {

  let sport = _.find(lSportsConfig.sort.sport, i => {

    return i.Id === sportObject.Id
  })

  // console.log(sport);

  return sport ? _.assign(sportObject, sport) : _.assign(sportObject, { "Id": sportObject.Id, "Sort": sportObject.Id });
}

const groupEventsBySport = function (events) {

  let group = _.groupBy(events, function (item) {
    return `${item.Fixture.Sport.Id}`;
  });

  let keys = Object.keys(group);

  group = _.zipWith(keys, group, function (item) {
    //console.log(group[item][0].Fixture.Sport)
    var obj = {};
    obj['Sport'] = group[item][0].Fixture.Sport;

    obj['FixtureCount'] = _.size(group[item]);
    obj['Locations'] = group[item];
    return _.defaults(obj);
  });

  //console.log(group);
  group = _.orderBy(group, ['Sport.Sort'], 'dec');


  _.forEach(group, (item) => {
    item.Locations = _.groupBy(item.Locations, function (item) {
      return `${item.Fixture.Location.Id}`;
    });

    //console.log(item.Locations);
    let keys = Object.keys(item.Locations);
    item.Locations = _.zipWith(keys, item.Locations, function (sub_item, value) {

      var obj = {};
      obj['Location'] = item.Locations[sub_item][0].Fixture.Location;
      obj['FixtureCount'] = _.size(item.Locations[sub_item]);
      obj['Leagues'] = item.Locations[sub_item];
      return _.defaults(obj);
    });

    item.Locations = _.orderBy(item.Locations, ['Location.Sort'], 'dec');


    //console.log(item.Locations);
    _.forEach(item.Locations, (location) => {
      location.Leagues = _.groupBy(location.Leagues, function (item) {
        return `${item.Fixture.League.Id}`;
      });


      let keys = Object.keys(location.Leagues);
      location.Leagues = _.zipWith(keys, location.Leagues, function (sub_sub_item, value) {
        var obj = {};
        obj['League'] = location.Leagues[sub_sub_item][0].Fixture.League;
        obj['FixtureCount'] = _.size(location.Leagues[sub_sub_item]);
        obj['Fixtures'] = _.orderBy(location.Leagues[sub_sub_item], ['Fixture.StartDate'], 'dec');
        return _.defaults(obj);
      });


    });


  });

  return group;
}


const searchEvents = function (state, value) {


  let { selectedSport, selectedLocation, selectedLeague, filteredEvents, eventSportGroup } = state.prematch;

  let events = null;

  if (value !== null && value !== '') {

    events = _.filter(filteredEvents, function (item) {

      return _.some(item.Fixture.Participants, function (tag) {
        return _.includes(tag.Name.toLowerCase(), value.toLowerCase()); //startsWith
      });

    });



    if (events.length === 0) {
      return null;
    } else {
      events = _.take(events, 50);
    }

  }

  //select
  if (events === null) {

    events = _.filter(filteredEvents, function (item) {
      return _.some(item.Fixture, function (tag) {

        return item.Fixture.Sport.Id === selectedSport && item.Fixture.Location.Id === selectedLocation && item.Fixture.League.Id === selectedLeague;
      });
    });
  }


  // if (events === null || events.length === 0) {

  //   events = _.head(eventSportGroup);
  //   if (events) {
  //     events = _.head(events.Locations);
  //     events = _.head(events.Leagues);
  //     events = events.Fixtures;
  //   } else {
  //     return null;
  //   }

  // }

  return groupEventsBySport(events);
}

const filterLocations = function (events, sportId) {

  let filteredEvents = null;
  let sport = _.find(events, s => {
    return s.Sport.Id === sportId
  });

  if (sport) {
    filteredEvents = sport.Locations;
  }

  return filteredEvents;
}

const getEventsBySelection = function (events, state) {


  let { sportId, locationId, leagueId } = state.prematch;

  let filteredEvents = null;

  let data = _.filter(events, e => {
    return e.Sport.Id === 6046;
  });



  console.log(data);

  // let sport = _.find(events, s => s.Sport.Id === sportId);

  // if (sport) {

  //   let location = _.find(sport.Locations, loc => {
  //     return loc.Location.Id === locationId
  //   });

  //   if (location) {
  //     let league = _.find(location.Leagues, leg => leg.League.Id === leagueId);

  //     if (league) filteredEvents = league.Fixtures;
  //   }
  // }

  return filteredEvents;
}

const selectEvents = function (events, state) {

  let filteredEvents = getEventsBySelection(events, state)
  if (filteredEvents !== null) return filteredEvents;


  return filteredEvents;
}

//InPlay
const inplaySearchEventsByName = function (filteredEvents, value) {

  let events = filteredEvents;

  if (value !== null && value !== '') {

    events = _.filter(filteredEvents, function (item) {

      return _.some(item.Fixture.Participants, function (tag) {
        return _.includes(tag.Name.toLowerCase(), value.toLowerCase()); //startsWith
      });

    });

    if (events.length === 0) {
      return null;
    } else {
      events = _.take(events, 50);
    }

  }

  return events;
}

const inplaySearchEvents = function (selectedSport, selectedLocations, filteredEvents, value) {


  let events = null;

  if (value !== null && value !== '') {

    events = _.filter(filteredEvents, function (item) {

      return _.some(item.Fixture.Participants, function (tag) {
        return _.includes(tag.Name.toLowerCase(), value.toLowerCase()); //startsWith
      });

    });



    if (events.length === 0) {
      return null;
    } else {
      events = _.take(events, 50);
    }

  }

  //select
  if (events === null) {
    let isSelectedLocationsEmpty = _.size(selectedLocations) === 0;
    events = _.filter(filteredEvents, function (item) {
      return _.some(item.Fixture, function (tag) {

        return item.Fixture.Sport.Id === selectedSport && (_.includes(selectedLocations, item.Fixture.Location.Id.toString()) || isSelectedLocationsEmpty);
      });
    });
  }

  return groupEventsBySport(events);
}

const inplayFilterBySport = function (filteredEvents, sportId) {

  filteredEvents = _.filter(filteredEvents, s => {
    return sportId ? s.Fixture.Sport.Id === sportId : true;
  });

  return filteredEvents;
}

const inplayFilterByLocations = function (filteredEvents, locations) {

  let count = _.size(locations);

  filteredEvents = _.filter(filteredEvents, s => {
    let contains = _.includes(locations, s.Fixture.Location.Id.toString());
    return count > 0 ? contains : true;
  });

  return filteredEvents;
}

const inplayFilterSelectedLocationsByLocations = function (selectedLocations, locations) {

  selectedLocations = _.filter(selectedLocations, s => {

    return _.find(locations, l => {
      return l.Id.toString() === s.toString()
    });
  });

  return selectedLocations;
}

const inplayFilterLocations = function (filteredEvents) {

  let locations = _.map(filteredEvents, "Fixture.Location");
  locations = _.uniqBy(locations, 'Id');

  return locations;
}

const lSportsReducer = function (state = initialState, action) {

  switch (action.type) {

    case Actions.ON_MAIN_MARKET_SELECTED: {

      let mainMarket = state.prematch.mainMarket;

      mainMarket = _.forEach(mainMarket, m => {
        if (m.Id === action.sportId) {
          m.selectedMarket = action.marketId;
        }
      })

      return {
        ...state,
        prematch: {
          ...state.prematch,
          mainMarket: mainMarket,
        }
      };
    }

    case Actions.ON_SPORT_SELECTED: {

      let locations = filterLocations(state.prematch.eventSportGroup, action.sportId);

      return {
        ...state,
        prematch: {
          ...state.prematch,
          selectedSport: action.sportId,
          locations: locations,
        }
      };
    }

    case Actions.ON_LOCATION_SELECTED: {


      return {
        ...state,
        prematch: {
          ...state.prematch,
          selectedLocation: action.locationId,
        }
      };
    }

    case Actions.SET_LOCATIONS_ACTIVE: {

      return {
        ...state,
        prematch: {
          ...state.prematch,
          isLocationsActive: action.value,
        }
      };
    }

    case Actions.SET_PREMATCH_ACTIVE: {

      return {
        ...state,
        prematch: {
          ...state.prematch,
          isPrematchActive: action.value,
        }
      };
    }

    case Actions.ON_LEAGUE_SELECTED: {

      let newState = {
        ...state,
        prematch: {
          ...state.prematch,
          selectedLeague: action.leagueId,
          search: null,
        }
      };

      let eventGroup = action.leagueId ? searchEvents(newState, null) : null;

      return {
        ...newState,
        prematch: {
          ...newState.prematch,
          eventGroup: eventGroup,
        }
      };
    }

    case Actions.SET_ACTIVE_MATCH_ID: {

      return {
        ...state,
        prematch: {
          ...state.prematch,
          activeMatchId: action.matchId,
        }
      };
    }

    case Actions.SET_SPORT_SEARCH_ACTIVE: {

      return {
        ...state,
        prematch: {
          ...state.prematch,
          isSportSearchActive: action.value,
        }
      };
    }

    case Actions.ON_SEARCH: {

      let eventGroup = searchEvents(state, action.search);
      return {
        ...state,
        prematch: {
          ...state.prematch,
          search: action.search,
          eventGroup: eventGroup,
        }
      };
    }

    case Actions.ON_CLEAR_SEARCH: {
      return {
        ...state,
        prematch: {
          ...state.prematch,
          search: '',
          eventGroup: [],
        }
      };
    }

    case Actions.START_LOADING: {

      return {
        ...state,
        prematch: {
          ...state.prematch,
          isLoadingStarted: false,
          desiredLoadCount: action.desiredLoadCount,
          loadedCount: 0,
        }
      };
    }

    case Actions.ON_REMOVE_LEAGUE: {

      let eventGroup = state.prematch.eventGroup;

      _.forEach(eventGroup, e => {

        if (e.Sport.Id === action.sportId) {

          _.forEach(e.Locations, location => {

            if (location.Location.Id === action.locationId) {

              location.Leagues = _.filter(location.Leagues, league => {

                return league.League.Id !== action.leagueId;
              });

              e.Locations = _.filter(e.Locations, location => {
                return location.Leagues.length !== 0;
              });
            }
          })
          eventGroup = _.filter(eventGroup, g => {
            return g.Locations.length !== 0;
          });
        }
      })

      let removeLeagueFromSportsCount = state.prematch.isSportSearchActive ? state.prematch.removeLeagueFromSportsCount : state.prematch.removeLeagueFromSportsCount + 1;


      return {
        ...state,
        prematch: {
          ...state.prematch,
          eventGroup: eventGroup,
          removeLeagueFromSportsCount: removeLeagueFromSportsCount,
        }
      };
    }


    case Actions.SET_EVENTS: {

      //assign additional information
      _.forEach(action.events, e => {
        e.Fixture.Sport = assignSportSort(e.Fixture.Sport);
        e.Fixture.Location = assignLocationSort(e.Fixture.Location, e.Fixture.Sport.Id);
      })

      let newEvents = _.uniqBy([...state.prematch.originEvents, ...action.events], 'FixtureId');
      let filteredEvents = filterEventsByConditions(newEvents);
      let eventSportGroup = groupEventsBySport(filteredEvents);

      let locations = state.prematch.locations;
      if (state.prematch.selectedSport) {
        locations = filterLocations(eventSportGroup, state.prematch.selectedSport);
      }



      return {
        ...state,
        prematch: {
          ...state.prematch,
          originEvents: newEvents,
          filteredEvents: filteredEvents,
          eventSportGroup: eventSportGroup,
          isLoadingStarted: true,
          loadedCount: (state.prematch.loadedCount + 1),
          locations: locations,
        }
      };
    }

    case Actions.UPDATE_EVENTS: {

      _.forEach(action.events, event => {
        let foundEvent = _.find(state.prematch.originEvents, { FixtureId: event.FixtureId });

        if (foundEvent) {

          if (event.Markets) {

            _.forEach(event.Markets, market => {

              let foundMarket = _.find(foundEvent.Markets, { Id: market.Id });

              if (foundMarket) {

                _.forEach(market.Providers, provider => {
                  let foundProvider = _.find(foundMarket.Providers, { Id: provider.Id });

                  if (foundProvider) {
                    _.forEach(provider.Bets, bet => {

                      _.forEach(foundProvider.Bets, (value, key) => {
                        if (bet.Id === value.Id) {
                          foundProvider.Bets[key] = _.merge({}, value, bet);
                          //console.log(foundProvider.Bets[key], value, bet);
                        }

                      })
                      //let foundBet = _.find(foundProvider.Bets, { Id: bet.Id });


                      // if (foundBet) {
                      //   console.log(foundBet, bet)
                      // }
                    })

                  }
                })
              }

            })
          }

          if (event.Fixture) {
            //console.log('Fixture', event);
          }
          if (event.Livescore) {
            //console.log('Livescore', event);
          }

        } else {//not found
          //if (event.Fixture && event.Markets) {
          //console.log('Not', event);
          //}
        }
      })

      //let list = deepmerge(state.prematch.filteredEvents, action.events);

      return state;

      // _.forEach(action.events, event => {
      //   let foundEvent = _.find(state.prematch.filteredEvents, { FixtureId: event.FixtureId });
      //   //console.log(state.prematch.filteredEvents)
      //   if (foundEvent) {

      //     _.forEach(event.Markets, market => {

      //       let foundMarket = _.find(foundEvent.Markets, { Id: market.Id });
      //       if (foundMarket) {

      //         _.forEach(market.Providers, provider => {

      //           let foundProvider = _.find(foundMarket.Providers, { Id: provider.Id });

      //           const bets = _(foundProvider.Bets) // start sequence
      //             .keyBy('Id') // create a dictionary of the 1st array
      //             .merge(_.keyBy(provider.Bets, 'Id')) // create a dictionary of the 2nd array, and merge it to the 1st
      //             .values() // turn the combined dictionary to array
      //             .value();

      //           foundProvider.Bets = bets;
      //           console.log(bets);
      //           console.log(foundProvider.Bets);

      //           // if (foundProvider) {
      //           //   _.forEach(provider.Bets, bet => {
      //           //     let foundBet = _.find(foundProvider.Bets, { Id: bet.Id });
      //           //     console.log(foundBet);

      //           //   })
      //           // }

      //         })

      //       }
      //     })

      //     //foundEvent.Markets = _.merge(foundEvent.Markets, event.Markets);
      //     //console.log("b", foundEvent, event)
      //     // _.assign(foundEvent, event);
      //     //console.log("A", foundEvent)
      //   }

      // })

      // const newfilteredEvents = _(state.prematch.filteredEvents) // start sequence
      //   .keyBy('FixtureId') // create a dictionary of the 1st array
      //   .merge(_.keyBy(action.events, 'FixtureId')) // create a dictionary of the 2nd array, and merge it to the 1st
      //   .values() // turn the combined dictionary to array
      //   .value();

      //let newfilteredEvents = _.merge(state.prematch.filteredEvents, action.events);

      // return {
      //   ...state,
      //   prematch: {
      //     ...state.prematch,
      //     filteredEvents: list
      //   }
      // };
    }









    //InPlay
    case Actions.INPLAY_SET_EVENTS: {


      //assign additional information
      _.forEach(action.events, e => {

        e.Fixture.Sport = assignSportSort(e.Fixture.Sport);
        e.Fixture.Location = assignLocationSort(e.Fixture.Location, e.Fixture.Sport.Id);
      })

      let newEvents = _.uniqBy([...state.inplay.originEvents, ...action.events], 'FixtureId');
      let filteredEvents = filterInPlayEvents(newEvents);


      let eventSportGroup = groupEventsBySport(filteredEvents);

      let searchEvents = inplaySearchEventsByName(filteredEvents, state.inplay.search);

      let filterBySport = inplayFilterBySport(searchEvents, state.inplay.selectedSport)

      let locations = inplayFilterLocations(filterBySport);
      let selectedLocationsFiltered = inplayFilterSelectedLocationsByLocations(state.inplay.selectedLocations, locations)
      let filterByLocations = inplayFilterByLocations(filterBySport, selectedLocationsFiltered);
      let eventGroup = groupEventsBySport(filterByLocations);

      return {
        ...state,
        inplay: {
          ...state.inplay,
          originEvents: newEvents,
          filteredEvents: filteredEvents,
          eventSportGroup: eventSportGroup,
          isLoadingStarted: true,
          loadedCount: (state.inplay.loadedCount + 1),
          locations: locations,
          eventGroup: eventGroup,
        }
      };
    }

    case Actions.ON_INPLAY_SPORT_SELECTED: {


      let { search, filteredEvents } = state.inplay;
      let searchEvents = inplaySearchEventsByName(filteredEvents, search);
      let filterBySport = inplayFilterBySport(searchEvents, action.sportId);

      let locations = inplayFilterLocations(filterBySport);
      let eventGroup = groupEventsBySport(filterBySport);

      return {
        ...state,
        inplay: {
          ...state.inplay,
          selectedSport: action.sportId,
          locations: locations,
          eventGroup: eventGroup,
          selectedLocations: [],
        }
      };

    }

    case Actions.ON_INPLAY_SEARCH: {

      let { selectedSport, filteredEvents } = state.inplay;
      let searchEvents = inplaySearchEventsByName(filteredEvents, action.search);
      let filterBySport = inplayFilterBySport(searchEvents, selectedSport)

      let locations = inplayFilterLocations(filterBySport);

      let selectedLocationsFiltered = inplayFilterSelectedLocationsByLocations(state.inplay.selectedLocations, locations)
      let filterByLocations = inplayFilterByLocations(filterBySport, selectedLocationsFiltered);
      let eventGroup = groupEventsBySport(filterByLocations);

      return {
        ...state,
        inplay: {
          ...state.inplay,
          search: action.search,
          locations: locations,
          eventGroup: eventGroup,
        }
      };
    }

    case Actions.ON_INPLAY_LOCATION_SELECTED: {

      let selectedLocations = state.inplay.selectedLocations;

      let contains = _.includes(selectedLocations, action.locationId.toString());

      if (contains) {
        selectedLocations = _.filter(selectedLocations, l => {
          return action.locationId !== l;
        })
      } else {

        selectedLocations = [...selectedLocations, action.locationId]
      }


      let searchEvents = inplaySearchEventsByName(state.inplay.filteredEvents, state.inplay.search);

      let filterBySport = inplayFilterBySport(searchEvents, state.inplay.selectedSport)

      let locations = inplayFilterLocations(filterBySport);

      let selectedLocationsFiltered = inplayFilterSelectedLocationsByLocations(selectedLocations, locations)
      let filterByLocations = inplayFilterByLocations(filterBySport, selectedLocationsFiltered);

      let eventGroup = groupEventsBySport(filterByLocations);


      return {
        ...state,
        inplay: {
          ...state.inplay,
          selectedLocations: selectedLocations,
          locations: locations,
          eventGroup: eventGroup,

        }
      };

    }

    case Actions.ON_INPLAY_RESET_SELECTED_LOCATIONS: {

      let searchEvents = inplaySearchEventsByName(state.inplay.filteredEvents, state.inplay.search);

      let filterBySport = inplayFilterBySport(searchEvents, state.inplay.selectedSport)
      let locations = inplayFilterLocations(filterBySport);
      let eventGroup = groupEventsBySport(filterBySport);

      return {
        ...state,
        inplay: {
          ...state.inplay,
          selectedLocations: [],
          locations: locations,
          eventGroup: eventGroup,
        }
      };

    }

    case Actions.INPLAY_SET_ACTIVE_MATCH_ID: {

      return {
        ...state,
        inplay: {
          ...state.inplay,
          activeMatchId: action.matchId,
        }
      };
    }

    case Actions.ON_INPLAY_MAIN_MARKET_SELECTED: {

      let mainMarket = state.inplay.mainMarket;

      mainMarket = _.forEach(mainMarket, m => {
        if (m.Id === action.sportId) {
          m.selectedMarket = action.marketId;
        }
      })

      return {
        ...state,
        inplay: {
          ...state.inplay,
          mainMarket: mainMarket,
        }
      };
    }

    case Actions.ON_INPLAY_REMOVE_LEAGUE: {

      let eventGroup = state.inplay.eventGroup;

      _.forEach(eventGroup, e => {

        if (e.Sport.Id === action.sportId) {

          _.forEach(e.Locations, location => {

            if (location.Location.Id === action.locationId) {

              location.Leagues = _.filter(location.Leagues, league => {

                return league.League.Id !== action.leagueId;
              });

              e.Locations = _.filter(e.Locations, location => {
                return location.Leagues.length !== 0;
              });
            }
          })
          eventGroup = _.filter(eventGroup, g => {
            return g.Locations.length !== 0;
          });
        }
      })

      //let removeLeagueFromSportsCount = state.prematch.isSportSearchActive ? state.prematch.removeLeagueFromSportsCount : state.prematch.removeLeagueFromSportsCount + 1;


      return {
        ...state,
        inplay: {
          ...state.inplay,
          eventGroup: eventGroup,
          //removeLeagueFromSportsCount: removeLeagueFromSportsCount,
        }
      };
    }


    default:
      return state;
  }
};


export default lSportsReducer;


