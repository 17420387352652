import React from 'react';
// import SportsList from '../Sports/SportsList';
import Live from './Live';
class LiveIndex extends React.Component {
    render() {
        return (
            <div className="main-container">
                {/* <SportsList/> */}
                <Live />
            </div>
        )
    }
}
export default LiveIndex;