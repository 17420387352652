import React from 'react';
import { Container, Row, Col, Button } from 'react-bootstrap';

import Betslip from './Betslip'

import * as Maps from "../../store/map";
import { connect } from 'react-redux';
import { compose } from 'redux';
import _ from 'lodash';

class MyBets extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            isActive: 'All',
            show: false,
            content: []
        }
    }
    setActiveTab = (tab) => {
        this.setState({
            isActive: tab
        })
    }
    closeModal = () => {
        this.setState({
            show: false
        })
    }
    cashOut = () => {
        let dataArr = [];
        let data = <div className="fs-13">
            <Row className="mx-auto pl-2 pr-2 fs-13">
                <Col xs={12} md={12} className="pr-2 pt-2 text-right">
                    <i className="material-icons" onClick={this.closeModal}>close</i>
                </Col>
            </Row>
            <Row className="mx-auto text-gray pl-2 pr-2 fs-13">
                <Col xs={3} md={3} className="pt-0 pl-2 pb-2 pr-2">17:34:23</Col>
                <Col xs={3} md={3} className="pt-0 pl-2 pb-2 pr-2">25/03/19</Col>
                <Col xs={6} md={6} className="pt-0 pl-2 pb-2 pr-2 text-right">Total odd: 145</Col>
            </Row>
            <div className="cashout-divider"></div>
            <div className="pb-1 fs-13 p-0 pl-2 pr-2 bl-red-8">
                <Row className="m-0 p-0 pr-2">
                    <Col xs={6} className="p-0 pt-1 font-600">Championsleague CUP</Col>
                    <Col xs={3} className="p-0 pt-1 text-center"></Col>
                    <Col xs={3} className="p-0 pt-1 text-right live-btn-bold">Live</Col>
                </Row>
                <Row className="m-0 p-0 pr-2">
                    <Col xs={6} className="p-0 pt-1">Real madrid-Barcelona</Col>
                    <Col xs={3} className="p-0 pt-1 text-center text-dark-green fs-15">0 : 0</Col>
                    <Col xs={3} className="p-0 pt-1 text-right">Odd: 2.45</Col>
                </Row>
                <Row className="m-0 p-0 pr-2">
                    <Col xs={3} className="p-0 pt-1">20/11/19</Col>
                    <Col xs={3} className="p-0 pt-1 pl-2">23:00</Col>
                    <Col xs={3} className="p-0 pt-1 text-center text-dark-green"></Col>
                    <Col xs={3} className="p-0 pt-1 text-right">Combination</Col>
                </Row>
                <Row className="m-0 p-0 pr-2">
                    <Col xs={2} className="p-0 pt-1 pick-btn-bold">Pick 1</Col>
                    <Col xs={5} className="p-0 pt-1 pl-2">Rest of the match</Col>
                    <Col xs={2} className="p-0 pt-1 text-center text-dark-green"></Col>
                    <Col xs={3} className="p-0 pt-1 text-right"></Col>
                </Row>
            </div>
            <div className="cashout-divider"></div>
            <div className="pb-1 fs-13 p-0 pl-2 pr-2 bl-green-8">
                <Row className="m-0 p-0 pr-2">
                    <Col xs={6} className="p-0 pt-1 font-600">Championsleague CUP</Col>
                    <Col xs={3} className="p-0 pt-1 text-center"></Col>
                    <Col xs={3} className="p-0 pt-1 text-right live-btn-bold">Live</Col>
                </Row>
                <Row className="m-0 p-0 pr-2">
                    <Col xs={6} className="p-0 pt-1">Real madrid-Barcelona</Col>
                    <Col xs={3} className="p-0 pt-1 text-center text-dark-green fs-15">0 : 1</Col>
                    <Col xs={3} className="p-0 pt-1 text-right">Odd: 2.45</Col>
                </Row>
                <Row className="m-0 p-0 pr-2">
                    <Col xs={3} className="p-0 pt-1">20/11/19</Col>
                    <Col xs={3} className="p-0 pt-1 pl-2">23:00</Col>
                    <Col xs={3} className="p-0 pt-1 text-center text-dark-green"></Col>
                    <Col xs={3} className="p-0 pt-1 text-right">Combination</Col>
                </Row>
                <Row className="m-0 p-0 pr-2">
                    <Col xs={2} className="p-0 pt-1 pick-btn-bold">Pick 2</Col>
                    <Col xs={5} className="p-0 pt-1 pl-2">Rest of the match</Col>
                    <Col xs={2} className="p-0 pt-1 text-center text-dark-green"></Col>
                    <Col xs={3} className="p-0 pt-1 text-right"></Col>
                </Row>
            </div>
            <div className="cashout-divider"></div>
            <div className="mh-50"></div>
        </div>
        dataArr.push(data)
        this.setState({ show: true, content: dataArr })
    }
    closeModal = () => {
        this.setState({ show: false })
    }
    render() {
        let { isActive, show, content } = this.state;

        let { betslips } = this.props.user;

        let drawBetslips = betslips ? _.map(betslips, betslip => {
            return <Betslip betslip={betslip} key={betslip.id} />
        }) : (null);

        return (
            <div className="main-container">
                <Row md="auto" className="mx-auto text-center text-white bg-gray-head fs-14 mybet-result">
                    {/* <Col xs="2" md="2" lg="2" className={`pt-3 pb-3 ${isActive === "All" ? 'active':''}`} onClick={(e) => this.setActiveTab('All')}>All</Col>
                    <Col xs="3" md="3" lg="3" className={`pt-3 pb-3 p-0 ${isActive === "in-game" ? 'active':''}`} onClick={(e) => this.setActiveTab('in-game')}>In Game</Col>
                    <Col xs="3" md="3" lg="3" className={`pt-3 pb-3 ${isActive === "won" ? 'active':''}`} onClick={(e) => this.setActiveTab('won')}>Won</Col>
                    <Col xs="2" md="2" lg="2" className={`pt-3 pb-3 p-0 ${isActive === "lost" ? 'active':''}`} onClick={(e) => this.setActiveTab('lost')}>Lost</Col> */}

                    <Col xs="3" md="3" lg="3" className={`pt-3 pb-3 ${isActive === "All" ? 'active' : ''}`} onClick={(e) => this.setActiveTab('All')}>All</Col>
                    <Col xs="3" md="3" lg="3" className={`pt-3 pb-3 p-0 ${isActive === "in-game" ? 'active' : ''}`} onClick={(e) => this.setActiveTab('in-game')}>In Game</Col>
                    <Col xs="3" md="3" lg="3" className={`pt-3 pb-3 ${isActive === "won" ? 'active' : ''}`} onClick={(e) => this.setActiveTab('won')}>Won</Col>
                    <Col xs="3" md="3" lg="3" className={`pt-3 pb-3 p-0 ${isActive === "lost" ? 'active' : ''}`} onClick={(e) => this.setActiveTab('lost')}>Lost</Col>
                </Row>
                <Row md="auto" className="mx-auto text-center pt-1 pb-1 fs-12 bg-light-head">
                    <Col xs="2" md="2" lg="2" className="pt-2 pb-2">Date</Col>
                    <Col xs="3" md="3" lg="3" className="pt-2 pb-2 p-0">Amount</Col>
                    <Col xs="3" md="3" lg="3" className="pt-2 pb-2 p-0">Possible win</Col>
                    <Col className="pt-2 pb-2 p-0 text-left">Win amount</Col>
                </Row>

                {drawBetslips}

                {/* <Row md="auto" className="mx-auto text-center bl-red bb-1-light fs-12 text-gray">
                    <Col xs="2" md="2" lg="2" className="pt-1 pb-1 p-0"><div>10/20/2019</div><div>13:30</div></Col>
                    <Col xs="3" md="3" lg="3" className="lh-50 p-0">10.00 EUR</Col>
                    <Col xs="3" md="3" lg="3" className="lh-50 p-0">130.00 EUR</Col>
                    <Col xs="2" md="2" lg="2" className="lh-50 p-0 text-left">0.00 EUR</Col>
                </Row>
                <Row md="auto" className="mx-auto text-center bl-gray fs-12 bb-1-light text-gray">
                    <Col xs="2" md="2" lg="2" className="pt-1 pb-1 p-0"><div>10/20/2019</div><div>13:30</div></Col>
                    <Col xs="3" md="3" lg="3" className="lh-50 p-0">10.00 EUR</Col>
                    <Col xs="3" md="3" lg="3" className="lh-50 p-0">130.00 EUR</Col>
                    <Col xs="2" md="2" lg="2" className="lh-50 p-0 text-left">0.00 EUR</Col>
                    <Col xs="2" md="2" lg="2" className="pt-2 lh-17p fs-13 pb-1 p-0 bg-blue text-white" onClick={this.cashOut}><div>Cash</div><div>Out</div></Col>
                </Row>
                <Row md="auto" className="mx-auto text-center bl-green fs-12 bb-1-light text-gray">
                    <Col xs="2" md="2" lg="2" className="pt-1 pb-1 p-0"><div>10/20/2019</div><div>13:30</div></Col>
                    <Col xs="3" md="3" lg="3" className="lh-50 p-0">10.00 EUR</Col>
                    <Col xs="3" md="3" lg="3" className="lh-50 p-0">130.00 EUR</Col>
                    <Col xs="2" md="2" lg="2" className="lh-50 p-0 text-left">0.00 EUR</Col>
                </Row> */}


            </div>
        )
    }
}
export default compose(
    connect(Maps.mapUserStateToProps, Maps.mapUserDispatchToProps)
)(MyBets);