import React from 'react';
import { Row, Col } from 'react-bootstrap';
import _ from 'lodash';

import * as Maps from "../../store/map";
import { connect } from 'react-redux';




class MultiMiniCalculator extends React.Component {
    constructor(props) {
        super(props)
    }

    handleSetMultiStake = (value) => {
        this.props.setMultiStake(value);
    }

    render() {

        let { openCalculator } = this.props;
        let { multiStake } = this.props.betslip;
        return (
            <Row className="p-0 pb-1 pt-1 betslip-panel mx-auto">
                <Col xs="8" className="mx-auto display-flex pr-0 pl-0">
                    <Col xs="2" className="cal-btn bg-green m-l" onClick={(e) => { e.preventDefault(); this.handleSetMultiStake(5.00) }}>
                        <div id="1">5</div>
                    </Col>
                    <Col xs="2" className="cal-btn bg-green m-l-r" onClick={(e) => { e.preventDefault(); this.handleSetMultiStake(10.00) }}>
                        <div>10</div>
                    </Col>
                    <Col xs="2" className="cal-btn bg-green" onClick={(e) => { e.preventDefault(); this.handleSetMultiStake(20.00) }}>
                        <div>20</div>
                    </Col>
                    <Col xs="2" className="cal-btn bg-green m-l-r" onClick={(e) => { e.preventDefault(); this.handleSetMultiStake(50.00) }}>
                        <div>50</div>
                    </Col>
                    <Col xs="2" className="cal-btn bg-green" onClick={(e) => { e.preventDefault(); this.handleSetMultiStake(100.00) }}>
                        <div>100</div>
                    </Col>
                </Col>
                <Col xs="4" className="m-l mx-auto display-flex pl-0">
                    <Col xs="8" className="btn-cal-white m-r-6 pr-1 pl-1">
                        <div onClick={openCalculator}>{parseFloat(multiStake).toFixed(2)}</div>
                    </Col>
                    <Col xs="4" className="p-0 pt-2">
                        <div>EUR</div>
                    </Col>
                </Col>
            </Row>
        )
    }
}
export default connect(Maps.mapBetslipStateToProps, Maps.mapBetslipDispatchToProps)(MultiMiniCalculator);