import React, { useEffect } from 'react';
import SportsList from './SportsList';
import Locations from './Locations';
import Prematch from '../Prematch';

import * as Maps from "../../../store/map";
import { connect } from 'react-redux';

import _ from 'lodash';

class Index extends React.Component {

    constructor(props) {
        super(props);

    }

    render() {

        let { isLocationsActive, isPrematchActive } = this.props.lSports.prematch;

        let drawLocations = isLocationsActive ? (<Locations />) : (null);
        let drawPrematch = isPrematchActive ? (<Prematch />) : (null);

        return (
            <div className="main-container">
                <SportsList />

                {drawLocations}
                {drawPrematch}
            </div>
        )
    }
}
export default connect(Maps.mapLSportsStateToProps, Maps.mapLSportsDispatchToProps)(Index);