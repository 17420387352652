import React from 'react';
import { Link } from "react-router-dom";
import * as Maps from "../../../store/map";
import { connect } from 'react-redux';
import _ from 'lodash';
import { lSportsConfig } from '../../../config/lsports.config'

class SportsList extends React.Component {
    constructor(props) {
        super(props)
    }

    selectSport = (sportId) => {

        this.props.inplaySelectSport(sportId);
    }

    componentDidMount() {
        this.props.inplaySelectSport(lSportsConfig.prematch.selectedSport);
    }

    render() {

        let { selectedSport, eventSportGroup } = this.props.lSports.inplay;

        let sportLength = _.size(eventSportGroup);
        const sportList = (
            _.map(eventSportGroup, (item, i) => {

                let sportId = item.Sport.Id;
                let sportName = item.Sport.Name;
                let count = item.FixtureCount;
                let icon = item.Sport.Icon;

                let paddingRight = i + 1 === sportLength ? 'pr-2' : '';

                return (
                    <div className={"sports-box-col " + paddingRight} onClick={(e) => this.selectSport(sportId)} key={sportId}>
                        <div className={`sports-box pt-3 ${selectedSport === sportId ? 'active' : ''}`}>
                            <div className="counter"> {count} </div>
                            <i className={"demo-icon fs-26 " + icon + " lh-1p"}></i>
                            <div>{sportName}</div>
                        </div>
                    </div>
                )
            })
        )

        return (
            <div className="sport-header pt-2 disable-select">
                <div className="sports-box-row">

                    <Link className="sports-box-col" to="/inplay/search">
                        <div className="sports-box pt-3">
                            <i className="demo-icon fs-22 mt-2 icon-search lh-1p"></i>
                            <div className="pt-1">Search</div>
                        </div>
                    </Link>

                    {sportList}

                </div>
            </div>
        )
    }
}
export default connect(Maps.mapLSportsStateToProps, Maps.mapLSportsDispatchToProps)(SportsList);