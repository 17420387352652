

import * as Actions from "../map/actions";

const initialState = {
  data: null,
  loginError: null,
  placeBetError: null,
  betslips: [],
};

const userReducer = function (state = initialState, action) {

  switch (action.type) {

    case Actions.LOGIN_SUCCESS: {
      return {
        ...state,
        loginError: null,
      };
    }

    case Actions.LOGIN_ERROR: {
      return {
        ...state,
        loginError: action.loginError,
      };
    }

    case Actions.SET_PLACE_BET_ERROR: {
      return {
        ...state,
        placeBetError: action.error,
      };
    }

    case Actions.SET_PLACE_BET_SUCCESS: {
      return {
        ...state,
        placeBetError: null,
      };
    }

    case Actions.SET_USER: {
      return {
        ...state,
        data: action.user,
      };
    }
    case Actions.LOGOUT_USER: {
      return {
        ...state,
        data: initialState.data,
      };
    }

    case Actions.SET_BETSLIPS: {
      return {
        ...state,
        betslips: action.betslips,
      };
    }

    default:
      return state;
  }
};


export default userReducer;


