import React, { useEffect } from 'react';
import SportsList from './SportsList';

import InPlay from '../InPlay';

import * as Maps from "../../../store/map";
import { connect } from 'react-redux';

import _ from 'lodash';

class Index extends React.Component {

    constructor(props) {
        super(props);

    }

    render() {

        return (
            <div className="main-container">
                <SportsList />

                <InPlay />
            </div>
        )
    }
}
export default connect(Maps.mapLSportsStateToProps, Maps.mapLSportsDispatchToProps)(Index);