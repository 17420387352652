import React, { Component } from 'react';
import { Row, Col } from 'react-bootstrap';

class KeyPadComponent extends Component {

    render() {
        let { result, onClickCalAction, possibleWin } = this.props;
        return (
            <div className="container">
                <div className="justify-content-md-center">
                    <Row className="p-1 fs-15">
                        <Col xs="6" className="pl-1">
                            Possible win
                            </Col>
                        <Col xs="6" className="pr-1 text-right font-500">
                            {parseFloat(possibleWin).toFixed(2)} <span className="text-white">EUR</span>
                        </Col>
                    </Row>
                    <Row className="fs-20 p-2 back-color mb-2 amount-row">
                        <Col xs="6" className="pl-1">
                            Amount
                        </Col>
                        <Col xs="6" className="pr-1 text-right font-500">
                            {result ? result : 0}.00 <span className="gray">EUR</span>
                        </Col>
                    </Row>
                    <Row className="fs-20 p-0 pb-2 pt-1 text-white text-center">
                        <Col className="pedding-bet-btn bg-green mr-1 br-5" id="10" name="10" onClick={onClickCalAction}>10</Col>
                        <Col className="pedding-bet-btn bg-green mr-1 br-5" id="20" name="20" onClick={onClickCalAction}>20</Col>
                        <Col className="pedding-bet-btn bg-green mr-1 br-5" id="50" name="50" onClick={onClickCalAction}>50</Col>
                        <Col className="pedding-bet-btn bg-green mr-1 br-5" id="100" name="100" onClick={onClickCalAction}>100</Col>
                        <Col className="pedding-bet-btn bg-green br-5" id="200" name="200" onClick={onClickCalAction}>200</Col>
                        {/* <div class="tab"> */}
                        {/* <Col xs="10" className="display-flex pl-0">
                        <Col xs="3" className="btn-cal bg-green">
                            <div id="1" class="pt-2 pb-2 pl-1 text-green">10</div>
                        </Col>
                        <Col xs="3" className="btn-cal l-r-3 bg-green">
                            <div class="pt-2 pb-2 pl-1 text-green">20</div>
                        </Col>
                        <Col xs="3" className="btn-cal bg-green">
                            <div class="pt-2 pb-2 pl-1 text-green">50</div>
                        </Col>
                        <Col xs="3" className="btn-cal l-r-3 bg-green">
                        <div class="pt-2 pb-2 text-green">100</div>
                        </Col>
                        </Col>
                        <Col xs="2" className="btn-cal bg-green">
                        <div class="pt-2 pb-2 text-green">200</div>
                        </Col> */}
                    </Row>
                    <Row className="fs-20">
                        <Col xs="9" className="display-flex pl-0 pr-2 pb-2 pt-1">
                            <Col xs="4" className="text-center back-color">
                                <div id="1" className="pt-3 pb-3 btn-back" name="1" onClick={onClickCalAction}>1</div>
                            </Col>
                            <Col xs="4" className="margin-l-r text-center back-color">
                                <div id="2" className="pt-3 pb-3 btn-back" name="2" onClick={onClickCalAction}>2</div>
                            </Col>
                            <Col xs="4" className="text-center back-color">
                                <div id="3" className="pt-3 pb-3 btn-back" name="3" onClick={onClickCalAction}>3</div>
                            </Col>
                        </Col>
                        <Col xs="9" className="display-flex pl-0 pr-2 pb-1">
                            <Col xs="4" className="text-center back-color">
                                <div id="4" className="pt-3 pb-3 btn-back" name="4" onClick={onClickCalAction}>4</div>
                            </Col>
                            <Col xs="4" className="margin-l-r text-center back-color">
                                <div id="5" className="pt-3 pb-3 btn-back" name="5" onClick={onClickCalAction}>5</div>
                            </Col>
                            <Col xs="4" className="text-center back-color">
                                <div id="6" className="pt-3 pb-3 btn-back" name="6" onClick={onClickCalAction}>6</div>
                            </Col>
                        </Col>
                        <Col xs="3" className="display-flex pr-0">
                            <Col xs="10" id="*" className="text-center back-color mul-btn" name="*" onClick={onClickCalAction}>
                                <div className="mul-btn-height pt-4 pb-4 btn-back" ><i className="material-icons">backspace</i></div>
                            </Col>
                        </Col>
                    </Row>
                    <Row className="fs-20">
                        <Col xs="9" className="display-flex pl-0 pr-2 pb-2 pt-1">
                            <Col xs="4" className="text-center back-color">
                                <div id="7" className="pt-3 pb-3 btn-back" name="7" onClick={onClickCalAction}>7</div>
                            </Col>
                            <Col xs="4" className="margin-l-r text-center back-color">
                                <div id="8" className="pt-3 pb-3 btn-back" name="8" onClick={onClickCalAction}>8</div>
                            </Col>
                            <Col xs="4" className="text-center back-color">
                                <div id="9" className="pt-3 pb-3 btn-back" name="9" onClick={onClickCalAction}>9</div>
                            </Col>
                        </Col>
                        <Col xs="3" className="display-flex p-0">
                            <Col xs="10" className="text-center back-color ok-btn">
                                <div id="=" className="mul-btn-height pt-4 pb-4 btn-back" name="=" onClick={onClickCalAction}>OK</div>
                            </Col>
                        </Col>
                        <Col xs="9" className="display-flex p-0 pl-0 pr-2">
                            <Col xs="8" className="text-center back-color">
                                <div id="0" className="pt-3 pb-3 btn-back btn-0-mr" name="0" onClick={onClickCalAction}>0</div>
                            </Col>
                            <Col xs="4" className="text-center back-color lft-10">
                                <div id="." className="pt-3 pb-3 btn-back" name="." onClick={onClickCalAction}>.</div>
                            </Col>
                        </Col>
                    </Row>
                </div>
            </div>
        );
    }
}


export default KeyPadComponent;