import React, { Component } from "react";
import { connect } from "react-redux";
import * as Maps from "./map";
import jwtService from "../services/jwtService";
import { compose } from 'redux'

class Auth extends Component {
  state = {
    waitAuthCheck: true
  };

  componentDidMount() {
    return Promise.all([
      this.jwtCheck()
    ]).then(() => {
      this.setState({ waitAuthCheck: false });
    });
  }

  jwtCheck = () =>
    new Promise(resolve => {
      jwtService.on("onAutoLogin", () => {
        //this.props.showMessage({ message: "Logging in with JWT" });
        //const user = useSelector(({ auth }) => auth.user);

        jwtService
          .getUser()
          .then(user => {
            this.props.setUser(user);

            resolve();

            //this.props.showMessage({ message: "Logged in with JWT" });
          })
          .catch(error => {
            //this.props.showMessage({ message: error });

            resolve();
          });


        jwtService
          .getLimits()
          .then(limits => {

            this.props.setLimits(limits);
            //this.props.setUser(limits);

            resolve();
          }).catch(error => { resolve(); });

        this.props.getBetslips();

      });




      jwtService.on("onAutoLogout", message => {
        if (message) {
          //this.props.showMessage({ message });
        }

        this.props.logout();

        resolve();
      });

      jwtService.on("onNoAccessToken", () => {
        resolve();
      });

      jwtService.init();

      return Promise.resolve();
    });

  render() {
    return this.state.waitAuthCheck ? (
      <div>loading...</div>
    ) : (
        <React.Fragment children={this.props.children} />
      );
  }
}


export default compose(
  connect(Maps.mapBetslipStateToProps, Maps.mapBetslipDispatchToProps),
  connect(Maps.mapUserStateToProps, Maps.mapUserDispatchToProps)
)(Auth);
