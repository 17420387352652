import React from 'react';
import { Row, Col } from 'react-bootstrap';
import * as Maps from "../../store/map";
import { connect } from 'react-redux';

class MyAccount extends React.Component {

    logout = () => {
        this.props.logoutUser();
        this.GoToPage('/');
    }

    GoToPage = path => {
        this.props.history.push(path);
    };

    render() {

        let { user } = this.props;

        return (
            <div className="main-container">
                <Row className="mx-auto p-3p bg-gray-head text-white">
                    <Col xs="2" md="2" className="pl-2 pr-2 user-content">
                        <span className="fs-18 text-white"><i className="demo-icon icon-my-account"></i></span>
                        <span className="fs-13 caption">{user.data.username}</span>
                    </Col>
                    <Col xs="9" md="9" className=" pr-0 fs-18 pt-2 pb-2">
                        {parseFloat(user.data.balance).toFixed(2)} EUR
                    </Col>
                </Row>
                <Row className="mx-auto icon-gray pb-3 pt-3 fs-18">
                    <Col xs="2" md="2" className="pl-2 pr-1 text-center ">
                        <i className="demo-icon icon-transaction"></i>
                    </Col>
                    <Col xs="10" md="10" className="pl-0 text-left">
                        Transaction
                    </Col>
                </Row>
                <div className="my-account-divider"></div>
                <Row className="mx-auto icon-gray pb-3 pt-3 fs-18">
                    <Col xs="2" md="2" className="pl-2 pr-1 text-center ">
                        <i className="demo-icon icon-password"></i>
                    </Col>
                    <Col xs="10" md="10" className="pl-0 text-left">
                        Change password
                    </Col>
                </Row>
                <div className="my-account-divider"></div>
                <Row className="mx-auto icon-gray pb-3 pt-3 fs-18">
                    <Col xs="2" md="2" className="pl-2 pr-1 text-center ">
                        <i className="demo-icon icon-logout"></i>
                    </Col>
                    <Col xs="10" md="10" className="pl-0 text-left" onClick={this.logout}>
                        Logout
                    </Col>
                </Row>
                <div className="my-account-divider"></div>
            </div>
        )
    }
}
export default connect(Maps.mapUserStateToProps, Maps.mapUserDispatchToProps)(MyAccount);