import React from 'react';

import Market from './Market';

class Markets extends React.Component {
    constructor(props) {
        super(props)
    }

    render() {
        let { markets, fixture } = this.props;

        let count = 0;
        const drawMarkets = markets ? (
            <div className="match-panel-bottom inner-shadow pt-2 pb-2">
                {markets.map(market => {
                    count++;
                    let bgLightGray = count % 2 === 1 ? " bg-light-gray " : "";
                    return (

                        <Market Market={market} fixture={fixture} column={3} key={market.Id} bgLight={bgLightGray} />
                    )
                })}

            </div>

        ) : (<div className="no-data fs-15 pt-3 pb-3">No data</div>);


        return drawMarkets;
    }
}
export default Markets;