import React from 'react';
import { Row, Col } from 'react-bootstrap';
import _ from 'lodash';

import * as Maps from "../../store/map";
import { connect } from 'react-redux';




class BetslipMultiBet extends React.Component {
    constructor(props) {
        super(props)
    }

    handleClose = (e) => {
        e.preventDefault();
        let { bet, fixture, market, switchBetslipType } = this.props;
        let { count } = this.props.betslip;
        this.props.removeBet(fixture, market, bet, bet.provider);
        if (count < 3) {
            switchBetslipType('1');
        }
    }

    canBeCombined = () => {
        let { fixture, market } = this.props;
        let { fixtures } = this.props.betslip;

        let currentFixture = _.find(fixtures, { fixtureId: fixture.FixtureId });

        return !(_.size(currentFixture.markets) > 1 || _.size(currentFixture.markets[0].bets) > 1);
    }

    render() {



        let { bet, fixture, market, index } = this.props;
        let { fixtures } = this.props.betslip;



        //console.log(market);
        let line = bet.Line ? ('(' + bet.Line + ')') : (null);


        let canBeCombined = this.canBeCombined();

        let canNotBeCombined = !canBeCombined ? (
            <Row className="mx-auto fs-15">

                <Col xs="12" className="bg-light p-1 mt-2 text-center" >

                    <span className="material-icons icon mr-2" style={{
                        verticalAlign: 'middle',
                        color: 'red',
                    }}>error</span>
                    <span>This pick can not be combined</span>

                </Col>
            </Row>
        ) : (null);

        let betStatus = bet.Status;

        let betStatusMessage = betStatus === 2 ? ('The bet is suspended (bets cannot be placed)') : ("The bet is settled(bets cannot be placed)")

        let isLive = fixture.Fixture.Status === 2 || fixture.Fixture.Status === 9;

        let printLive = isLive ? "LIVE" : "";

        let participant0 = 0;
        let participant1 = 0;

        if (isLive) {
            let results = (fixture.Livescore && fixture.Livescore.Scoreboard && fixture.Livescore.Scoreboard.Results) ? fixture.Livescore.Scoreboard.Results : null;

            if (results) {
                participant0 = results[0].Value;
                participant1 = results[1].Value;
            }
        }

        let betStatusInfo = canBeCombined && betStatus !== 1 ? (
            <Row className="mx-auto fs-15">

                <Col xs="12" className="bg-light p-1 mt-2 text-center" >

                    <span className="material-icons icon mr-2" style={{
                        verticalAlign: 'middle',
                        color: 'red',
                    }}>error</span>
                    <span>{betStatusMessage} </span>

                </Col>
            </Row>
        ) : (null);

        let drawBet = bet.isPlaced ? (
            <div className="mx-auto sectionhead pb-2 bb-1">
                <Row className="mx-auto fs-15">

                    <Col xs="12" className="bg-light p-4 mt-2 text-center fs-20" >
                        <span style={{
                            color: 'green',
                        }}>Bet Placed </span>
                        <span className="material-icons icon ml-2 mb-2 fs-32" style={{
                            verticalAlign: 'middle',
                            color: 'green',
                        }}>check</span>


                    </Col>
                </Row>
            </div>
        ) : (
                <div className="mx-auto sectionhead pb-2 bb-1">
                    <Row className="mx-auto match-inner-panel mt-2 fs-15">
                        <Col xs="1" md="auto" className="pl-0"> {index}. </Col>


                        <Col xs="6" className="red font-weight-bold">{printLive}</Col>

                        <Col className="text-right pr-0"><i className="material-icons fs-20" onClick={this.handleClose}>close</i></Col>
                    </Row>
                    <Row className="mx-auto fs-15">
                        <Col xs="1">{participant0}</Col>
                        <Col xs="6">{fixture.Fixture.Participants[0].Name}</Col>
                        <Col className="text-right fs-17 font-weight-bold">{parseFloat(bet.Price).toFixed(2)}</Col>
                    </Row>
                    <Row className="mx-auto fs-15">
                        <Col xs="1">{participant1}</Col>
                        <Col xs="6">{fixture.Fixture.Participants[1].Name}</Col>

                    </Row>

                    <Row className="mx-auto fs-15">

                        <Col xs="6" className=" text-dark-green pt-2" >{market.Name}</Col>

                        <Col xs="6" className=" text-dark-green pt-2"> Pick: {bet.Name}{line}</Col>
                    </Row>


                    {canNotBeCombined}
                    {betStatusInfo}

                </div>
            );

        return drawBet;
    }
}
export default connect(Maps.mapBetslipStateToProps, Maps.mapBetslipDispatchToProps)(BetslipMultiBet);