import React from 'react';
import { Container, Row, Col, Button } from 'react-bootstrap';
import _ from 'lodash';
import Moment from 'react-moment';
import 'moment-timezone';


class Bet extends React.Component {
    constructor(props) {
        super(props)
    }

    render() {
        let { bet, bettype_id } = this.props;
        console.log(bet)

        let betType = bettype_id === 1 ? "Single" : "Combination";

        let statusStyle = "bl-red-8";

        if (bet.settlement === null) {
            statusStyle = "bl-gray-8";
        } else if (bet.settlement === 2) {
            statusStyle = "bl-green-8";
        }

        return (


            <div>

                <div className={"pb-1 fs-13 p-0 pl-2 pr-2 " + statusStyle}>
                    <Row className="m-0 p-0 pr-2">
                        <Col xs={6} className="p-0 pt-1 font-600">Championsleague CUP</Col>
                        <Col xs={3} className="p-0 pt-1 text-center"></Col>
                        <Col xs={3} className="p-0 pt-1 text-right live-btn-bold">Live</Col>
                    </Row>
                    <Row className="m-0 p-0 pr-2">
                        <Col xs={6} className="p-0 pt-1">{bet.match}</Col>
                        <Col xs={3} className="p-0 pt-1 text-center text-dark-green fs-15">0 : 0</Col>
                        <Col xs={3} className="p-0 pt-1 text-right">Odd: {parseFloat(bet.price).toFixed(2)}</Col>
                    </Row>
                    <Row className="m-0 p-0 pr-2">
                        <Col xs={3} className="p-0 pt-1"><Moment format="DD/MM/YY">{bet.start_date}</Moment></Col>
                        <Col xs={3} className="p-0 pt-1 pl-2"><Moment format="HH:mm">{bet.start_date}</Moment></Col>
                        <Col xs={3} className="p-0 pt-1 text-center text-dark-green"></Col>
                        <Col xs={3} className="p-0 pt-1 text-right">{betType}</Col>
                    </Row>
                    <Row className="m-0 p-0 pr-2">
                        <Col xs={2} className="p-0 pt-1 pick-btn-bold">Pick {bet.name}</Col>
                        <Col xs={5} className="p-0 pt-1 pl-2">Rest of the match</Col>
                        <Col xs={2} className="p-0 pt-1 text-center text-dark-green"></Col>
                        <Col xs={3} className="p-0 pt-1 text-right"></Col>
                    </Row>
                </div>
                <div className="cashout-divider"></div>
            </div>
        )
    }
}
export default Bet;