import React from 'react';
import _ from 'lodash';

import * as Maps from "../../store/map";
import { connect } from 'react-redux';

import Matches from './Matches';

class Prematch extends React.Component {
    constructor(props) {
        super(props)
    }

    render() {
        let { eventGroup } = this.props.lSports.prematch;




        let drawMatches = _.map(eventGroup, sport => {

            return _.map(sport.Locations, location => {

                return _.map(location.Leagues, league => {

                    return (
                        <Matches {...this.props} isLive={false} events={league.Fixtures} key={"group_" + league.League.Id} />
                    )

                })
            })
        })



        return (
            <div>

                {drawMatches}

            </div>
        )
    }
}
export default connect(Maps.mapLSportsStateToProps, Maps.mapLSportsDispatchToProps)(Prematch);