import React from 'react';
import { Row, Col } from 'react-bootstrap';
import _ from 'lodash';

import * as Maps from "../../../store/map";
import { connect } from 'react-redux';




class Bet extends React.Component {
    constructor(props) {
        super(props)
    }

    handleToggle = (e) => {
        e.preventDefault();

        let { bet, fixture, market, provider } = this.props;
        let { fixtures } = this.props.betslip;

        if (_.find(fixtures, { fixtureId: fixture.FixtureId, markets: [{ Id: market.Id, bets: [{ Id: bet.Id }] }] })) {
            this.props.removeBet(fixture, market, bet, provider.Id);
        } else {
            this.props.addBet(fixture, market, bet, provider.Id);
        }
    }

    render() {

        //console.log(this.props);

        let { bet, isCentered, fixture, market } = this.props;
        let { fixtures, } = this.props.betslip;

        let line = bet.Line ? ('(' + bet.Line + ')') : (null);

        let colClass = isCentered ? "p-2 pl-2 pr-2 ml-1 border " : "lh-10 pl-2 pr-2 ml-1 border ";

        let span1Class = isCentered ? "left odd-name" : "text-center width-100 left pt-2 pb-2 odd-name ";
        let span2Class = isCentered ? "right odd-price" : "text-center width-100 right pb-2 pt-1 odd-price";
        let isActive = _.find(fixtures, { fixtureId: fixture.FixtureId, markets: [{ Id: market.Id, bets: [{ Id: bet.Id }] }] }) ? 'active' : '';

        return (
            <Col md="auto" id={"bet-" + bet.Id} className={colClass + " ripple-bet " + isActive} onClick={this.handleToggle} key={bet.Id}>
                <span className={span1Class}>{bet.Name}{line}</span>
                <span className={span2Class}>{parseFloat(bet.Price).toFixed(2)}</span>
            </Col>

        )
    }
}
export default connect(Maps.mapBetslipStateToProps, Maps.mapBetslipDispatchToProps)(Bet);