import lSportsService from "../../../services/lSportsService";

export const SET_EVENTS = "[APP] SET EVENTS";
export const UPDATE_EVENTS = "[APP] UPDATE EVENTS";
export const ON_SEARCH = "[APP] ON SEARCH";
export const ON_CLEAR_SEARCH = "[APP] ON CLEAR SEARCH";
export const ON_MAIN_MARKET_SELECTED = "[APP] ON MAIN MARKET SELECTED";
export const ON_SPORT_SELECTED = "[APP] ON SPORT SELECTED";
export const ON_LOCATION_SELECTED = "[APP] ON LOCATION SELECTED";
export const ON_LEAGUE_SELECTED = "[APP] ON LEAGUE SELECTED";
export const SET_ACTIVE_MATCH_ID = "[APP] SET ACTIVE MATCH ID";

export const SET_LOCATIONS_ACTIVE = "[APP] SET LOCATIONS ACTIVE";
export const SET_PREMATCH_ACTIVE = "[APP] SET PREMATCH ACTIVE";

export const START_LOADING = "[APP] START LOADING";
export const ON_REMOVE_LEAGUE = "[APP] ON REMOVE LEAGUE";
export const SET_SPORT_SEARCH_ACTIVE = "[APP] SET SPORT SEARCH ACTIVE";






export const selectMainMarket = (sportId, marketId) => {
  return (dispatch, getState) => {
    dispatch({ type: ON_MAIN_MARKET_SELECTED, sportId: sportId, marketId: marketId });
  }
}

export const selectSport = (sportId) => {
  return (dispatch, getState) => {
    dispatch({ type: ON_SPORT_SELECTED, sportId: sportId });
  }
}

export const selectLocation = (locationId) => {
  return (dispatch, getState) => {
    dispatch({ type: ON_LOCATION_SELECTED, locationId: locationId });
  }
}

export const selectLeague = (leagueId) => {
  return (dispatch, getState) => {
    dispatch({ type: ON_LEAGUE_SELECTED, leagueId: leagueId });
  }
}

export const setActiveMatchId = (matchId) => {
  return (dispatch, getState) => {
    dispatch({ type: SET_ACTIVE_MATCH_ID, matchId: matchId });
  }
}

export const setLocationsActive = (value) => {
  return (dispatch, getState) => {
    dispatch({ type: SET_LOCATIONS_ACTIVE, value: value });
  }
}

export const setPrematchActive = (value) => {
  return (dispatch, getState) => {
    dispatch({ type: SET_PREMATCH_ACTIVE, value: value });
  }
}

export const setSportSearchActive = (value) => {
  return (dispatch, getState) => {
    dispatch({ type: SET_SPORT_SEARCH_ACTIVE, value: value });
  }
}

export const setEvents = (events) => {
  return (dispatch, getState) => {
    dispatch({ type: SET_EVENTS, events: events });

  }
}

export const updateEvents = (events) => {
  return (dispatch, getState) => {
    dispatch({ type: UPDATE_EVENTS, events: events });

  }
}



export const search = (value) => {
  return (dispatch, getState) => {
    let { originEvents } = getState().lSports.prematch
    if (originEvents) {
      dispatch({ type: ON_SEARCH, search: value });
    }
  }
}

export const clearSearch = () => {
  return (dispatch, getState) => {
    dispatch({ type: ON_CLEAR_SEARCH });
  }
}

export const removeLeague = (sportId, locationId, leagueId) => {
  return (dispatch, getState) => {
    let { originEvents } = getState().lSports.prematch
    if (originEvents) {
      dispatch({ type: ON_REMOVE_LEAGUE, sportId: sportId, locationId: locationId, leagueId: leagueId });
    }
  }
}

export const getEvents = (lang) => {
  return (dispatch, getState) => {
    lSportsService.getEvents(lang)
      .then(events => {
        dispatch(setEvents(events.data.Body));
      }).catch(error => {
        console.error(error);
      });
  }
}

export const getEventsBySport = (lang, sportId) => {
  return (dispatch, getState) => {
    lSportsService.getEventsBySport(lang, sportId)
      .then(events => {
        dispatch(setEvents(events.data.Body));

      }).catch(error => {
        console.error(error);
      });
  }
}

export const getEventsBySportAndLocation = (lang, sportId, locationId) => {
  return (dispatch, getState) => {
    lSportsService.getEventsBySportAndLocation(lang, sportId, locationId)
      .then(events => {
        dispatch(setEvents(events.data.Body));

      }).catch(error => {
        console.error(error);
      });
  }
}

export const startLoading = (desiredLoadCount) => {
  return (dispatch, getState) => {
    dispatch({ type: START_LOADING, desiredLoadCount: desiredLoadCount });
  }
}



export const fetchEvents = (lang) => {
  return (dispatch, getState) => {
    lSportsService.fetchEvents(lang)
      .then(events => {
        dispatch(setEvents(events.Body));

      }).catch(error => {
        console.error(error);
      });
  }
}



//InPlay
export const INPLAY_START_LOADING = "[APP] INPLAY START LOADING";
export const INPLAY_SET_EVENTS = "[APP] INPLAY SET EVENTS";
export const ON_INPLAY_SPORT_SELECTED = "[APP] ON NPLAY SPORT SELECTED";
export const ON_INPLAY_SEARCH = "[APP] ON INPLAY SEARCH";
export const ON_INPLAY_LOCATION_SELECTED = "[APP] ON INPLAY LOCATION SELECTED";
export const ON_INPLAY_RESET_SELECTED_LOCATIONS = "[APP] ON INPLAY RESET SELECTED LOCATIONS";
export const INPLAY_SET_ACTIVE_MATCH_ID = "[APP] INPLAY SET ACTIVE MATCH ID";
export const ON_INPLAY_MAIN_MARKET_SELECTED = "[APP] ON INPLAY MAIN MARKET SELECTED";
export const ON_INPLAY_REMOVE_LEAGUE = "[APP] ON INPLAY REMOVE LEAGUE";


export const inplayStartLoading = (desiredLoadCount) => {
  return (dispatch, getState) => {
    dispatch({ type: INPLAY_START_LOADING, desiredLoadCount: desiredLoadCount });
  }
}

export const inplayGetEvents = (lang) => {
  return (dispatch, getState) => {
    lSportsService.inPlayGetEvents(lang)
      .then(events => {
        dispatch(inplaySetEvents(events.Body.Events));

      }).catch(error => {
        console.error(error);
      });
  }
}

export const inplaySetEvents = (events) => {
  return (dispatch, getState) => {
    dispatch({ type: INPLAY_SET_EVENTS, events: events });

  }
}

export const inplaySelectSport = (sportId) => {
  return (dispatch, getState) => {
    dispatch({ type: ON_INPLAY_SPORT_SELECTED, sportId: sportId });
  }
}

export const inplaySearch = (value) => {
  return (dispatch, getState) => {
    let { originEvents } = getState().lSports.inplay
    if (originEvents) {
      dispatch({ type: ON_INPLAY_SEARCH, search: value });
    }
  }
}

export const inplaySelectLocation = (locationId) => {
  return (dispatch, getState) => {
    dispatch({ type: ON_INPLAY_LOCATION_SELECTED, locationId: locationId });
  }
}

export const inplayResetSelectedLocations = (locationId) => {
  return (dispatch, getState) => {
    dispatch({ type: ON_INPLAY_RESET_SELECTED_LOCATIONS });
  }
}

export const inplaySetActiveMatchId = (matchId) => {
  return (dispatch, getState) => {
    dispatch({ type: INPLAY_SET_ACTIVE_MATCH_ID, matchId: matchId });
  }
}

export const inplaySelectMainMarket = (sportId, marketId) => {
  return (dispatch, getState) => {
    dispatch({ type: ON_INPLAY_MAIN_MARKET_SELECTED, sportId: sportId, marketId: marketId });
  }
}

export const inplayRemoveLeague = (sportId, locationId, leagueId) => {
  return (dispatch, getState) => {
    let { originEvents } = getState().lSports.inplay
    if (originEvents) {
      dispatch({ type: ON_INPLAY_REMOVE_LEAGUE, sportId: sportId, locationId: locationId, leagueId: leagueId });
    }
  }
}

export function mapLSportsDispatchToProps(dispatch) {
  return {
    getEvents: (lang) => { dispatch(getEvents(lang)) },
    fetchEvents: (lang) => { dispatch(fetchEvents(lang)) },
    getEventsBySport: (lang, sportId) => { dispatch(getEventsBySport(lang, sportId)) },
    getEventsBySportAndLocation: (lang, sportId, locationId) => { dispatch(getEventsBySportAndLocation(lang, sportId, locationId)) },
    search: (value) => { dispatch(search(value)) },
    clearSearch: () => { dispatch(clearSearch()) },
    selectMainMarket: (sportId, marketId) => { dispatch(selectMainMarket(sportId, marketId)) },
    setActiveMatchId: (matchId) => { dispatch(setActiveMatchId(matchId)) },
    selectSport: (sportId) => { dispatch(selectSport(sportId)) },
    selectLocation: (locationId) => { dispatch(selectLocation(locationId)) },
    selectLeague: (leagueId) => { dispatch(selectLeague(leagueId)) },
    setLocationsActive: (value) => { dispatch(setLocationsActive(value)) },
    setPrematchActive: (value) => { dispatch(setPrematchActive(value)) },
    startLoading: (desiredLoadCount) => { dispatch(startLoading(desiredLoadCount)) },
    removeLeague: (sportId, locationId, leagueId) => { dispatch(removeLeague(sportId, locationId, leagueId)) },
    setSportSearchActive: (value) => { dispatch(setSportSearchActive(value)) },
    updateEvents: (events) => { dispatch(updateEvents(events)) },

    //inplay
    inplayStartLoading: (desiredLoadCount) => { dispatch(inplayStartLoading(desiredLoadCount)) },
    inplayGetEvents: (lang) => { dispatch(inplayGetEvents(lang)) },
    inplaySelectSport: (sportId) => { dispatch(inplaySelectSport(sportId)) },
    inplaySearch: (value) => { dispatch(inplaySearch(value)) },
    inplaySelectLocation: (locationId) => { dispatch(inplaySelectLocation(locationId)) },
    inplayResetSelectedLocations: () => { dispatch(inplayResetSelectedLocations()) },
    inplaySetActiveMatchId: (matchId) => { dispatch(inplaySetActiveMatchId(matchId)) },
    inplaySelectMainMarket: (sportId, marketId) => { dispatch(inplaySelectMainMarket(sportId, marketId)) },
    inplayRemoveLeague: (sportId, locationId, leagueId) => { dispatch(inplayRemoveLeague(sportId, locationId, leagueId)) },

  }
}

