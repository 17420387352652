import React from "react";
import { Modal, Row, Col, Button } from "react-bootstrap";

import * as Maps from "../../store/map";
import { connect } from 'react-redux';

class Login extends React.Component {

  state = {
    ...this.state,
    smShow: true,
    checked: true
  };

  isStayLogin = () => {
    this.setState({ checked: !this.state.checked });
  };

  handleSubmit = (e) => {
    this.props.submitLogin(this.state.inputUsername, this.state.inputPassword);
  };

  handleChange = (e) => {
    this.setState({ ...this.state, [e.target.id]: e.target.value });
  };

  render() {

    let { user } = this.props;
    let smShow = user.data === null;
    let { checked } = this.state;

    console.log(user, smShow);

    return (
      <div>
        <Modal size="sm" show={smShow} onHide={this.props.hideLogin} aria-labelledby="example-modal-sizes-title-sm" centered>
          <div>
            <div className="pl-2 ml-1 pt-2 text-dark-green">
              <i className="demo-icon icon-effebet-logo"></i>
            </div>
          </div>
          <Modal.Body>
            <form onSubmit={e => { e.preventDefault(); this.handleSubmit() }} >
              <div className="p-3">
                <Row className="mt-1">
                  <input type="text" className="login-input" placeholder="Username" id="inputUsername" onChange={this.handleChange} />
                </Row>
                <Row className="mt-2">
                  <input type="password" className="login-input" placeholder="Password" id="inputPassword" onChange={this.handleChange} />
                </Row>
                <Row className="fs-13">
                  <Col className="pt-2">
                    <label onClick={this.isStayLogin}>
                      <input type="checkbox" defaultChecked={checked} name="remember" />{" "}
                      Stay logged in
                  </label>
                  </Col>
                </Row>
                <Row className="mg-lg-btn">
                  <Button className="login-btn" type="submit">Login</Button>
                </Row>
              </div>
            </form>
          </Modal.Body>
        </Modal>
      </div>
    );
  }
}
export default connect(Maps.mapUserStateToProps, Maps.mapUserDispatchToProps)(Login);
