import React from 'react';
import { Row, Col } from 'react-bootstrap';
import _ from 'lodash';

import Util from '../../../helper/Util';

import Bet from './Bet';

class Market extends React.Component {
    constructor(props) {
        super(props)
    }

    handleToggle = (e) => {
        e.preventDefault();
        var element = e.currentTarget;
        if (element.classList.contains('active')) {
            element.classList.remove("active");
        }
        else {
            element.classList.add("active");
        }
    }



    drawBet = (bet, isCentered) => {

        let line = bet.Line ? ('(' + bet.Line + ')') : (null);
        return isCentered ? (
            <Col md="auto" id={"bet-" + bet.Id} className="p-2 pl-2 pr-2 ml-1 border " onClick={this.handleToggle} key={bet.Id}>
                <span className="left odd-name">{bet.Name}{line}</span>
                <span className="right odd-price">{parseFloat(bet.Price).toFixed(2)}</span>
            </Col>
        ) : (
                <Col md="auto" id={"bet-" + bet.Id} className="lh-10 pl-2 pr-2 ml-1 border " onClick={this.handleToggle} key={bet.Id}>
                    <span className="text-center width-100 left pt-2 pb-2 odd-name ">{bet.Name}{line}</span>
                    <span className="text-center width-100 right pb-2 pt-1 odd-price">{parseFloat(bet.Price).toFixed(2)}</span>
                </Col>
            );
    }

    render() {

        let { Market, column, bgLight, fixture } = this.props;

        let { Providers } = Market;

        let selectedProvider = Providers[0];
        let { Bets } = Providers ? selectedProvider : null;

        Bets = Util.sortBet(Bets, Market.Id);


        let slicedBets = null;

        if (_.size(Bets) > 0 && Bets[0].BaseLine !== undefined) {
            slicedBets = _.groupBy(Bets, function (bet) {
                return `${bet.BaseLine}`;
            });

            slicedBets = Object.values(slicedBets);
        } else {
            slicedBets = new Array(Math.ceil(Bets.length / column)).fill("").map(function () { return this.splice(0, column) }, Bets.slice());
        }

        let count = 0;
        const drawBets = slicedBets ? (slicedBets.map(flex => {
            count++;
            return (
                <div className="display-flex pb-1 pl-2p" key={count}>
                    {
                        flex.map(item => {
                            return <Bet fixture={fixture} market={Market} bet={item} provider={selectedProvider} isCentered={_.size(flex) < 3} key={item.Id} />;
                        })
                    }
                </div>
            )
        })) : (null);


        let line = Market.Line ? (<span>{Market.Line}</span>) : (null);

        return (

            <Row className={"mx-auto match-inner-panel-bg market-odds pt-1 " + bgLight}>
                <Col xs="4" lg="4" className=" align-middle align-self-center">
                    {Market.Name} {line}
                </Col>
                <Col xs="8" lg="8" className=" pb-1 pt-1">

                    {drawBets}

                </Col>
            </Row>
        )
    }
}
export default Market;