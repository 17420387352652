import React from 'react';
import _ from 'lodash';

import * as Maps from "../../store/map";
import { connect } from 'react-redux';
import { lSportsConfig } from '../../config/lsports.config'

import { Tabs, Tab, AppBar } from '@material-ui/core';

import { createMuiTheme } from '@material-ui/core/styles';
import { ThemeProvider } from '@material-ui/styles';

const theme = createMuiTheme({
    palette: {
        primary: {
            main: '#0f673d',
        },

    },
});

class MainMarketSelector extends React.Component {


    handleClick = (sportId, value) => {

        this.props.selectMainMarket(sportId, value);
    }

    isDisabled = (events, marketId) => {
        let size = _.size(events);
        let matchCount = _.filter(events, e => {
            return _.some(e.Markets, m => {
                return m.Id === marketId;
            })
        })
        return size === _.size(matchCount) ? '' : 'disabled';
    }

    render() {

        let { events } = this.props;

        let sport = events[0].Fixture.Sport;

        let { mainMarket } = this.props.lSports.prematch;

        let currentMainMarket = _.find(mainMarket, m => m.Id === sport.Id);

        let selectedTab = 0;

        let marketToSelectList = _.map(currentMainMarket.Markets, (value, key) => {

            if (currentMainMarket.selectedMarket === value.Id) {
                selectedTab = key;
            }

            return (
                <Tab style={{
                    textTransform: 'none',
                    fontFamily: 'inherit',
                    fontSize: 'inherit',
                    fontWeight: 'inherit',
                    lineHeight: 'inherit',
                }} label={value.Name} key={value.Id} onClick={(e) => {
                    e.preventDefault();
                    this.handleClick(sport.Id, value.Id)
                    document.activeElement.blur();
                }} />
            )
        })

        return (
            <ThemeProvider theme={theme}>

                <AppBar position={'sticky'} color="default" style={{ top: '0px' }} >
                    <Tabs ref={this.refTabs}
                        value={selectedTab}
                        indicatorColor="primary"
                        textColor="primary"

                        aria-label="scrollable auto tabs example"
                        variant="scrollable"
                        scrollButtons="auto"
                    >

                        {marketToSelectList}
                    </Tabs>
                </AppBar >

            </ThemeProvider>

        )
    }
}
export default connect(Maps.mapLSportsStateToProps, Maps.mapLSportsDispatchToProps)(MainMarketSelector);