import jwtService from "../../../services/jwtService";
import axios from "axios";
import { SET_LOADING } from "./general.actions";

export const GET_USER = "[APP] GET USER";
export const SET_USER = "[APP] SET USER";
export const SUBMIT_LOGIN = "[APP] SUBMIT USER";
export const LOGOUT_USER = "[APP] SUBMIT USER";
export const LOGIN_ERROR = "[APP] LOGIN ERROR";
export const LOGIN_SUCCESS = "[APP] LOGIN SUCCESS";
export const CLEAR_BETS = "[APP] CLEAR BETS";
export const SET_PLACE_BET_ERROR = "[APP] SET PLACE BET ERROR";
export const SET_PLACE_BET_SUCCESS = "[APP] SET PLACE BET SUCCESS";
export const SET_BETSLIPS = "[APP] SET PETSLIPS";



export const submitLogin = (username, password) => {
  return (dispatch, getState) => {
    jwtService
      .signInWithUsernameAndPassword(username, password)
      .then(user => {

        jwtService.getUser()
          .then(user => {
            dispatch({ type: SET_USER, user: user })
          });

        return dispatch({
          type: LOGIN_SUCCESS
        });
      }).catch(error => {
        return dispatch({
          type: LOGIN_ERROR,
          loginError: error
        });
      });
  }
}

export const logoutUser = (username, password) => {
  return (dispatch, getState) => {
    jwtService.logout();
    dispatch({ type: LOGOUT_USER })
  }
}

export const setUser = (user) => {
  return (dispatch, getState) => {
    dispatch({ type: SET_USER, user: user })
  }
}

export const getUser = () => {
  return (dispatch, getState) => {
    jwtService.getUser()
      .then(user => {
        dispatch(setUser(user))
      });
  }
}

export const getBetslips = () => {
  return (dispatch, getState) => {
    jwtService.getBetslips()
      .then(betslips => {
        dispatch(dispatch({ type: SET_BETSLIPS, betslips: betslips }))
      });
  }
}

export const placeBet = (data) => {
  return (dispatch, getState) => {

    dispatch({ type: SET_LOADING, value: true })

    jwtService
      .placeBet(data)
      .then(user => {

        dispatch({ type: SET_LOADING, value: false })
        dispatch({ type: SET_USER, user: user })

        return dispatch({
          type: SET_PLACE_BET_SUCCESS,
        });
      }).catch(error => {
        dispatch({
          type: SET_PLACE_BET_ERROR,
          error: error
        });

        return dispatch({ type: SET_LOADING, value: false });
      });
  }
}

export const setPlaceBetError = (error) => {
  return (dispatch, getState) => {
    dispatch({ type: SET_PLACE_BET_ERROR, error: error })
  }
}

//setTimeout(this.props.setBets(Util.filterBetStakeZeroIn(fixtures)), 2000);

export function mapUserDispatchToProps(dispatch) {
  return {
    getUser: () => { dispatch(getUser()) },
    setUser: (user) => { dispatch(setUser(user)) },
    submitLogin: (username, password) => { dispatch(submitLogin(username, password)) },
    logoutUser: () => { dispatch(logoutUser()) },
    placeBet: (data) => {

      dispatch(placeBet(data))
    },
    setPlaceBetError: (error) => { dispatch(setPlaceBetError(error)) },
    getBetslips: () => { dispatch(getBetslips()) },

  }
}

