import React from 'react';
import { Container, Row, Col, Button } from 'react-bootstrap';
import _ from 'lodash';
import Moment from 'react-moment';
import 'moment-timezone';
import Bet from './Bet';


class Bets extends React.Component {
    constructor(props) {
        super(props)
    }

    render() {
        let { betslip, closeModal } = this.props;

        let drawBets = _.map(betslip.bets, bet => {
            return <Bet bet={bet} bettype_id={betslip.bettype_id} key={bet.id} />
        })

        console.log(betslip)
        return (

            <div className="fs-13">
                <Row className="mx-auto pl-2 pr-2 fs-13">
                    <Col xs={12} md={12} className="pr-2 pt-2 text-right">
                        <i className="material-icons" onClick={closeModal}>close</i>
                    </Col>
                </Row>

                <Row className="mx-auto text-gray pl-2 pr-2 fs-13">
                    <Col xs={3} md={3} className="pt-0 pl-2 pb-2 pr-2"><Moment format="HH:mm">{betslip.created_at}</Moment></Col>
                    <Col xs={3} md={3} className="pt-0 pl-2 pb-2 pr-2"><Moment format="DD/MM/YY">{betslip.created_at}</Moment></Col>
                    <Col xs={6} md={6} className="pt-0 pl-2 pb-2 pr-2 text-right">Total odd: {parseFloat(betslip.stake).toFixed(2)}</Col>
                </Row>
                <div className="cashout-divider"></div>
                {drawBets}

                <div className="mb-5"></div>
            </div>
        )
    }
}
export default Bets;