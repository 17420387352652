import React from 'react';
import { Row, Col } from 'react-bootstrap';
import _ from 'lodash';
import * as Maps from "../../store/map";
import { connect } from 'react-redux';


class Locations extends React.Component {
    constructor(props) {
        super(props)
    }

    resetSelectedLocations = (e) => {
        e.preventDefault();
        this.props.inplayResetSelectedLocations();
    }

    handleClick = (e) => {
        e.preventDefault();
        this.props.inplaySelectLocation(e.currentTarget.id);
    }

    render() {

        let { locations, selectedLocations } = this.props.lSports.inplay;

        let isAllActive = _.size(selectedLocations) === 0;

        const locationsList = locations ? (

            locations.map((item, i) => {

                let locationId = item.Id;
                let locationName = item.Name;



                let contains = _.includes(selectedLocations, locationId + "");

                return (

                    <div className="live-box-col" id={locationId} onClick={this.handleClick} key={locationId}>
                        <div className={`live-box ${contains ? 'active' : ''}`}>
                            {/* <img className="live-flag-25" src={turkeyFlag} /> */}
                            <div country={"flag_" + locationId} className="live-flag-25 flag"></div>
                            <span className="pl-1">{locationName.substring(0, 3)}</span>
                        </div>
                    </div>
                )
            })
        ) : (<div>loading...</div>)

        return (
            <div >
                <div className="live-box-row mt-2 mb-2">
                    <div className="live-box-col" onClick={this.resetSelectedLocations}>
                        <div className={`live-box ${isAllActive ? 'active' : ''}`}>
                            <span>All</span>
                        </div>
                    </div>
                    {locationsList}

                </div>
            </div>
        )
    }
}

export default connect(Maps.mapLSportsStateToProps, Maps.mapLSportsDispatchToProps)(Locations);
