import React from 'react';
import { Link } from "react-router-dom";
import * as Maps from "../../../store/map";
import { connect } from 'react-redux';
import _ from 'lodash';

class SearchBar extends React.Component {
    constructor(props) {
        super(props)

    }

    handleChanged = (e) => {
        this.props.search(e.target.value)
    }

    render() {
        let { search } = this.props.lSports.prematch;
        return (
            <div className="sport-header pt-2 disable-select">
                <div className="sports-box-row" style={{ height: '40px' }}>
                    <div className="sport-search-bar show-search pb-2" >
                        <input type="text" className="ml-2 form-control search-bar" autoFocus placeholder="Search here" value={search ? search : ''} onChange={this.handleChanged} />
                        <Link className="search-close-btn" to="/prematch">
                            <i className="material-icons" onClick={this.openSeachBar}> close </i>
                        </Link>

                    </div>
                </div>
            </div>
        )
    }
}
export default connect(Maps.mapLSportsStateToProps, Maps.mapLSportsDispatchToProps)(SearchBar);