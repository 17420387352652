import React from 'react';
import LiveCasino from './LiveCasino';
import LiveCasinoHeader from './LiveCasinoHeader';
class CasinoIndex extends React.Component{
    render(){
        return(
            <div className="main-container">
                <LiveCasinoHeader/>
                <LiveCasino />
            </div>
        )
    }
}
export default CasinoIndex;