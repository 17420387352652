import React from 'react';
import { Container, Row, Col, Button } from 'react-bootstrap';
import _ from 'lodash';
import Moment from 'react-moment';
import 'moment-timezone';
import PopUpModal from '../Common/Modal'
import Bets from './Bets'

import Util from '../../helper/Util';

class Betslip extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            show: false,
            content: []
        }
    }

    handleClick = (e) => {
        e.preventDefault();
        let { betslip } = this.props;

        this.setState({
            show: true,
            content: <Bets betslip={betslip} closeModal={this.closeModal} />,
        })
    }

    closeModal = () => {
        this.setState({ show: false })
    }


    render() {
        let { betslip, showBets } = this.props;
        let { show, content } = this.state;



        let statusStyle = 'bl-grey';

        if (betslip.settlement === 0) {
            statusStyle = 'bl-gray'
        } else if (betslip.settlement === 1) {
            statusStyle = 'bl-red'
        } else if (betslip.settlement === 2) {
            statusStyle = 'bl-green'
        }

        let drawBets = show ? (<PopUpModal show={show} content={content} closeModal={this.closeModal} />) : (null);

        return (
            <div>
                <Row md="auto" className={"mx-auto text-center " + statusStyle + " bb-1-light fs-12 text-gray"} onClick={this.handleClick}>
                    <Col xs="2" md="2" lg="2" className="pt-1 pb-1 p-0">
                        <div><Moment format="DD/MM/YY">{betslip.created_at}</Moment></div>
                        <div><Moment format="HH:mm">{betslip.created_at}</Moment></div>
                    </Col>
                    <Col xs="3" md="3" lg="3" className="lh-50 p-0">{parseFloat(betslip.stake).toFixed(2)} EUR</Col>
                    <Col xs="3" md="3" lg="3" className="lh-50 p-0">{parseFloat(betslip.possible_win).toFixed(2)} EUR</Col>
                    <Col xs="4" md="4" lg="4" className="lh-50 p-0 text-left">{parseFloat(betslip.win).toFixed(2)} EUR</Col>

                </Row>
                {drawBets}
            </div>
        )
    }
}
export default Betslip;