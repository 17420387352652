import React from "react";
import { Dropdown } from "react-bootstrap";
class Header extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {


    return (
      <div className="container header-fix bg-green pt-2 pb-2 clearfix" style={{ "zIndex": "1200" }}>
        <span
          className="float-left fs-22 text-white font-weight-bold disable-select"
          onClick={e => this.props.GoToPage("")}
        >
          {/* <img src={Logo} className="logo" /> */}

          <i className="demo-icon icon-effebet-logo"></i>
          {/* effebet */}
        </span>
        <span className="float-right fs-22">
          <Dropdown onSelect={this.changeLanguage}>
            <Dropdown.Toggle as={Dropdown} id="dropdown-custom-components">
              TR
            </Dropdown.Toggle>

            <Dropdown.Menu>
              <Dropdown.Item>EN</Dropdown.Item>
              <Dropdown.Item active>TR</Dropdown.Item>
              <Dropdown.Item>FR</Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </span>
      </div>
    );
  }
}
export default Header;
