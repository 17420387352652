
import * as Actions from "../map/actions";
import _ from 'lodash';

import Util from "../../helper/Util";

const initialState = {
  fixtures: [],
  multiStake: 0.00,
  totalOdds: 0.00,
  limits: [
    {
      "id": 1,
      "type": "max",
      "name": "Max single bet",
      "default_limit": 250,
      "status": 0,
      "created_at": "2019-11-17 08:09:39",
      "updated_at": "2019-11-20 18:27:03"
    },
    {
      "id": 2,
      "type": "min",
      "name": "Min single bet",
      "default_limit": 2,
      "status": 0,
      "created_at": "2019-11-17 08:09:39",
      "updated_at": "2019-11-20 18:52:39"
    },
    {
      "id": 3,
      "type": "max",
      "name": "Max win amount",
      "default_limit": 1500,
      "status": 0,
      "created_at": "2019-11-17 08:09:39",
      "updated_at": "2019-11-20 18:52:39"
    },
    {
      "id": 4,
      "type": "max",
      "name": "Max odd",
      "default_limit": 2500,
      "status": 1,
      "created_at": "2019-11-17 08:09:39",
      "updated_at": "2019-11-19 21:38:05"
    },
    {
      "id": 5,
      "type": "min",
      "name": "Min tip",
      "default_limit": 0,
      "status": 1,
      "created_at": "2019-11-17 08:09:39",
      "updated_at": "2019-11-19 21:22:11"
    },
    {
      "id": 6,
      "type": "min",
      "name": "Min bet amount",
      "default_limit": 2,
      "status": 1,
      "created_at": "2019-11-17 08:09:39",
      "updated_at": "2019-11-19 21:48:20"
    },
    {
      "id": 7,
      "type": "max",
      "name": "Max bet amount combine",
      "default_limit": 0,
      "status": 1,
      "created_at": "2019-11-17 08:09:39",
      "updated_at": "2019-11-19 21:22:11"
    },
    {
      "id": 8,
      "type": "max",
      "name": "Max bet amount per match",
      "default_limit": 0,
      "status": 1,
      "created_at": "2019-11-17 08:09:39",
      "updated_at": "2019-11-19 21:42:40"
    }
  ]
};



const betslipReducer = function (state = initialState, action) {

  switch (action.type) {

    case Actions.ADD_BET: {

      let fixtures = [];
      let foundFixture = _.find(state.fixtures, { fixtureId: action.fixture.FixtureId });

      if (foundFixture) {

        let foundMarket = _.find(foundFixture.markets, { Id: action.market.Id });

        if (foundMarket) {

          let foundBet = _.find(foundMarket.bets, { Id: action.bet.Id });

          if (foundBet) {

          } else {
            foundMarket.bets = [
              ...foundMarket.bets,
              _.assign({}, action.bet, { Stake: 0.00, provider: action.provider, })
            ]
          }

        } else {
          foundFixture.markets = [
            ...foundFixture.markets,
            {
              Id: action.market.Id,
              market: action.market,
              bets: [
                _.assign({}, action.bet, { Stake: 0.00, provider: action.provider, })
              ]
            }
          ]
        }


        fixtures = state.fixtures;

      } else {
        fixtures = [
          ...state.fixtures,
          {
            fixtureId: action.fixture.FixtureId,
            fixture: action.fixture,
            markets: [
              {
                Id: action.market.Id,
                market: action.market,
                bets: [
                  _.assign({}, action.bet, { Stake: 0.00, provider: action.provider, })
                ]
              }
            ]
          }
        ]
      }

      let allBets = _.map(fixtures, f => {
        return _.map(f.markets, m => {
          return _.map(m.bets, b => {
            return b;
          })
        })
      });

      allBets = _.flattenDepth(allBets, 2);

      let totalMultiOdds = 0.0;
      let totalOdds = _.sum(_.map(allBets, b => {
        if (totalMultiOdds === 0.0) {
          totalMultiOdds = Util.toFixedFloat(b.Price, 2);
        } else {
          totalMultiOdds *= Util.toFixedFloat(b.Price, 2);
        }
        return Util.toFixedFloat(b.Price, 2);
      }));

      return {
        ...state,
        fixtures: fixtures,
        count: _.size(allBets),
        totalOdds: totalOdds,
        totalMultiOdds: totalMultiOdds,
      };
    }

    case Actions.REMOVE_BET: {

      let fixtures = state.fixtures;

      _.forEach(fixtures, e => {

        if (e.fixtureId === action.fixture.FixtureId) {

          _.forEach(e.markets, market => {

            if (market.Id === action.market.Id) {

              market.bets = _.filter(market.bets, bet => {

                return bet.Id !== action.bet.Id;
              });

              e.markets = _.filter(e.markets, m => {
                return m.bets.length !== 0;
              });
            }
          })

          fixtures = _.filter(fixtures, g => {
            return g.markets.length !== 0;
          });
        }
      })


      let allBets = _.map(fixtures, f => {
        return _.map(f.markets, m => {
          return _.map(m.bets, b => {
            return b;
          })
        })
      });

      allBets = _.flattenDepth(allBets, 2);

      let totalMultiOdds = 0.0;
      let totalOdds = _.sum(_.map(allBets, b => {
        if (totalMultiOdds === 0.0) {
          totalMultiOdds = Util.toFixedFloat(b.Price, 2);
        } else {
          totalMultiOdds *= Util.toFixedFloat(b.Price, 2);
        }
        return Util.toFixedFloat(b.Price, 2);
      }));



      return {
        ...state,
        fixtures: fixtures,
        count: _.size(allBets),
        totalOdds: totalOdds,
        totalMultiOdds: totalMultiOdds,
      };
    }

    case Actions.SET_BETS: {

      let fixtures = action.fixtures;

      let allBets = _.map(fixtures, f => {
        return _.map(f.markets, m => {
          return _.map(m.bets, b => {
            return b;
          })
        })
      });

      allBets = _.flattenDepth(allBets, 2);

      let totalMultiOdds = 0.0;
      let totalOdds = _.sum(_.map(allBets, b => {
        if (totalMultiOdds === 0.0) {
          totalMultiOdds = Util.toFixedFloat(b.Price, 2);
        } else {
          totalMultiOdds *= Util.toFixedFloat(b.Price, 2);
        }
        return Util.toFixedFloat(b.Price, 2);
      }));



      return {
        ...state,
        fixtures: fixtures,
        count: _.size(allBets),
        totalOdds: totalOdds,
        totalMultiOdds: totalMultiOdds,
      };
    }

    case Actions.CLEAR_BETS: {
      return {
        ...state,
        fixtures: [],
        count: 0,
        multiStake: 0.00,
        totalOdds: 0.00,
      };
    }

    case Actions.SET_MULTI_STAKE: {

      return {
        ...state,
        multiStake: action.value,
      };
    }

    case Actions.SET_BET_STAKE: {

      let fixtures = state.fixtures;
      let foundFixture = _.find(state.fixtures, { fixtureId: action.fixture.FixtureId });

      if (foundFixture) {
        let foundMarket = _.find(foundFixture.markets, { Id: action.market.Id });

        if (foundMarket) {
          let foundBet = _.find(foundMarket.bets, { Id: action.bet.Id });

          if (foundBet) {
            foundBet.Stake = action.value
          }
        }

        fixtures = state.fixtures;
      }

      return {
        ...state,
        fixtures: fixtures,
      };
    }

    case Actions.SET_LIMITS: {

      return {
        ...state,
        limits: action.limits,
      };
    }

    default:
      return state;
  }
};


export default betslipReducer;


