import React from 'react'
import 'bootstrap/dist/css/bootstrap.min.css';
import Login from '../Login/Login'
import { Link } from 'react-router-dom';

import * as Maps from "../../store/map";
import { connect } from 'react-redux';

class Navigator extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            showLogin: false,
            activeNav: ''
        }
    }
    toggleNevigation = (e, nav) => {
        this.setState({
            activeNav: nav
        })
        this.GoTo(nav);
    }
    showLoginForm = (e) => {
        this.setState({ showLogin: true })
    }
    hideLogin = () => {
        this.setState({ showLogin: false })
    }
    GoTo = (path) => {
        this.props.history.push('/' + path)
    }

    isLoggedIn = () => {
        return this.props.user.data !== null;
    }

    render() {
        let { location } = this.props;
        let { activeNav } = this.state;
        let { user } = this.props;
        let { count } = this.props.betslip;

        //console.log(this.props);


        let myAccount = this.isLoggedIn() ? (
            <div className={`menu-item ripple pl-0 pr-0 ${location.pathname === '/my-account' || activeNav === 'my-account' ? 'active' : ''}`} onClick={(e) => this.toggleNevigation(e, 'my-account')} >
                <div className="menu-content tooltip">
                    <span className="tooltiptext">{parseFloat(user.data.balance).toFixed(2)}</span>
                    <i className="demo-icon icon-my-account fs-23"></i>
                    <div className='menu-text lh-17'>My Account</div>
                </div>
            </div>
        ) : (
                <div className='menu-item ripple pl-0 pr-0' onClick={this.showLoginForm} >
                    <div className="menu-content tooltip">

                        <i className="demo-icon icon-my-account fs-23"></i>
                        <div className='menu-text lh-17'>Login</div>
                    </div>
                </div>
            );



        let myBets = this.isLoggedIn() ? (
            <div className={`menu-item ripple ${location.pathname === '/my-bets' ? 'active' : ''}`} onClick={(e) => this.toggleNevigation(e, 'my-bets')} >
                <div className="menu-content">
                    <i className="demo-icon icon-my-bets fs-22"></i>
                    <div className='menu-text pt-1'>My Bets</div>
                </div>

            </div>
        ) : (
                <div className='menu-item ripple disabled' >
                    <div className="menu-content">
                        <i className="demo-icon icon-my-bets fs-22"></i>
                        <div className='menu-text pt-1'>My Bets</div>
                    </div>

                </div>
            );

        let drawBetslipCount = count > 0 ? (<span className="tooltipbit tooltiptext">{count}</span>) : (null);

        return (
            <div className="navbar">
                <div className={`menu-item ripple ${location.pathname === '/' ? 'active' : ''}`} onClick={(e) => this.toggleNevigation(e, '')}>
                    <div className="menu-content pt-12p">
                        <Link to='/'>
                            {/* <img src={HomeIcon} className="navigate-icon"/> */}
                            <i className="demo-icon icon-home fs-23"></i>
                            <div className='menu-text lh-17'>Home</div>
                        </Link>
                    </div>
                </div>
                <div className={`menu-item ripple ${location.pathname === '/inplay' ? 'active' : ''}`} onClick={(e) => this.toggleNevigation(e, 'inplay')}>
                    <div className="menu-content">
                        <i className="demo-icon icon-live fs-25 bg-live"></i>
                    </div>
                    {/* <div className="navigator-divider"></div> */}
                </div>
                {myBets}

                {myAccount}

                <div className={`menu-item ripple pl-0 pr-0 ${count > 0 ? '' : 'disabled'} ${location.pathname === '/betslip' && count > 0 ? 'active' : ''}`} onClick={(e) => this.toggleNevigation(e, 'betslip')}>
                    <div className="menu-content">
                        <a className="tooltip">
                            {drawBetslipCount}
                            <i className="material-icons fs-27">receipt</i>
                            <div className='menu-text lh-6p'>Betslip</div>
                        </a>
                    </div>
                    {/* <div className="navigator-divider"></div> */}
                </div>
                {
                    this.state.showLogin ?
                        <Login show={this.state.showLogin} hideLogin={this.hideLogin} />
                        :
                        ''
                }
            </div>
        )
    }
}
export default connect(Maps.mapUserStateToProps, Maps.mapUserDispatchToProps)(Navigator);