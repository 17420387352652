import React from 'react';
import { Row, Col } from 'react-bootstrap';
import _ from 'lodash';

import * as Maps from "../../../store/map";
import { connect } from 'react-redux';

class League extends React.Component {
    constructor(props) {
        super(props)
    }

    handleClick = (e, leagueId) => {
        e.preventDefault();
        this.props.selectLeague(leagueId);

        this.props.setLocationsActive(false);
        this.props.setPrematchActive(true);
    }

    render() {

        let { leagues } = this.props;

        let index = 0;
        let drawLeagues = leagues ? (_.map(leagues, item => {

            let leagueId = item.League.Id;
            let leagueName = item.League.Name;
            let count = item.FixtureCount;

            index++;
            return (
                <div key={leagueId}>
                    <Row className="m-0 justify-content-md-center" onClick={(e) => this.handleClick(e, leagueId)}>
                        <Col xs="1" lg="1" className="p-0"></Col>
                        <Col xs="2" lg="2" md="auto">{index}.</Col>
                        <Col xs="7" lg="7" md="auto" className="p-0">{leagueName}</Col>
                        <Col xs="1" lg="1" md="auto" className="p-0">{count}</Col>
                        <Col xs="1" lg="1" md="auto" className="p-0"><i className="material-icons align-i"> keyboard_arrow_right </i></Col>
                    </Row>
                    <div className="sports-divider"></div>
                </div>
            )
        })

        ) : (null);


        return (
            <div className="articlewrap">
                {/* <div className="no-data">No data</div> */}
                {drawLeagues}
            </div>
        )
    }
}
export default connect(Maps.mapLSportsStateToProps, Maps.mapLSportsDispatchToProps)(League);
