import React, { Component } from 'react';
import { Modal, Button } from 'react-bootstrap';
import ResultComponent from './ResultComponent';
import KeyPadComponent from "./KeyPadComponent";

import * as Maps from "../../store/map";
import { connect } from 'react-redux';

class Calculator extends Component {
    constructor(props) {
        super(props);

        this.state = {
            result: props.startValue > 0.00 ? props.startValue : "",
        }
    }

    onClickCalAction = (e) => {
        e.preventDefault();
        let { onSetValue, onCloseModal } = this.props;

        let action = e.currentTarget.id;
        if (action === "=") {
            onCloseModal();
        }

        else if (action === "C") {
            this.reset()
        }
        else if (action === "CE" || action === "*") {
            this.backspace()
        }
        else if (action === "10" || action === "20" || action === "50" || action === "100" || action === "200") {
            onSetValue(action);
            this.setState({
                result: action
            })
            onCloseModal();
        }
        else {
            onSetValue(this.state.result + action);
            this.setState({
                result: this.state.result + action
            })
        }
    };
    calculate = () => {
        let { onSetValue } = this.props;


        var checkResult = ''
        if (this.state.result.includes('--')) {
            checkResult = this.state.result.replace('--', '+')
        }

        else {
            checkResult = this.state.result
        }

        try {
            onSetValue((eval(checkResult) || "") + "");
            this.setState({
                // eslint-disable-next-line
                result: (eval(checkResult) || "") + ""
            })
        } catch (e) {
            onSetValue(0.00);
            this.setState({
                result: "error"
            })

        }
    };

    reset = () => {
        let { onSetValue } = this.props;
        onSetValue(0.00);
        this.setState({
            result: ""
        })
    };

    backspace = () => {
        let { onSetValue } = this.props;

        let result = this.state.result.slice(0, -1);

        onSetValue(result === "" ? 0.00 : result);


        this.setState({
            result: result
        })
    };

    render() {
        let { onCloseModal, onSetValue, startValue, possibleWin } = this.props;
        return (
            <div>
                <Modal className="modal-dialog-cal modal-calculator" show={true} onHide={(e) => onCloseModal()}>
                    <Modal.Body className="modal-body">
                        {/* <ResultComponent result={this.state.result} /> */}
                        <KeyPadComponent onClickCalAction={this.onClickCalAction} result={this.state.result} possibleWin={possibleWin} />

                    </Modal.Body>
                </Modal>
            </div>
        )
            ;
    }
}


export default connect(Maps.mapBetslipStateToProps, Maps.mapBetslipDispatchToProps)(Calculator);