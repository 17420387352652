import React from 'react';
import turkeyFlag from '../../Flags/218-turkey.svg';
import englandFlag from '../../Flags/216-england.svg';
import russiaFlag from '../../Flags/248-russia.svg';
import germanyFlag from '../../Flags/162-germany.svg';
import { Container, Row, Col, Button } from 'react-bootstrap';
import PopUpModal from '../Common/Modal'
class Live extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            isActive: 'eng',
            isActiveBtn: '2',
            isActiveSubBtn: '5',
            show: false,
            content: [],
            activeRowId: 2,
            matchActivity: 1,
            activeStat: 3,
            star: false
        }
    }

    setStarActive = () => {
        this.setState({
            star: !this.state.star
        })
    }
    setMatchActivity = (value) => {
        this.setState({
            matchActivity: value
        })
    }
    closeModal = () => {
        this.setState({
            show: false
        })
    }
    openResult = (value) => {
        let activeId = value === this.state.activeRowId ? '' : value
        this.setState({
            activeRowId: activeId
        })
    }
    setActive = (value) => {

        this.setState({ isActive: value })
    }
    setActiveBtn = (btnId) => {
        this.setState({ isActiveBtn: btnId })
    }
    setActiveSubBtn = (value) => {
        var element = document.getElementById('live-panel-sub-' + value);
        if (element.classList.contains('active')) {
            element.classList.remove("active");
        }
        else {
            element.classList.add("active");
        }
    }
    setActiveMainBtn = (value) => {
        // this.setState({ isActiveSubBtn: btnId })
        var element = document.getElementById('live-panel-' + value);
        if (element.classList.contains('active')) {
            element.classList.remove("active");
        }
        else {
            element.classList.add("active");
        }
    }
    setActiveStats = (value) => {
        this.setState({ activeStat: value }, () => {
            this.ShowStats();
        })

    }
    ShowStats = () => {
        let { activeStat } = this.state;
        var data = '';
        var dataArr = [];
        data = <Container className="fs-13">
            <Row className="justify-content-md-center fs-15">
                <Col xs={4} md={4} className={`ptb-12 text-center ${activeStat === 1 ? 'activeStats' : ''}`} onClick={(e) => this.setActiveStats(1)}>Stats</Col>
                <Col xs={4} md={4} className={`ptb-12 text-center ${activeStat === 2 ? 'activeStats' : ''}`} onClick={(e) => this.setActiveStats(2)}>Form</Col>
                <Col xs={4} md={4} className={`ptb-12 text-center ${activeStat === 3 ? 'activeStats' : ''}`} onClick={(e) => this.setActiveStats(3)}>Head to Head</Col>
            </Row>
            <Row className="pt-2 pb-2 bg-light-pink">
                <Col className="pl-3 p-0 text-center">Pos</Col>
                <Col xs={3} className="p-0"></Col>
                <Col className="p-0 text-center">M</Col>
                <Col className="p-0 text-center">W</Col>
                <Col className="p-0 text-center">D</Col>
                <Col className="p-0 text-center">L</Col>
                <Col className="p-0 text-center">G</Col>
                <Col className="p-0 text-center">PTS</Col>
                <Col className="p-0"></Col>
            </Row>
            <div className="pb-4 text-gray">
                <Row className="pt-1 pb-1">
                    <Col className="pl-3 p-0 text-center">1</Col>
                    <Col xs={3} className="p-0">Copenhagen</Col>
                    <Col className="p-0 text-center">1</Col>
                    <Col className="p-0 text-center">1</Col>
                    <Col className="p-0 text-center">1</Col>
                    <Col className="p-0 text-center">1</Col>
                    <Col className="p-0 text-center">3:1</Col>
                    <Col className="p-0 text-center">1</Col>
                    <Col className="p-0">OFF</Col>
                </Row>
                <Row className="pt-1 pb-1">
                    <Col className="pl-3 p-0 text-center">2</Col>
                    <Col xs={3} className="p-0">Brazil</Col>
                    <Col className="p-0 text-center">2</Col>
                    <Col className="p-0 text-center">2</Col>
                    <Col className="p-0 text-center">2</Col>
                    <Col className="p-0 text-center">2</Col>
                    <Col className="p-0 text-center">4:1</Col>
                    <Col className="p-0 text-center">2</Col>
                    <Col className="p-0">OFF</Col>
                </Row>
                <Row className="pt-1 pb-1">
                    <Col className="pl-3 p-0 text-center">3</Col>
                    <Col xs={3} className="p-0">China League</Col>
                    <Col className="p-0 text-center">3</Col>
                    <Col className="p-0 text-center">3</Col>
                    <Col className="p-0 text-center">3</Col>
                    <Col className="p-0 text-center">3</Col>
                    <Col className="p-0 text-center">1:1</Col>
                    <Col className="p-0 text-center">3</Col>
                    <Col className="p-0"></Col>
                </Row>
                <Row className="pt-1 pb-1">
                    <Col className="pl-3 p-0 text-center">4</Col>
                    <Col xs={3} className="p-0">Italy League 2</Col>
                    <Col className="p-0 text-center">4</Col>
                    <Col className="p-0 text-center">4</Col>
                    <Col className="p-0 text-center">4</Col>
                    <Col className="p-0 text-center">4</Col>
                    <Col className="p-0 text-center">2:0</Col>
                    <Col className="p-0 text-center">4</Col>
                    <Col className="p-0"></Col>
                </Row>
                <Row className="pt-1 pb-1">
                    <Col className="pl-3 p-0 text-center">5</Col>
                    <Col xs={3} className="p-0">Bundesliga 2</Col>
                    <Col className="p-0 text-center">5</Col>
                    <Col className="p-0 text-center">5</Col>
                    <Col className="p-0 text-center">5</Col>
                    <Col className="p-0 text-center">5</Col>
                    <Col className="p-0 text-center">2:2</Col>
                    <Col className="p-0 text-center">5</Col>
                    <Col className="p-0"></Col>
                </Row>
            </div>
            <Row className="pb-2">
                <Button className="login-btn ml-2 mr-2 fs-20" onClick={this.closeModal}>Close</Button>
            </Row>
        </Container>
        dataArr.push(data);
        this.setState({
            show: true,
            content: dataArr
        })
    }
    render() {
        let { isActive, isActiveBtn, isActiveSubBtn, show, content, activeRowId, matchActivity, activeStat, star } = this.state;
        return (
            <div>
                {/* sort by country */}
                <div className="live-box-row mt-2 mb-2">
                    <div className="live-box-col" onClick={(e) => this.setActive('All')}>
                        <div className={`live-box ${isActive === "All" ? 'active' : ''}`}>
                            <span>All</span>
                        </div>
                    </div>
                    <div className="live-box-col" onClick={(e) => this.setActive('tur')}>
                        <div className={`live-box ${isActive === "tur" ? 'active' : ''}`}>
                            <img className="live-flag-25" src={turkeyFlag} />
                            <span className="pl-1">tur</span>
                        </div>
                    </div>
                    <div className="live-box-col" onClick={(e) => this.setActive('eng')}>
                        <div className={`live-box ${isActive === "eng" ? 'active' : ''}`}>
                            <img className="live-flag-25" src={englandFlag} />
                            <span className="pl-1">eng</span>
                        </div>
                    </div>
                    <div className="live-box-col" onClick={(e) => this.setActive('rus')}>
                        <div className={`live-box ${isActive === "rus" ? 'active' : ''}`}>
                            <img className="live-flag-25" src={russiaFlag} />
                            <span className="pl-1">rus</span>
                        </div>
                    </div>
                    <div className="live-box-col" onClick={(e) => this.setActive('ger')}>
                        <div className={`live-box ${isActive === "ger" ? 'active' : ''}`}>
                            <img className="live-flag-25" src={germanyFlag} />
                            <span className="pl-1">ger</span>
                        </div>
                    </div>
                </div>
                {/* sort by country */}
                <div className="match-type mt-2 pt-2 pb-2 bg-f7">
                    <div className="result-nav">
                        <div className="result-nav-main fs-15 p-2 grid-divider text-center">
                            <div className={`result-w-p result-nav-tab result-nav-divider ${matchActivity === 1 ? 'active' : ''}`} onClick={(e) => this.setMatchActivity(1)}>Match Result</div>
                            <div className={`result-w-p result-nav-tab result-nav-divider ${matchActivity === 2 ? 'active' : ''}`} onClick={(e) => this.setMatchActivity(2)}>Next Goal</div>
                            <div className={`result-w-p result-nav-tab result-nav-divider ${matchActivity === 3 ? 'active' : ''}`} onClick={(e) => this.setMatchActivity(3)}>Remaining Match</div>
                            <div className={`result-w-p ml-1 result-nav-tab ${matchActivity === 4 ? 'active' : ''}`} onClick={(e) => this.setMatchActivity(4)}>Double Chance</div>
                        </div>
                    </div>
                </div>
                <div className="country">
                    <Row className="justify-content-md-center bg-dark-gray mx-auto text-white">
                        <Col>
                            <img className="live-flag-25" src={turkeyFlag}></img>
                            <span className="pl-2">Turkey</span>
                            <span className="pl-1">Liga 2</span>
                        </Col>
                    </Row>
                </div>
                <div className="match-panel">
                    <div>
                        <Row className="mx-auto match-inner-panel">
                            <div className="width-95 mx-auto">
                                <Col xs="5" lg="5" md="auto" className="pl-3 p-2" onClick={(e) => this.openResult(1)}>
                                    Ajax fc Amsterdam <br />PSV Rotterdam LC
                        </Col>
                                <Col className="p-1 pt-2 mt-2 text-center active fs-15" onClick={(e) => this.openResult(1)}>
                                    0 : 0
                        </Col>
                                <Col className="p-1 pt-2 mt-2 text-center" onClick={this.ShowStats}>
                                    <i className="demo-icon icon-stats"></i>
                                </Col>
                                <Col className="p-1 pt-0 mt-2 text-center">
                                    <i className="demo-icon fs-18 icon-livestream" style={{ color: "red" }}></i>
                                </Col>
                                <Col className="display-flex p-1 pt-2 text-right mt-2 fs-15" onClick={(e) => this.openResult(1)}>
                                    <span>+120</span> <i className={`live-dropdown-arror fs-20 pl-1 fa ${activeRowId === 1 ? 'fa-angle-down' : 'fa-angle-right'}`}></i>
                                </Col>
                            </div>
                        </Row>
                        <Row className="mx-auto match-inner-panel-bg">
                            <div className="width-95 mx-auto pb-1">
                                <Col xs="2" lg="2" md="auto" className="display-flex text-center pl-3 p-2">
                                    <i className={`fs-17 material-icons ${star ? 'activeStart' : ''}`} onClick={this.setStarActive}>star_border</i>
                                    <span className="pl-2 red">45"</span>
                                </Col>
                                <Col md="auto" id="live-panel-1" className="p-2 pl-3 pr-3 ml-3 border"
                                    onClick={(e) => this.setActiveMainBtn('1')}>
                                    <span className="left">1</span>
                                    <span className="right">1.22</span>
                                </Col>
                                <Col md="auto" id="live-panel-2" className="p-2 pl-3 pr-3 ml-1 border"
                                    onClick={(e) => this.setActiveMainBtn('2')}>
                                    <span className="left">x</span>
                                    <span className="right">2.75</span>
                                </Col>
                                <Col md="auto" id="live-panel-3" className="p-2 pl-3 pr-3 ml-1 border"
                                    onClick={(e) => this.setActiveMainBtn('3')}>
                                    <span className="left">2</span>
                                    <span className="right">2.75</span>
                                </Col>
                            </div>
                        </Row>
                        <div className={`result-open-panel ${activeRowId === 1 ? 'open-result' : ''}`}>
                            <div className="no-data fs-15 pt-3 pb-3">No data</div>
                        </div>
                    </div>
                    <div className="result-divider"></div>
                    <div>
                        <Row className="mx-auto match-inner-panel">
                            <div className="width-95 mx-auto">
                                <Col xs="5" lg="5" md="auto" className="pl-3 p-2" onClick={(e) => this.openResult(2)}>
                                    Ajax fc Amsterdam <br /> PSV Rotterdam LC
                        </Col>
                                <Col className="p-1 pt-2 mt-2 text-center active fs-15" onClick={(e) => this.openResult(2)}>
                                    0 : 0
                        </Col>
                                <Col className="p-1 pt-2 mt-2 text-center" onClick={this.ShowStats}>
                                    <i className="demo-icon icon-stats"></i>
                                </Col>
                                <Col className="p-1 pt-0 mt-2 text-center">
                                    <i className="demo-icon fs-18 icon-livestream" style={{ color: "red" }}></i>
                                </Col>
                                <Col className="display-flex p-1 pt-2 text-right mt-2 fs-15" onClick={(e) => this.openResult(2)}>
                                    <span>+120</span> <i className={`live-dropdown-arror fs-20 pl-1 fa ${activeRowId === 2 ? 'fa-angle-down' : 'fa-angle-right'}`}></i>
                                </Col>
                            </div>
                        </Row>
                        <Row className="mx-auto match-inner-panel-bg">
                            <div className="width-95 mx-auto pb-1">
                                <Col xs="2" lg="2" md="auto" className="text-center pl-3 p-2">
                                    45"
                            </Col>
                                <Col md="auto" id="live-panel-4" className="p-2 pl-3 pr-3 ml-3 border"
                                    onClick={(e) => this.setActiveMainBtn('4')}>
                                    <span className="left">1</span>
                                    <span className="right">1.22</span>
                                </Col>
                                <Col md="auto" id="live-panel-5" className="p-2 pl-3 pr-3 ml-1 border"
                                    onClick={(e) => this.setActiveMainBtn('5')}>
                                    <span className="left">x</span>
                                    <span className="right">2.75</span>
                                </Col>
                                <Col md="auto" id="live-panel-6" className="p-2 pl-3 pr-3 ml-1 border"
                                    onClick={(e) => this.setActiveMainBtn('6')}>
                                    <span className="left">2</span>
                                    <span className="right">2.75</span>
                                </Col>
                            </div>
                        </Row>

                    </div>
                    <div className={`result-open-panel ${activeRowId === 2 ? 'open-result' : ''}`}>
                        <div className="match-panel-bottom inner-shadow pt-2 pb-2">
                            <Row className="mx-auto match-inner-panel-bg bg-light-gray">
                                <div className="width-95 mx-auto pb-1">
                                    <Col xs="4" lg="4" md="auto" className="pl-3 p-2">
                                        Next Goal
                            </Col>
                                    <Col xs="1" lg="1" md="auto" className="text-center p-2">
                                        0:2
                            </Col>
                                    <Col md="auto" id="live-panel-sub-1" className="p-2 pl-2 pr-2 ml-2 border" onClick={(e) => this.setActiveSubBtn(1)}>
                                        <span className="left">1</span>
                                        <span className="right">1.22</span>
                                    </Col>
                                    <Col md="auto" id="live-panel-sub-2" className="p-2 pl-2 pr-2 ml-1 border" onClick={(e) => this.setActiveSubBtn(2)}>
                                        <span className="left">x</span>
                                        <span className="right">2.75</span>
                                    </Col>
                                    <Col md="auto" id="live-panel-sub-3" className="p-2 pl-2 pr-2 ml-1 border" onClick={(e) => this.setActiveSubBtn(3)}>
                                        <span className="left">2</span>
                                        <span className="right">2.75</span>
                                    </Col>
                                </div>
                            </Row>
                            <Row className="mx-auto match-inner-panel-bg pt-1">
                                <div className="width-95 mx-auto pb-1">
                                    <Col xs="5" lg="5" md="auto" className="pl-3 p-2">
                                        Double Chance
                            </Col>
                                    <Col md="auto" id="live-panel-sub-4" className="p-2 pl-2 pr-2 ml-2 border" onClick={(e) => this.setActiveSubBtn(4)}>
                                        <span className="left">1</span>
                                        <span className="right">1.22</span>
                                    </Col>
                                    <Col md="auto" id="live-panel-sub-5" className="p-2 pl-2 pr-2 ml-1 border" onClick={(e) => this.setActiveSubBtn(5)}>
                                        <span className="left">x</span>
                                        <span className="right">2.75</span>
                                    </Col>
                                    <Col md="auto" id="live-panel-sub-6" className="p-2 pl-2 pr-2 ml-1 border" onClick={(e) => this.setActiveSubBtn(6)}>
                                        <span className="left">2</span>
                                        <span className="right">2.75</span>
                                    </Col>
                                </div>
                            </Row>
                            <Row className="mx-auto match-inner-panel-bg pt-1 bg-light-gray">
                                <div className="width-95 mx-auto pb-1">
                                    <Col xs="5" lg="5" md="auto" className="pl-3 p-2">
                                        Draw no bet
                            </Col>
                                    <Col md="auto" id="live-panel-sub-7" className="p-2 pl-2 pr-2 ml-2 border" onClick={(e) => this.setActiveSubBtn(7)}>
                                        <span className="left">1</span>
                                        <span className="right">1.22</span>
                                    </Col>
                                    <Col md="auto" id="live-panel-sub-8" className="p-2 pl-2 pr-2 ml-1 border" onClick={(e) => this.setActiveSubBtn(8)}>
                                        <span className="left">x</span>
                                        <span className="right">2.75</span>
                                    </Col>
                                    <Col md="auto" id="live-panel-sub-9" className="p-2 pl-2 pr-2 ml-1 border" onClick={(e) => this.setActiveSubBtn(9)}>
                                        <span className="left">2</span>
                                        <span className="right">2.75</span>
                                    </Col>
                                </div>
                            </Row>
                            <Row className="mx-auto match-inner-panel-bg pt-1">
                                <Col xs="5" lg="5" md="auto" className="lh-85">
                                    Correct Score
                            </Col>
                                <div className="width-56 pb-1">
                                    <div className="display-flex pb-2 pl-2p">
                                        <Col md="auto" id="live-panel-sub-10" className="lh-10 pl-2 pr-2 ml-2 border" onClick={(e) => this.setActiveSubBtn(10)}>
                                            <span className="text-center width-100 left pt-1 pb-2">1</span>
                                            <span className="text-center width-100 right pb-1">1.22</span>
                                        </Col>
                                        <Col md="auto" id="live-panel-sub-11" className="lh-10 pl-2 pr-2 ml-1 border" onClick={(e) => this.setActiveSubBtn(11)}>
                                            <span className="text-center width-100 left pt-1 pb-2">x</span>
                                            <span className="text-center width-100 right pb-1">2.75</span>
                                        </Col>
                                        <Col md="auto" id="live-panel-sub-12" className="lh-10 pl-2 pr-2 ml-1 border" onClick={(e) => this.setActiveSubBtn(12)}>
                                            <span className="text-center width-100 left pt-1 pb-2">2</span>
                                            <span className="text-center width-100 right pb-1">2.75</span>
                                        </Col>
                                        <Col md="auto" id="live-panel-sub-13" className="lh-10 pl-2 pr-2 ml-1 border" onClick={(e) => this.setActiveSubBtn(13)}>
                                            <span className="text-center width-100 left pt-1 pb-2">2</span>
                                            <span className="text-center width-100 right pb-1">2.75</span>
                                        </Col>
                                    </div>
                                    <div className="display-flex pl-2p">
                                        <Col md="auto" id="live-panel-sub-14" className="lh-10 pl-2 pr-2 ml-2 border" onClick={(e) => this.setActiveSubBtn(14)}>
                                            <span className="text-center width-100 left pt-1 pb-2">1</span>
                                            <span className="text-center width-100 right pb-1">1.22</span>
                                        </Col>
                                        <Col md="auto" id="live-panel-sub-15" className="lh-10 pl-2 pr-2 ml-1 border" onClick={(e) => this.setActiveSubBtn(15)}>
                                            <span className="text-center width-100 left pt-1 pb-2">x</span>
                                            <span className="text-center width-100 right pb-1">2.75</span>
                                        </Col>
                                        <Col md="auto" id="live-panel-sub-16" className="lh-10 pl-2 pr-2 ml-1 border" onClick={(e) => this.setActiveSubBtn(16)}>
                                            <span className="text-center width-100 left pt-1 pb-2">2</span>
                                            <span className="text-center width-100 right pb-1">2.75</span>
                                        </Col>
                                        <Col md="auto" id="live-panel-sub-17" className="lh-10 pl-2 pr-2 ml-1 border" onClick={(e) => this.setActiveSubBtn(17)}>
                                            <span className="text-center width-100 left pt-1 pb-2">2</span>
                                            <span className="text-center width-100 right pb-1">2.75</span>
                                        </Col>
                                    </div>

                                </div>
                            </Row>
                            <Row className="mx-auto match-inner-panel-bg pt-1 bg-light-gray">
                                <div className="width-95 mx-auto pb-1">
                                    <Col xs="5" lg="5" md="auto" className="pl-3 p-2">
                                        Over / Under
                            </Col>
                                    <Col md="auto" id="live-panel-sub-18" className="p-2 pl-2 pr-2 ml-2 border" onClick={(e) => this.setActiveSubBtn(18)}>
                                        <span className="left">Over</span>
                                        <span className="right">1.22</span>
                                    </Col>
                                    <Col md="auto" id="live-panel-sub-19" className="p-2 pl-2 pr-2 ml-1 border" onClick={(e) => this.setActiveSubBtn(19)}>
                                        <span className="left">Under</span>
                                        <span className="right">2.75</span>
                                    </Col>
                                </div>
                            </Row>
                            {/* <Row>
                        <Col xs="5" lg={5} >
                            test
                        </Col>
                        <Row>
                        <Col md="auto" className="p-1 pl-2 pr-2 ml-2 border" >
                                <span className="left">1</span>
                                <span className="right">1.22</span>
                            </Col>
                            <Col md="auto" className="p-1 pl-2 pr-2 ml-1 border active">
                                <span className="left">x</span>
                                <span className="right">2.75</span>
                            </Col>
                            <Col md="auto" className="p-1 pl-2 pr-2 ml-1 border">
                                <span className="left">2</span>
                                <span className="right">2.75</span>
                            </Col>
                        </Row>
                        <Row>
                        <Col md="auto" className="p-1 pl-2 pr-2 ml-2 border" >
                                <span className="left">1</span>
                                <span className="right">1.22</span>
                            </Col>
                            <Col md="auto" className="p-1 pl-2 pr-2 ml-1 border active">
                                <span className="left">x</span>
                                <span className="right">2.75</span>
                            </Col>
                            <Col md="auto" className="p-1 pl-2 pr-2 ml-1 border">
                                <span className="left">2</span>
                                <span className="right">2.75</span>
                            </Col>
                        </Row>
                    </Row> */}
                        </div>
                    </div>
                </div>
                {/* <div className="match-panel-sub">
                    <Row className="mx-auto">
                        <Col xs="2" lg="2" md="auto" className="pl-3 p-1">
                            Pauze
                        </Col>
                        <Col xs="3" lg="3" className="p-1 pl-3 pr-3 ml-2 border">
                            <span className="left">1</span>
                            <span className="right">1.22</span>
                        </Col>
                        <Col xs="3" lg="3" className="p-1 pl-3 pr-3 ml-2 border active">
                            <span className="left">x</span>
                            <span className="right">2.75</span>
                        </Col>
                        <Col xs="3" lg="3" className="p-1 pl-3 pr-3 ml-2 border">
                            <span className="left">1</span>
                            <span className="right">1.22</span>
                        </Col>
                    </Row>
                </div> */}
                <PopUpModal show={show} content={content} closeModal={this.closeModal} activeStat={activeStat} />
            </div>
        )
    }
}
export default Live;