import React from 'react';
import Casino from './Casino';
import CasinoHeader from './CasinoHeader';
class CasinoIndex extends React.Component{
    render(){
        return(
            <div className="main-container">
                <CasinoHeader/>
                <Casino/>
            </div>
        )
    }
}
export default CasinoIndex;