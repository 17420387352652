import React from "react";



import { Route } from "react-router-dom";
// import logo from './logo.svg';
import "./App.css";
import Header from "./Components/Common/Header";
import Navigator from "./Components/Common/Navigator";
import Home from "./Components/Home";
import CasinoIndex from "./Components/Casino/CasinoIndex";
import LiveCasinoIndex from "./Components/LiveCasino/LiveCasinoIndex";
import SportsIndex from "./Components/Prematch/Sports/Index";
import LiveIndex from "./Components/Live/LiveIndex";
import BetslipIndex from "./Components/Betslip";
import BetslipSingle from "./Components/Betslip/BetslipSingle.js";
import MyAccount from "./Components/MyAccount/MyAccount";
import MyBets from "./Components/MyBets/MyBets";
import Auth from "./store/Auth";
import PrematchAMQ from "./store/PrematchAMQ";
import InPlayMQ from "./store/InPlayAMQ";
import LoadingSpinner from './Components/Common/LoadingSpinner';
import PrematchSearch from './Components/Prematch/Search/Index';
// import Login from './Components/Login/Login'

import InPlay from "./Components/InPlay/Sports/Index";
import InPlaySearch from './Components/InPlay/Search/Index';

import { connect } from 'react-redux';

class App extends React.Component {




  GoToPage = path => {
    this.props.history.push(path);
  };

  render() {
    let customProps = {
      GoToPage: this.GoToPage
    };

    return (



      <div className="app">
        <InPlayMQ></InPlayMQ>
        <PrematchAMQ>
          <Auth>
            <Header {...this.props} {...customProps} />

            <Route exact path="/" component={Home} />
            <Route exact path="/casino" component={CasinoIndex} />
            <Route exact path="/live-casino" component={LiveCasinoIndex} />

            <Route exact path="/live" component={LiveIndex} />
            <Route exact path="/betslip" component={BetslipIndex} />
            <Route exact path="/betslip-single" component={BetslipSingle} />
            <Route exact path="/my-account" component={MyAccount} />
            <Route exact path="/my-bets" component={MyBets} />

            <Route exact path="/inplay" component={InPlay} />
            <Route exact path="/inplay/search" component={InPlaySearch} />

            <Route exact path="/prematch" component={SportsIndex} />
            <Route exact path="/prematch/search" component={PrematchSearch} />


            <Navigator {...this.props} {...customProps} />
          </Auth>
        </PrematchAMQ>
        <LoadingSpinner />
      </div>



    );
  }
}

export default App;
