
export const SET_LOADING = "[APP] SET LOADING";

export const setLoading = (value) => {
  return (dispatch, getState) => {
    dispatch({ type: SET_LOADING, value: value })
  }
}

export function mapGeneralDispatchToProps(dispatch) {
  return {
    setLoading: (value) => { dispatch(setLoading(value)) },
  }
}

