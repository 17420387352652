import React from 'react';
import { Modal } from 'react-bootstrap'
class PopUpModal extends React.Component {
    render() {
        let {content,show, closeModal} = this.props;
        return (
            <Modal
                size="sm"
                show={show}
                onHide={closeModal}
                aria-labelledby="example-modal-sizes-title-sm"
                centered
                className="common-modal"
            >
                <Modal.Body>
                    {content}
                </Modal.Body>
            </Modal>
        )
    }
}
export default PopUpModal;