import React from 'react';
import { Row, Col } from 'react-bootstrap';
import League from './League';

import * as Maps from "../../../store/map";
import { connect } from 'react-redux';


class Locations extends React.Component {
    constructor(props) {
        super(props)
    }

    handleClick = (e, locationId) => {
        e.preventDefault();
        let { selectedLocation } = this.props.lSports.prematch;
        let locState = selectedLocation === locationId || locationId === null ? false : true;
        locationId = locState ? locationId : null;

        this.props.selectLocation(locationId);

    }

    render() {
        let { locations, selectedLocation } = this.props.lSports.prematch;

        var locationLength = locations ? locations.length : 0;
        const locationsList = locations ? (

            locations.map((item, i) => {

                let locationId = item.Location.Id;
                let locationName = item.Location.Name;
                let count = item.FixtureCount;

                let divider = locationLength - 1 > i ? (< div className="sports-divider"> </div>) : (null);

                return (

                    <div className={`section ${selectedLocation === locationId ? 'open' : ''}`} key={locationId}>
                        <Row className="justify-content-md-center sectionhead mx-auto p-0 pb-2 " onClick={(e) => this.handleClick(e, locationId)}>
                            <Col xs="2" lg="2" className="p-0">
                                <div country={"flag_" + locationId} className="flag"></div>
                            </Col>
                            <Col xs="8" lg="8" md="auto" className="p-0">{locationName}</Col>
                            <Col xs="1" lg="1" md="auto" className="p-0">{count}</Col>
                            <Col xs="1" lg="1" md="auto" className="p-0"><button>toggle</button></Col>
                        </Row>
                        {divider}

                        <League leagues={item.Leagues} />
                    </div >
                )
            })
        ) : (<div>loading...</div>)

        return (
            <div className="disable-select pt-3">
                {locationsList}
            </div>
        )
    }
}

export default connect(Maps.mapLSportsStateToProps, Maps.mapLSportsDispatchToProps)(Locations);
