import React from 'react';
import { Row, Col } from 'react-bootstrap';
import BetslipList from './BetslipList';
import BetslipSingle from './BetslipSingle';
import _ from 'lodash';

import * as Maps from "../../store/map";
import { connect } from 'react-redux';

// import CountryLeague from './CountryLeague';
class Index extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            activeId: this.props.betslip.count > 1 ? "2" : "1",
        }
    }
    activeButton = (e) => {
        let { count } = this.props.betslip;
        if (e === '2' && count < 2) return;

        this.setState({ activeId: e })
    }

    clearBets = (e) => {
        this.props.clearBets();
        this.setState({ activeId: "1" })
    }

    render() {
        let { activeId } = this.state;
        let { count } = this.props.betslip;

        let header = count > 0 ? (
            <Row className="mx-auto">
                <Col xs="3" className={`btn-style ${activeId === "1" ? 'active-toggle text-dark-green' : ''}`}>
                    <div id="1" name="2" className="pt-1 tablinks active" onClick={(e) => this.activeButton(e.target.id)}>Single</div>
                </Col>
                <Col xs="3" className={`btn-style ${activeId === "2" ? 'active-toggle text-dark-green' : ''}`}>
                    <div id="2" className="pt-1 tablinks" onClick={(e) => this.activeButton(e.target.id)}>Multiple</div>
                </Col>
                <Col xs="3" className={this.state.activeId === "3" ? "btn-style" : "btn-style"}>
                    <div id="3" className="tablinks" onClick={this.clearBets}>
                        <i className="demo-icon icon-trash-1 fs-24"></i>
                    </div>
                </Col>
                <Col xs="3" className="btn-toggle">
                    <label className="switch">
                        <input type="checkbox" />
                        <span className="slider round"></span>
                    </label>
                    <div>Fast bet</div>
                </Col>
            </Row>
        ) : (<div className="no-data bg-light p-5"> No odds selected</div>)

        return (
            <div className="main-container">
                {header}

                {activeId == "2" ? <BetslipList switchBetslipType={this.activeButton} /> : ""}
                {activeId == "1" ? <BetslipSingle /> : ""}
            </div>
        )
    }
}
export default connect(Maps.mapBetslipStateToProps, Maps.mapBetslipDispatchToProps)(Index);