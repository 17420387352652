import React, { Component } from 'react';
import Prematch from '../../Prematch/Prematch';
import SearchBar from './SearchBar';

import * as Maps from "../../../store/map";
import { connect } from 'react-redux';

import _ from 'lodash';

class Index extends Component {

    constructor(props) {
        super(props);
    }

    componentDidMount() {
        this.props.clearSearch();
    }

    render() {

        return (
            <div className="main-container">
                <SearchBar />
                <Prematch />
            </div>
        )
    }
}
export default connect(Maps.mapLSportsStateToProps, Maps.mapLSportsDispatchToProps)(Index);